<template>
  <div class="container">
    <h2>GoodRx vs. IntelligentRx Drug Price Comparisons</h2>
    <p>Search or click on a drug below to see both the GoodRx and the IntelligentRx price for the drug's most common form, dosage, and quantity. These drug price comparisons were used to generate the IntelligentRx vs. GoodRx comparison statistics you see above.</p>
    <div class="row">
        <div class="input-group mb-3 px-4 text-dark">
            <input type="search" class="form-control text-left" :placeholder="placeHolder" style="font: var(--body-font-weight) 16px/19px var(--body-font-family);" v-model="drugSearch" />
        </div>
    </div>
    <div class="row pb-4" v-if="drugSearch != null && drugSearch != ''">
    <div class="col col-12">
        <div v-for="result in this.drugSearchResults" :key="result.drugName">
            <a :href="result.link"
          style="text-transform:capitalize;">{{result.drugName}}</a>
        </div>
    </div>
    </div>
    <!-- <div class="row">
      <div
        class="col-12 col-sm-6 col-md-4 col-lg-3"
        v-for="drugName in this.drugNames"
        :key="drugName"
      >
        <a
          :href="{
            name: 'goodrx-comparison-page',
            query: { drugName: drugName },
          }"
          style="text-transform:capitalize;"
          >{{ drugName }}</a
        >
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
export default {
  name: "goodrx-comparison-links",
  data() {
    return {
      drugNames: [],
      drugSearch: "",
    };
  },
  computed: {
    placeHolder() {
      return "🔎︎   Search drugs";
    },
    drugSearchResults() {
        if (this.drugSearch == null || this.drugSearch.length < 1)
            return [];
        let results = [];
        for (let i = 0; i < this.drugNames.length; i++) {
            const drugName = this.drugNames[i];
            if (drugName.toUpperCase().startsWith(this.drugSearch.toUpperCase()))
                results.push({"link": "/goodrx-comparison?drugName="+drugName, "drugName": drugName})
        }
        if (results.length > 10)
            results.splice(10);
        return results;
    },
  },
  created() {
    this.drugNames = [
"Atorvastatin Calcium",
"Lipitor",
"Levothyroxine Sodium",
"Synthroid",
"Levo-T",
"Levoxyl",
"Unithroid",
"Lisinopril",
"Prinivil",
"Zestril",
"Metformin Hcl",
"Glucophage",
"Amlodipine Besylate",
"Norvasc",
"Amlodipine-Atorvastatin",
"Caduet",
"Amlodipine-Olmesartan",
"Azor",
"Amlodipine-Valsartan",
"Exforge",
"Metoprolol Succinate",
"Toprol Xl",
"Metoprolol Tartrate",
"Lopressor",
// "Albuterol Sulfate",
"Albuterol Sulfate Hfa",
"Proair Hfa",
"Proventil Hfa",
"Ventolin Hfa",
"Omeprazole",
"Losartan Potassium",
"Cozaar",
"Simvastatin",
"Flolipid",
"Zocor",
"Gabapentin",
"Neurontin",
"Hydrocodone Bitartrate Er",
"Hysingla Er",
"Zohydro Er",
"Hydrochlorothiazide",
"Microzide",
"Irbesartan-Hydrochlorothiazide",
"Avalide",
"Lisinopril-Hydrochlorothiazide",
"Zestoretic",
"Losartan-Hydrochlorothiazide",
"Hyzaar",
"Methyldopa",
"Sertraline Hcl",
"Zoloft",
"Montelukast Sodium",
"Singulair",
"Fluticasone Propionate",
"Cutivate",
"Amoxicillin",
"Furosemide",
"Lasix",
"Pantoprazole Sodium",
"Protonix",
"Acetaminophen",
"Acephen",
"Aphen",
"Pain & Fever",
"Tylenol",
"Childrens Fever Reducing",
"Athenol",
"Mapap",
"Masophen",
"Non-Aspirin",
"Pain Relief",
"Pain Reliever",
"Pharbetol",

"Tactinal",
"Child Fever Reducer",
"Child Pain Rel-Fever Reducer",
"Childrens Fever Reducer",
"Childrens Non-Aspirin",
"Feverall",
"Childrens Pain And Fever",
"Betatemp",
"Childrens Tylenol",
"Infant Pain-Fever",
"Infants Pain Reliever",
"Infants Tylenol",
"Pediacare Fever Reducer",
"Tylenol Extra Strength",
"Shake That Ache",
"Childrens Acetaminophen",
"Ed-Apap",
"Little Remedies Fever-Pain",
"M-Pap",
"Silapap",
"Child Fever Reducer-Pain Relvr",
"Childrens Aurophen Pain-Fever",
"Childrens Pain Relief",
"Childrens Pain Reliever",
"Childrens Pain-Fever",

"Fever Reducer-Pain Reliever",
"Infant Fever-Pain Reliever",
"Infants Acetaminophen",
"Infants Fever-Pain Reliever",
"Infants Pain Relief",
"Infants Pain-Fever",
"Nortemp",
"Acetaminophen Extra Strength",
"Non-Aspirin Pain Relief",
"Pain Relief Extra Strength",

"Childrens Mapap",
"Childrens Pain & Fever",
"Childrens Tactinal",
"Prednisone",
"Escitalopram Oxalate",
"Lexapro",
"Fluoxetine Hcl",
"Prozac",
"Dextroamphetamine Sulfate",
"Dextroamphetamine Sulfate Er",
"Dexedrine",
"Amphetamine",
"Amphetamine Sulfate",
"Evekeo",
"Tramadol Hcl",
"Qdolo",
"Ultram",
"Insulin Aspart",
"Insulin Lispro",
"Lantus",
"Bupropion Hcl",
"Ibuprofen",
"Ibu",
"Advil",
"Rosuvastatin Calcium",
"Crestor",
"Pravastatin Sodium",
"Pravachol",
"Trazodone Hcl",
"Tamsulosin Hcl",
"Flomax",
"Carvedilol",
"Coreg",
"Meloxicam",
"Mobic",
"Citalopram Hbr",
"Celexa",
"Duloxetine Hcl",
"Cymbalta",
"Alprazolam",
"Xanax",
"Potassium",
"Potassium Gluconate",
"Clopidogrel",
"Plavix",
"Aspirin",
"Bayer Chewable Aspirin",
"Childrens Aspirin",
"St. Joseph Aspirin",
"Atenolol",
"Tenormin",
"Cyclobenzaprine Hcl",
"Fexmid",
"Glipizide",
"Glucotrol",
"Methylphenidate Er",
"Aptensio Xr",
"Concerta",
"Azithromycin",
"Zithromax",
"Clonazepam",
"Klonopin",
"Oxycodone Hcl",
"Roxicodone",
"Allopurinol",
"Zyloprim",
"Venlafaxine Hcl",
"Warfarin Sodium",
"Propranolol Hcl",
"Cetirizine Hcl",
"Childrens Zyrtec",
"Zyrtec",
"Childrens All Day Allergy",
"Childrens Aller-Tec",
"Childrens Allergy",
"Childrens Allergy Complete",
"Childrens Allergy Relief",
"Childrens Cetirizine Hcl",
"Childrens Wal-Zyr",
"Wal-Zyr",
"Allergy Relief",
"Childrens Benadryl Allergy",
"Childrens Allegra Allergy",
"Allergy",
"Allergy Medicine",
"Banophen",
"Childrens Aurodryl Allergy",
"Childrens Diphenhydramine",
"Childrens Wal-Dryl Allergy",
"Complete Allergy",
"Diphedryl",
"Diphedryl Allergy",
"Diphenhist",
"Diphenhydramine Hcl",
"Geri-Dryl",
"M-Dryl",

"Siladryl",
"Wal-Dryl Allergy",
"Aller-Ease",
"Childrens Wal-Fex",
"Fexofenadine Hcl",
"Allergy-Time",
"Chlortabs",
"Pharbechlor",
"Anti-Itch",
"Benadryl",
"Pharbedryl",
"Aller-G-Time",
"Diphen",
"Nighttime Allergy Relief",
"Total Allergy",
"Aler-Caps",
"Allergy Medication",
"Wal-Dryl",
"Allegra Allergy",
"Aller-Chlor",
"Allergy 4-Hour",
"Chlorhist",
"Chlorpheniramine Maleate",
"Wal-Finate",
"Cortizone-10",
"Benadryl Itch Stopping",
"Benadryl Allergy",
"Wal-Fex Allergy",
"Hydrocortisone Plus",
"Hydrocortisone-Aloe",
"Itch Relief",
"Cortisone With Aloe",
"Hydroskin",
// "Estradiol",
"Estrace",
"Ethynodiol-Ethinyl Estradiol",
"Demulen 1/35-21",
"Kelnor 1-35",
"Zovia 1-35",
"Zovia 1-35E",
"Etonogestrel-Ethinyl Estradiol",
"Nuvaring",
"Eluryng",
"Norethindrone",
"Camila",
"Deblitane",
"Errin",
"Heather",
"Incassia",
"Jencycla",
"Jolivette",
"Lyleq",
"Lyza",
"Nora-Be",
"Norlyda",
"Norlyroc",
"Sharobel",
"Tulana",
"Ortho Micronor",
"Lorazepam",
"Ativan",
"Quetiapine Fumarate",
"Seroquel",
"Zolpidem Tartrate",
"Ambien",
// "Ergocalciferol",
"Budesonide",
"Pulmicort",
// "Budesonide-Formoterol Fumarate",
"Symbicort",
"Spironolactone",
"Aldactone",
"Ondansetron Hcl",
"Zofran",
"Naproxen",
"Ec-Naprosyn",
"Ec-Naproxen",
"Lamotrigine",
"Subvenite",
"Lamictal",
"Fluticasone-Salmeterol",
"Airduo Respiclick",
"Pregabalin",
"Lyrica",
"Glimepiride",
"Amaryl",
"Diclofenac",
"Zorvolex",
"Fenofibrate",
"Antara",
"Paroxetine Hcl",
"Paxil",
"Clonidine Hcl",
"Catapres",
"Loratadine",
"Hydrocortisone Acetate",

"Hydrocream",
"Noble Formula Hc",
"Proctocort",
"Soothing Care",
"Hemorrhoidal Ointment",
"Major-Prep Hemorrhoidal",
"Anucort-Hc",
"Anusol-Hc",
"Hemmorex-Hc",
"Diltiazem Hcl",
"Cardizem",
"Hydroxyzine Hcl",
"Amitriptyline Hcl",
"Doxycycline Hyclate",
"Acticlate",
"Doryx",
// "Norgestimate-Ethinyl Estradiol",
"Ortho Tri-Cyclen",
"Ortho-Cyclen",
"Tri Femynor",
"Tri-Estarylla",
"Tri-Linyah",
"Tri-Mili",
"Tri-Nymyo",
"Tri-Previfem",
"Tri-Sprintec",
"Tri-Vylibra",
"Trinessa",
"Estarylla",
"Femynor",
"Mili",
"Mono-Linyah",
"Mononessa",
"Nymyo",
"Previfem",
"Sprintec",
"Vylibra",
"Latanoprost",
"Xalatan",
"Cephalexin",
"Keflex",
"Tizanidine Hcl",
"Finasteride",
"Propecia",
"Lovastatin",
"Topiramate",
"Topamax",
"Sulfamethoxazole-Trimethoprim",
"Bactrim",
"Bactrim Ds",
"Trimethoprim",
"Buspirone Hcl",
"Oseltamivir Phosphate",
"Ferrous Sulfate",
"Feosol",
"Carbonyl Iron",
"Amoxicillin-Clavulanate Potass",
"Valsartan",
"Diovan",
"Levetiracetam",
"Keppra",
"Roweepra",
"Hydralazine Hcl",
"Mirtazapine",
"Remeron",
"Aripiprazole",
"Abilify",
"Oxybutynin Chloride",
"Esomeprazole Magnesium",
"Nexium",
"Alendronate Sodium",
"Fosamax",
"Folic Acid",
"Triamcinolone Acetonide",
"Kenalog",
"Kenalog-40",
// "Triamcinolone Diacetate",
"Thyroid",
"Armour Thyroid",
"Np Thyroid",
"Ciprofloxacin Hcl",
"Ciloxan",
"Isosorbide Mononitrate",
"Sumatriptan Succinate",
"Imitrex",
"Sumatriptan",
"Benzonatate",
"Tessalon Perle",
"Famotidine",
"Diazepam",
"Diastat",
"Valium",
"Ropinirole Hcl",
"Requip",
"Triamterene",
"Dyrenium",
"Benazepril Hcl",
"Lotensin",
"Metronidazole",
"Flagyl",
"Methocarbamol",
"Robaxin",
"Nifedipine",
"Procardia",
"Baclofen",
"Gablofen",
"Methotrexate",
"Ezetimibe",
"Zetia",
"Celecoxib",
"Celebrex",
"Guanfacine Hcl",
"Donepezil Hcl",
"Aricept",
"Hydroxychloroquine Sulfate",
"Plaquenil",
"Clindamycin Hcl",
"Cleocin Hcl",
"Divalproex Sodium",
"Depakote",
"Morphine Sulfate",
"Mitigo",
"Levonorgestrel",
"After Pill",
"Econtra Ez",
"Econtra One-Step",
"Fallback Solo",
"My Choice",
"My Way",
"New Day",
"Next Choice One Dose",
"Opcicon One-Step",
"Option 2",
"Take Action",
"Plan B One-Step",
"Aftera",
"Prednisolone",
"Enalapril Maleate",
"Epaned",
"Vasotec",
"Pioglitazone Hcl",
"Actos",
"Cyanocobalamin Injection",
"Meclizine Hcl",
"Medi-Meclizine",
"Motion Sickness",
"Dimenhydrinate",
"Dramamine Less Drowsy",
"Motion Relief",
"Motion Sickness Ii",
"Motion Sickness Relief",
"Travel-Ease",
"Wal-Dram 2",
"Driminate",
"Travel Sickness",
"Wal-Dram",
"Dramamine",
"Antivert",
"Bonine",
"Motion-Time",
"Valacyclovir",
"Valtrex",
"Ipratropium Bromide",
"Docusate Calcium",
"Docusate Sodium",
"Kaopectate",
"Surfak",
"Enemeez",
"Colace",
"Sof-Lax",
"Bismatrol",
"Bismuth Maximum Strength",
"Stomach Relief",
"Pink Bismuth",
"Kao-Tin",
"Stool Softener",
"Col-Rite",
"Doc-Q-Lace",
"Docusil",
"Dok",
"Dulcoease",
"Dulcolax Stool Softener",
"Laxa Basic 100",
"Phillips Laxative",
"Pepto-Bismol",
"Peptic Relief",
"Pepto-Bismol To-Go",
"Digestive Relief",
"Anti-Diarrheal",
"Geri-Pectate",
"Docuprene",
"Move It Along",
"Promolaxin",
"Bismuth",
"Bismuth Subsalicylate",
"Diotame",
"Soothe",
"Imodium A-D",
"Ultra A-D",
"Diarrhea Relief",
"K-Pec",
"Stomach Relief Original",
"Retaine Pm",
"Anti-Diarrhea",
"Diamode",
"Loperamide",
"Victoza",
"Verapamil Hcl",
"Calan",
"Cefdinir",
"Nortriptyline Hcl",
"Pamelor",
"Timolol Maleate",
"Istalol",
"Timoptic",
"Trulicity",
"Promethazine Hcl",
"Phenergan",
"Acyclovir",
"Zovirax",
"Fluconazole",
"Diflucan",
"Methylprednisolone",
"Medrol",
"Ramipril",
"Altace",
"Dexmethylphenidate Hcl",
"Focalin",
"Brimonidine Tartrate",
"Alphagan P",
"Oxcarbazepine",
"Trileptal",
"Risperidone",
"Risperdal",
"Levofloxacin",
"Levaquin",
"Chlorthalidone",
"Atomoxetine Hcl",
"Strattera",
"Polyethylene Glycol 3350",
"Miralax",
"Natura-Lax",
"Clearlax",
"Healthylax",
"Powderlax",
"Smoothlax",
"Gavilax",
"Gentlelax",
"Glycolax",
"Laxaclear",
"Laxative Peg 3350",
"Purelax",
"Calcium",
"Calci-Chew",
"Mupirocin",
"Bactroban",
"Centany",
// "Drospirenone-Eth Estra-Levomef",
"Beyaz",
"Rajani",
"Phentermine Hcl",
"Carbidopa",
"Lodosyn",
"Carbidopa-Levodopa",
"Sinemet 10-100",
"Dhivy",
"Sinemet 25-100",
"Omega-3 Acid Ethyl Esters",
"Lovaza",
"Triklo",
"Desogestrel-Ethinyl Estradiol",
"Guaifenesin",
"Chest Congestion Relief",
"Child Mucinex Chest Congestion",
"Diabetic Siltussin Das-Na",
"Geri-Tussin",
"Mucinex Fast-Max Chest-Congest",
"Mucus-Chest Congestion",
"Scot-Tussin Expectorant",
"Tusnel-Ex",
"G-Fenesin",
"Mucosa",
"Tab Tussin",
"Adult Tussin Chest Congestion",
"Adult Wal-Tussin",
"Childrens Chest Congestion",
"Childrens Mucus Relief",
"Cough Syrup",
"Diabetic Tussin Ex",
"Expectorant",
"Expectorant Cough Syrup",
"Ri-Tussin",
"Robafen",
"Scot-Tussin",
"Siltussin Sa",
"Tussin",
"Tussin Chest Congestion",
"Tussin Honey",
"Tussin Mucus-Chest Congestion",
"Wal-Tussin",
"Coughtab 400",
"Fenesin Ir",
"Mucus Relief",
"Mucus Relief Chest",
"Refenesen",
"Coughtab",
"Cough Relief",
"Mucus Relief Chest Congestion",
"Organ-I Nr",
"Tussin Cough",
"Wal-Tussin Cough",
"Cough Gels",
"Cough Control",
"Robafen Cough",
"Robitussin",
"Dextromethorphan Hbr",
"Rizatriptan",
"Maxalt",
"Irbesartan",
"Avapro",
"Progesterone",
"Prometrium",
"Doxazosin Mesylate",
"Cardura",
"Tradjenta",
"Humira",
"Nitrofurantoin",
"Furadantin",
"Macrodantin",
"Digoxin",
"Lanoxin",
"Butalbital-Acetaminophen",
"Allzital",
"Tencon",
"Marten-Tab",
"Ketoconazole",
"Extina",
"Nizoral",
"Ketodan",
"Nitroglycerin",
"Nitrostat",
"Temazepam",
"Restoril",
"Amiodarone Hcl",
"Memantine Hcl",
"Namenda",
"Invokana",
"Ketorolac Tromethamine",
"Acular",
"Liothyronine Sodium",
"Cytomel",
"Triostat",
"Lithium",
"Dicyclomine Hcl",
"Bentyl",
"Pramipexole Dihydrochloride",
"Mirapex",
"Terazosin Hcl",
"Magnesium",
"Magnesium Oxide",
"Laxative Dietary Supplement",
"Magox 400",
"Mgo",
"Colchicine",
"Colcrys",
"Valproate Sodium",
"Depacon",
"Azelastine Hcl",
"Astepro",
"Azelastine-Fluticasone",
"Dymista",
"Prazosin Hcl",
"Minipress",
"Anastrozole",
"Arimidex",
"Desvenlafaxine Succinate Er",
"Pristiq",
"Clobetasol Emollient",
"Olux-E",
"Clobetasol Emulsion",
"Tovet Emollient",
"Clobetasol Propionate",
"Clobex",
"Temovate",
"Olanzapine",
"Zyprexa",
"Sucralfate",
"Carafate",
"Levocetirizine Dihydrochloride",
"24Hr Allergy Relief",
"Xyzal",
"Methimazole",
"Tapazole",
"Cyclosporine",
"Restasis",
"Sildenafil Citrate",
"Revatio",
"Viagra",
"Carbamazepine",
"Tegretol",
"Epitol",
"Labetalol Hcl",
"Lansoprazole",
"Prevacid",
"Dorzolamide",
"Melatonin",
"Meladox",
"Melatin",
"Ranolazine Er",
"Ranexa",
"Gemfibrozil",
"Lopid",
"Flecainide Acetate",
"Lidocaine",
"Lmx 4",
"Anecream",
"Lidoheal-90",
"Lmx 4 Plus",
"Benztropine Mesylate",
"Nystatin",
"Eszopiclone",
"Lunesta",
"Torsemide",
"Demadex",
"Soaanz",
"Mesalamine",
"Canasa",
"Rowasa",
"Sfrowasa",
// "Buprenorphine",
"Butrans",
"Erythromycin",
"Erygel",
"Doxepin Hcl",
"Prudoxin",
"Hydromorphone Hcl",
"Penicillin V Potassium",
"Bumetanide",
"Letrozole",
"Femara",
"Tretinoin",
"Avita",
"Retin-A",
"Atralin",
"Minocycline Hcl",
"Minocin",
"Bisoprolol Fumarate",
"Calcitriol",
"Rocaltrol",
"Epinephrine",
"Adrenalin",
"Glyburide",
"Sotalol",
"Betapace",
"Betapace Af",
"Sorine",
"Sotalol Af",
"Bimatoprost",
"Latisse",
"Medroxyprogesterone Acetate",
"Depo-Provera",
"Tamoxifen Citrate",
"Mometasone Furoate",
"Elocon",
"Nasonex",
"Sulfasalazine",
"Sulfasalazine Dr",
"Azulfidine",
"Indomethacin",
"Tivorbex",
"Olopatadine Hcl",
"Pataday",
"Pataday Once Daily Relief",
"Eye Allergy Itch Relief",
"Telmisartan",
"Micardis",
// "Phenytoin",
// "Dilantin",
// "Phenytoin Sodium Extended",
"Naloxone Hcl",
"Evzio",
"Raloxifene Hcl",
"Evista",
"Tobramycin",
"Bethkis",
"Tobrex",
"Dexamethasone",
"Naltrexone Hcl",
// "Neomycin-Polymyxin B",
// "Neosporin G.U. Irrigant",
"Terbinafine Hcl",
"Lamisil",
"Fentanyl",
"Duragesic",
"Dermasarra",
"Men-Phor",
"Sarna Original",
"Zonisamide",
"Zonegran",
"Brompheniramine-Pseudoephed-Dm",
"Dextromethorphan-Guaifenesn-Pe",
"Pseudoephedrine Hcl",
"Sudafed",
"Nasal Decongestant",
"Sudogest",
"Suphedrin",
"Suphedrine",
"Suphedrine Sinus Congestion",
"Wal-Phed",
"Childrens Sudafed",
"Childrens Silfedrine",
"Dimethyl Fumarate",
"Tecfidera",
"Tadalafil",
"Adcirca",
"Cialis",
"Alyq",
"Emtricitabine",
"Emtriva",
"Tenofovir Disoproxil Fumarate",
"Viread",
"Tolterodine Tartrate",
"Detrol",
"Quinapril-Hydrochlorothiazide",
"Accuretic",
"Senna",
// "Betamethasone Diprop-Minoxidil",
"Solifenacin Succinate",
"Vesicare",
"Fluocinonide",
"Vanos",
"Chlorhexidine Gluconate",
"Paroex",
"Periogard",
"Antiseptic Skin Cleanser",
"Peridex",
"Betasept",
"Dyna-Hex",
"Dynahex",
"Hibiclens",
"Eliquis",
"Januvia",
"Xarelto",
"Spiriva",
"Jardiance",
"Myrbetriq",
"Bystolic",
"Nebivolol Hcl",
"Incruse Ellipta",
"Dexilant",
"Dexlansoprazole Dr",
"Latuda",
"Entresto",
"Ozempic",
"Beconase Aq",
"Chantix",
"Varenicline",
// "Aimovig Autoinjector",
"Linzess",
"Enbrel",
// "Insulin Aspart Flexpen",
// "Insulin Aspart Penfill",
// "Insulin Pen Needle",
    ];
    this.drugNames.sort();

    // Note: these are sent to title case with text-transform: capitalize;
    for (let i=0;i<this.drugNames.length;i++)
    {
        this.drugNames[i] = this.drugNames[i].toLowerCase();
    }
  },
};
</script>
<style scoped>
</style>