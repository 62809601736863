<template>
    <div>
        <!-- 
            One design goal is to have search results in view as soon as the page finishes loading.
            On Desktop this is easy because the search options are generally arranged along the X axis & results along the Y.
            On Mobile it is hard because both search options and results are along the Y axis.
            Consequently our mobile view compresses the search options.
        -->
        <div class="row" v-if="this.isMobile() && !this.mobileDoEditSearchSettings">
            <div class="col col-8">
                <span v-text="compressedQuantity"></span>&hairsp;
                <span v-if="selectedFormValue" v-text="selectedFormValue"></span>
                <span v-if="selectedDosageValue">
                    at&nbsp;<span v-text="selectedDosageValue"></span>
                </span>
                in&nbsp;<span v-text="zipCode" class="d-inline-block"></span>
            </div>
            <div class="col col-4">
                <prc-button type="info" class="input-height" @click="mobileDoEditSearchSettings = true">Edit</prc-button>
            </div>
        </div>
        <div class="" v-if="!this.isMobile() || this.mobileDoEditSearchSettings">
            <!-- <div
        v-if="editZipCode || !isValidZipCode() || this.isMobile()"
        class="row align-items-center h-100"
      > -->
            <div class="row align-items-center h-100">
                <div class="col col-md-12 col-lg-6 col-12">
                    <prc-input v-model="zipCode" type="text" placeholder="Enter Zip Code" :disabled="!!location"
                        errorMessage="The Zip code must be 5 digits" :state="isValidZipCode()" name="zipCode"
                        label="Zip Code" :enterMethod="loadDrugDetails" @input="zipCodeChanged" />
                </div>
            </div>
            <!-- <div
        v-if="!editZipCode && isValidZipCode() && !this.isMobile()"
        class="row align-items-center h-100"
      >
        <div class="col-12 align-middle pb-0 mb-0">
          <button
            class="d-inline-block btn btn-light ml-3 col col-lg-3 col-md-6 col-6"
            @click="editZipCode = true"
            ><font-awesome-icon icon="fa fa-pencil" size="1x" aria-hidden="true"></font-awesome-icon>&nbsp;&nbsp;Edit Zip Code {{ zipCode }}</button>
        </div>
      </div> -->

            <div class="row pt-1" v-if="showZipCodeWarning">
                <div class="col">
                    <div class="alert alert-warning alert-dismissible fade show set-location" role="alert">
                        Please update your zip code
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mt-22 no-gutters align-items-end">
                <div class="col-md-9 col-sm-12">
                    <select-label-form-dosage-qty></select-label-form-dosage-qty>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { requestmixin } from "../../mixins/requestmixin";
import PrcButton from "../controls/prc-button.vue";
import PrcInput from "../controls/prc-input.vue";
import SelectLabelFormDosageQty from './select-label-form-dosage-qty.vue';
import { searchDrugNames } from "../../mixins/searchDrugNames";
import { ipapimixin } from "../../mixins/ipapimixin";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";
import { errormixin } from "../../mixins/errormixin";
import { routerMixin } from "../../mixins/routerMixin";
import { useDrugDetailsFromServerStore } from '../../stores/drug-details-from-server';
import { useSearchDrugOptionsStore } from '../../stores/search-drug-options';
import { mapWritableState } from 'pinia';

export default {
    name: "search-drug-options",
    mixins: [
        searchDrugNames,
        ipapimixin,
        requestmixin,
        mixinDetectingMobile,
        errormixin,
        routerMixin,
    ],
    props: {
        haveLoadedFullDrugDetails: { type: Boolean, default: false }, // true when we perform a successful load which is not the initial load.
    },
    emits: ['load-drug-details', 'reload'],
    data() {
        return {
            showZipCodeWarning: false, // never show zip code warning - it is maybe too confusing
            mobileDoEditSearchSettings: false,
            lastSearchedQuantity: null, // counter-intuitively, this variable is not needed by the parent component.
            editZipCode: false, // once you edit the zip code, the input should persist
        };
    },

    components: {
        PrcButton,
        PrcInput,
        SelectLabelFormDosageQty,
    },

    computed: {
        ...mapWritableState(useSearchDrugOptionsStore, ['location', 'zipCode', 'selectedGsn', 'selectedNdc', 'selectedLabelValue', 'labelSetByHuman', 'selectedFormValue', 'selectedDosageValue', 'selectedQuantityValue', 'customQuantity', 'customQuantityPackaged', 'loadDrugDetails', 'updateURL', 'labelChanged', 'formChanged', 'dosageChanged', 'quantityChanged', 'pharmacyNameFilter']),

        ...mapWritableState(useDrugDetailsFromServerStore, ['drugDetails']),

        isPackaged() {
            const store = useDrugDetailsFromServerStore();
            return (
                store.getQuantities != null &&
                store.getQuantities.length > 0 &&
                store.getQuantities[0]?.quantityToDisplay != null &&
                store.getQuantities[0].quantityToDisplay.substring(0, 2) == "1 "
            );
        },

        selectedLabel() {
            const store = useDrugDetailsFromServerStore();
            return store.getLabels?.find(
                (label) =>
                    label.isSelected &&
                    label.drugName.toLowerCase() === this.drugName.toLowerCase()
            );
        },

        selectedForm() {
            const store = useDrugDetailsFromServerStore();
            return store.getForms?.find(
                (form) => form.form === this.selectedFormValue
            );
        },

        selectedDosage() {
            const store = useDrugDetailsFromServerStore();
            return store.getDosages?.find(
                (dosage) => dosage.strength === this.selectedDosageValue
            );
        },

        selectedQuantity() {
            const store = useDrugDetailsFromServerStore();
            return store.getQuantities?.find(
                (quantity) => quantity.quantity === this.selectedQuantityValue
            );
        },

        disableSearch() {
            return !this.zipCode && !this.location;
        },

        compressedQuantity() {
            const store = useDrugDetailsFromServerStore();
            // Must be in this order because customQuantity is checked before customQuantityPackaged
            if (String(this.selectedQuantityValue) === "Custom" && this.isPackaged) {
                let quantityLabel = "";
                if (store.getQuantities?.length > 0)
                    quantityLabel = store.getQuantities[0].quantityLabel;
                return (
                    String(this.customQuantityPackaged) + " " + String(quantityLabel)
                );
            }
            if (String(this.selectedQuantityValue) === "Custom") {
                return this.customQuantity == null ? "" : String(this.customQuantity);
            }
            if (!this.isPackaged) {
                return this.selectedQuantityValue == null
                    ? ""
                    : String(this.selectedQuantityValue);
            }
            return String(this.selectedQuantity?.quantityToDisplay);
        },
    },

    methods: {
        resetData() {
            this.showZipCodeWarning = false;
            this.mobileDoEditSearchSettings = false;
            this.lastSearchedQuantity = null;
            this.editZipCode = false;
        },
        getQuantity() {
            var qty = this.selectedQuantityValue;
            if (qty === "Custom" && this.isPackaged) {
                qty = this.customQuantityPackaged;
            } else if (qty === "Custom") {
                qty = this.customQuantity;
            }
            return qty;
        },

        isValidZipCode() {
            //initial state of the input must be neutral
            if (this.zipCode === null || this.zipCode === "") {
                return null;
            }

            const isValidZipCode = /^\d{5}$/.test(this.zipCode);

            return isValidZipCode;
        },

        detectLocation() {
            //do we support geolocation
            if (!("geolocation" in navigator)) {
                return;
            }

            // get position
            navigator.geolocation.getCurrentPosition(
                (location) => {
                    this.location = location;

                    //set the input to initial state
                    this.zipCode = null;
                    this.updateURL();
                },
                (err) => {
                    this.reportError(err);
                }
            );
        },

        loadDrugDetailsFn(keepSearchSettingsOpen) {
            if (keepSearchSettingsOpen == null)
                keepSearchSettingsOpen = this.isMobile();
            if (!keepSearchSettingsOpen) this.mobileDoEditSearchSettings = false;
            this.updateURL();
            this.$emit("load-drug-details", false);
        },

        zipCodeChanged() {
            this.showZipCodeWarning = false;
            this.updateURL();
            if (this.isValidZipCode()) this.$emit("load-drug-details", false);
        },

        labelChangedFn() {
            this.labelSetByHuman = true;
            this.selectedGsn = null;
            this.selectedNdc = null;
            // zero out the quantity https://app.asana.com/0/1200287405669226/1202953102117841
            this.selectedQuantityValue = null;
            this.customQuantity = null;
            this.customQuantityPackaged = null;
            this.updateURL();
            this.loadDrugDetails();
        },

        pharmacySelected(pharmacyName) {
            const selectedCouponDetails = this.drugDetails.drugs.find(
                (d) => d.pharmacyName === pharmacyName
            );
            var routeData = this.$router.resolve({
                name: "coupon-details-page",
                query: {
                    qty: this.getQuantity(),
                    form: this.selectedForm?.form,
                    dosage: this.selectedDosage?.strength,
                    ln: selectedCouponDetails.ln,
                    price: selectedCouponDetails.price,
                    pharmacyName: selectedCouponDetails.pharmacyName,
                    servicePhone: selectedCouponDetails.servicePhone,
                    helpdeskPhone: selectedCouponDetails.helpdeskPhone,
                    rxBin: selectedCouponDetails.rxBin,
                    rxPcn: selectedCouponDetails.rxPcn,
                    memberId: selectedCouponDetails.memberId,
                    rxGroup: selectedCouponDetails.rxGroup,
                    returnURL: encodeURIComponent(location.pathname + location.search),
                },
            });
            window.open(routeData.href, "_blank");
        },

        formChangedFn() {
            // default values should be null since GSN naturally changes when you change the form.
            // https://app.asana.com/0/1200287405669226/1201750904873164
            let form =
                this.drugDetails.forms.find((f) => f?.form === this.selectedFormValue) || null;
            this.selectedGsn = form?.gsn || null;
            this.selectedNdc = form?.ndc || null;
            // get rid of the current qty value; it's irrelevant now
            this.selectedQuantityValue = null;
            this.updateURL();
            this.loadDrugDetails();
        },

        dosageChangedFn() {
            // default values should be null since GSN naturally changes when you change the form.
            // https://app.asana.com/0/1200287405669226/1201750904873164
            let dosage = this.drugDetails.dosages.find(
                    (s) => s?.strength === this.selectedDosageValue
                ) || null;
            this.selectedGsn = dosage?.gsn || null;
            this.selectedNdc = dosage?.ndc || null;
            if (
                this.drugDetails.packageAwareQuantities.length > 0 &&
                this.drugDetails.packageAwareQuantities[0]?.quantityToDisplay != null &&
                this.drugDetails.packageAwareQuantities[0].quantityToDisplay?.length >
                1 &&
                this.drugDetails.packageAwareQuantities[0].quantityToDisplay.substring(
                    0,
                    2
                ) == "1 " // implied it is a packaged product if == '1 '
            ) {
                // is a packaged product
                // console.log("packaged product dosageChanged");
                this.selectedQuantityValue = this.drugDetails.dosages.find(
                    (s) => s?.strength === this.selectedDosageValue
                ).commonQty;
            }
            this.updateURL();
            this.loadDrugDetails();
        },

        quantityChangedFn() {
            // console.log(this.selectedQuantityValue);
            // quantities all have the same GSN so don't bother updating the GSN when the quantity changes.
            if (
                this.isValidZipCode() ||
                (this.location?.coords?.latitude && this.location?.coords?.longitude)
            ) {
                // The GSN has not changed, but the search results might.
                if (
                    this.selectedQuantity?.quantity === "Custom" &&
                    ((this.customQuantity == null &&
                        this.customQuantityPackaged == null) ||
                        (this.lastSearchedQuantity === Number(this.customQuantity) &&
                            this.lastSearchedQuantity ===
                            Number(this.customQuantityPackaged)))
                ) {
                    // We should wait for someone to specify a custom quantity before searching.
                    //
                    // If you search with a custom quantity & then try to search again with the same custom quantity then this code path is hit.
                    return;
                }
                // Note: Custom quantity handling for packaged products is done elsewhere
                this.updateURL();
                this.loadDrugDetails();
            }
        },

        updateURLFn() {
            this.updateRouterToMany({
                g: this.selectedGsn,
                nd: this.selectedNdc,
                f: this.selectedFormValue,
                l: this.selectedLabelValue,
                h: this.labelSetByHuman,
                d: this.selectedDosageValue,
                q: this.selectedQuantityValue,
                cq: this.customQuantity,
                cqp: this.customQuantityPackaged,
                p: this.pharmacyNameFilter,
                z: this.zipCode,
            })
        },
        updateRouterFrom(k) {
            let routeValue = this.$route.query[k];
            if (routeValue != null) {
                switch (k) {
                    case "g":
                        this.selectedGsn = routeValue;
                        break;
                    case "nd":
                        this.selectedNdc = routeValue;
                        break;
                    case "f":
                        this.selectedFormValue = routeValue;
                        break;
                    case "l":
                        this.selectedLabelValue = routeValue;
                        break;
                    case "h":
                        this.labelSetByHuman = routeValue;
                        break;
                    case "d":
                        this.selectedDosageValue = routeValue;
                        break;
                    case "q":
                        this.selectedQuantityValue = routeValue;
                        break;
                    case "cq":
                        this.customQuantity = routeValue;
                        break;
                    case "cqp":
                        this.customQuantityPackaged = routeValue;
                        break;
                    case "p":
                        this.pharmacyNameFilter = routeValue;
                        break;
                    case "z":
                        this.zipCode = routeValue;
                        break;
                }
            }
        },

        queryKeysToValues() {
            return {
                n: this.selectedLabelValue,
                g: this.selectedGsn,
                f: this.selectedFormValue,
                l: this.selectedLabelValue,
                h: this.labelSetByHuman,
                d: this.selectedDosageValue,
                q: this.selectedQuantityValue,
                cq: this.customQuantity,
                cqp: this.customQuantityPackaged,
                p: this.pharmacyNameFilter,
                z: this.zipCode,
            };
        },

        updateSelectedFromURL() {
            for (const k in this.queryKeysToValues()) {
                this.updateRouterFrom(k);
            }
        },

        doMounted() {
            this.resetData();

            // mounted is the earliest hook where we will have access to this.$route
            this.zipCode = this.getZipCodeCache();
            if (this.zipCode == null || !this.isValidZipCode()) {
                this.zipCode = "92101"; // default is in San Diego
            }
            if (this.$route.query.drugName != null)
                this.selectedLabelValue = this.$route.query.drugName;
            else if (this.$route.path != null && !this.$route.path.includes("admin"))
                this.selectedLabelValue = this.$route.path
                    .slice(1)
                    .replaceAll("%20", " ")
                    .replaceAll("+", " ");
            else
            {
                console.error('no this.drugName in search-drug-options');
            }

            // Set up the functions so they are accessible from search-drug-options.ts
            this.updateURL = this.updateURLFn;
            this.loadDrugDetails = this.loadDrugDetailsFn;
            this.labelChanged = this.labelChangedFn;
            this.formChanged = this.formChangedFn;
            this.dosageChanged = this.dosageChangedFn;
            this.quantityChanged = this.quantityChangedFn;

            this.updateSelectedFromURL();
            this.updateURL();
        }
    },
    created() {
        // Don't put anything here without examining doMounted() and the watch: {} on this route
    },
    mounted() {
        // reset the stores
        const s = useSearchDrugOptionsStore();
        s.$reset();
        const s2 = useDrugDetailsFromServerStore();
        s2.$reset();

        // doMounted should be done before reload because it sets up the parent component for success
        // What I really want is to mimic the normal component loading experience
        // As far as I can tell, the child component seems to run its mounted() before the parent
        // so that is what I am doing here.
        this.doMounted();

        this.$emit("reload", false);

    },
    watch: {
        $route(to, from) {
            if (to.path == from.path) {
                // We do not need to re-work all of search-drug-options if the label name has not changed
                // This is triggered whenever we update the URL when staying on search-drug-page or similar
                return;
            }

            // The route has changed
            // This happens if search-bar triggers a change
            // We "want" to do a hard refresh to completely reset the application state
            // We need to trigger these changes not only here, but also in the parent search-drug-page
            
            // reset the stores
            const s = useSearchDrugOptionsStore();
            s.$reset();
            const s2 = useDrugDetailsFromServerStore();
            s2.$reset();

            // This is basically what this.reload() does in the parent component too
            this.doMounted();
            this.$emit("reload", false);
        }
    }
};
</script>

<style scoped>
.input-height {
    height: 48px;
}

.labeled-dropdown-wrapper>>>.dropdown-light {
    padding-left: 20px;
    text-align: left;
    letter-spacing: 0.4px;
}

@media (max-width: 992px) {
    .dropdowns-row>div {
        margin-bottom: 20px;
    }

    .search-button {
        margin-top: 10px;
    }
}
</style>
