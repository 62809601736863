<template>
    <div class="container-fluid">
        <div class="row">
            <try-the-app v-if="this.showTryTheApp" />
        </div>
        <div class="row">
            <newsletter v-if="this.showNewsletter" />
        </div>
        <hr v-if="!this.showNewsletter" />
        <footer class="container-fluid">
            <div class="container-fluid footer-container">
                <div class="row">
                    <div class="col col-md-4 col-12 footer-item">
                        <div class="
                text-sm-center
                float-sm-none
                text-md-left
                float-md-left
                pb-3
              ">
                            <h1 class="h4" style="font-weight: var(--heading-font-weight)">Resources</h1>
                            <p>
                                <router-link class="text-reset" to="/faq">Help and FAQ</router-link>
                            </p>

                            <p>
                                <router-link class="text-reset" to="/goodrx-comparison">GoodRx Comparison</router-link>
                            </p>

                            <p>
                                <router-link class="text-reset" to="/cvs-savings-finder-comparison">CVS Savings Finder
                                    Comparison</router-link>
                            </p>

                            <p>
                                <router-link class="text-reset" to="/optum-perks-comparison">Optum Perks Comparison</router-link>
                            </p>

                            <p>
                                <router-link class="text-reset" to="/irxsavvy">IRx Savvy</router-link>
                            </p>

                            <p>
                                <router-link class="text-reset" to="/how-it-works">How it works</router-link>
                            </p>

                            <p>
                                <router-link class="text-reset" to="/consumer-discount-cards">Consumer Discount
                                    Card</router-link>
                            </p>

                            <p>
                                <router-link class="text-reset" to="/mobile-app">Mobile App</router-link>
                            </p>
                        </div>
                    </div>
                    <div class="col col-md-4 col-12 footer-item">
                        <div class="
                text-sm-center text-md-left
                float-md-left float-sm-none
                pb-3
              ">
                            <h1 class="h4" style="font-weight: var(--heading-font-weight)">Company</h1>
                            <p>
                                <router-link class="text-reset" to="/careers">Careers</router-link>
                            </p>
                            <p>
                                <router-link class="text-reset" to="/about">About us</router-link>
                            </p>
                            <p>
                                <router-link class="text-reset" to="/contact">Contact Us</router-link>
                            </p>
                            <p>
                                <router-link class="text-reset" to="/privacy-policy">Privacy Policy</router-link>
                            </p>
                            <p>
                                <router-link class="text-reset" to="/terms-of-use">Terms of use</router-link>
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row" style="color: var(--secondary)">
                    <div class="col col-md-4 col-12 text-sm-center text-md-left pb-3">
                        <a href="https://www.facebook.com/intelligentrx" target="_blank" rel="noopener noreferrer"
                            class="text-reset mr-4" aria-label="IntelligentRx facebook page">
                            <font-awesome-icon icon="fa-brands fa-square-facebook" size="3x"
                                aria-label="IntelligentRx facebook page" />
                        </a>
                        <a href="https://instagram.com/intelligentrx/" target="_blank" rel="noopener noreferrer"
                            class="text-reset mr-4" aria-label="IntelligentRx instagram page">
                            <font-awesome-icon icon="fa-brands fa-square-instagram" size="3x"
                                aria-label="IntelligentRx instagram page" />
                        </a>
                        <a href="https://twitter.com/IntelligentRx/" target="_blank" rel="noopener noreferrer"
                            class="text-reset mr-4" aria-label="IntelligentRx twitter page">
                            <font-awesome-icon icon="fa-brands fa-square-twitter" size="3x"
                                aria-label="IntelligentRx twitter page" />
                        </a>
                    </div>
                </div>
                <div class="row" style="color: var(--secondary)">
                    <div class="col col-md-6 col-12 text-sm-center text-md-left">
                        <span>IntelligentRx &copy; 2020-{{ currentYear }} All Rights Reserved</span>
                    </div>
                </div>
                <div class="row content-row">
                    <div class="col">
                        <p class="footer-content">
                            DISCOUNT ONLY – THIS IS NOT INSURANCE. There are no enrollment or
                            membership fees for the program. Discounts are available
                            exclusively through participating pharmacies. The range of the
                            discounts will vary depending upon the type of provider and
                            services rendered. Members are required to pay for all health care
                            services, but can receive a discount from participating
                            pharmacies. For customers in MA, this plan is not health insurance
                            coverage and does not meet minimum credible coverage requirements
                            under MGL c. 111M and 956 CMR 5.00. Prescription prices may vary
                            from pharmacy to pharmacy and are subject to change. The pricing
                            estimates given are based on the most recent information available
                            and may change based on when you actually fill your prescription
                            at the pharmacy. Average savings of 72%, with potential savings of
                            up to 85% off of the regular retail prices based upon 2019
                            national program savings data. The savings provided are calculated
                            based on the pharmacy’s retail price. PROGRAM IS NOT HEALTH
                            INSURANCE OR A MEDICARE PRESCRIPTION DRUG PLAN OR A SUBSTITUTION
                            FOR INSURANCE, and it may not be used in conjunction with any
                            insurance plan or government funded program such as Medicare or
                            Medicaid.
                        </p>
                        <p class="footer-content">
                            The use of names, logos, brands, and trademarks does not apply any
                            affiliation or endorsement by their owners and they remain the
                            property of their respective owners.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import Newsletter from "../controls/newsletter.vue";
import tryTheApp from "../controls/try-the-app.vue";
export default {
    components: {
        tryTheApp,
        Newsletter,
    },
    name: "footer-section",
    data() {
        return {
            showTryTheApp: true,
            showNewsletter: true,
            currentYear: 2024,
        }
    },
    mounted() {
        this.showTryTheApp = false;
        this.showNewsletter = false;
        this.currentYear = new Date().getFullYear();
    }
};
</script>

<style scoped>
.footer-container {
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer-navigation>* {
    font: var(--body-font-weight) 19px/23px var(--body-font-family);
    letter-spacing: 0px;
    color: var(--secondary);
    opacity: 1;
}

.footer-navigation>span {
    padding-left: 10px;
    padding-right: 10px;
}

.footer-item {
    font: 16px/21px var(--body-font-family);
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.footer-content {
    text-align: left;
    font: var(--body-font-weight) 14px/22px var(--body-font-family);
    letter-spacing: 0px;
    color: var(--secondary);
    opacity: 1;
}

.content-row {
    padding-top: 20px;
}

.logo-row {
    padding-top: 30px;
}

@media (max-width: 992px) {
    .footer {
        padding: 40px 0px 50px 0px;
    }

}
</style>