import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5838518"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row pitch-image-background" }
const _hoisted_3 = {
  key: 0,
  class: "col col-12 col-lg-6"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "col col-12 col-lg-6" }
const _hoisted_6 = { class: "d-flex flex-column align-items-left justify-content-center h-100" }
const _hoisted_7 = { class: "display-4 font-weight-bold" }
const _hoisted_8 = { class: "pitch-font" }
const _hoisted_9 = {
  key: 1,
  class: "col col-12 col-lg-6"
}
const _hoisted_10 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.getOverallClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ($props.imageLeft)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", {
                src: require(`@/assets/${$props.imageSrc}`),
                class: "img-fluid pitch-image",
                alt: $data.imgAltText
              }, null, 8, _hoisted_4)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h2", _hoisted_7, _toDisplayString($props.title), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString($props.text), 1)
          ])
        ]),
        (!$props.imageLeft)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("img", {
                src: require(`@/assets/${$props.imageSrc}`),
                class: "img-fluid pitch-image",
                alt: $data.imgAltText
              }, null, 8, _hoisted_10)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}