import { DrugFormulation } from '@/components/irxsavvy/all-drug-info.vue';
import { createPinia, defineStore } from 'pinia'
 
interface DrugDetailsFromServer {
    //
    drugDetails: any | null
    // Used as a fallback when drugDetails aren't available
    // This happens in equivalent-effects.vue
    drugFormulations: DrugFormulation[]
}


// this is a helper
function getFormsDosagesQtys(state) {
    const formsDict: { [key: string]: any } = {};
    const dosagesdict: { [key: string]: any } = {};
    const packageAwareQuantitiesDict: { [key: string]: any } = {};
    for (let i = 0; i < state.drugFormulations.length; i++) {
        const drug: any = state.drugFormulations[i];
        formsDict[drug.form] = {
            gsn: drug.gsn,
            ndc: drug.ndc,
            form: drug.form,
            isSelected: drug.isSelected || formsDict[drug.form]?.isSelected,
        };
    }
    const forms = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, value] of Object.entries(formsDict)) {
        forms.push(value);
    }
    const selectedForm = forms.find(f => f?.isSelected) || null;
    
    for (let i = 0; i < state.drugFormulations.length; i++) {
        const drug: any = state.drugFormulations[i];
        // only those dosages which are related to the current form are relevant
        if (selectedForm == null || selectedForm.form !== drug.form) {
            continue;
        }

        const strength =
            drug.dosageQuantity.toString() + " " + drug.dosageUnit;
        dosagesdict[strength] = {
            gsn: drug.gsn,
            ndc: drug.ndc,
            strength: strength,
            dosageQuantity: drug.dosageQuantity,
            isSelected: drug.isSelected || dosagesdict[strength]?.isSelected,
        };
    }
    const dosages = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, value] of Object.entries(dosagesdict)) {
        dosages.push(value);
    }
    const selectedDosage = dosages.find(f => f?.isSelected) || null;
    
    for (let i = 0; i < state.drugFormulations.length; i++) {
        const drug: any = state.drugFormulations[i];
        // only those quantities which are related to the current form & dosage are relevant
        if (selectedForm == null || selectedForm.form !== drug.form || selectedDosage == null || selectedDosage.dosageQuantity !== drug.dosageQuantity) {
            continue;
        }
        packageAwareQuantitiesDict[drug.quantity] = {
            gsn: drug.gsn,
            quantity: drug.quantity,
            quantityLabel: drug.quantityLabel,
            quantityToDisplay: drug.quantityToDisplay,
            isSelected:
                drug.isSelected ||
                packageAwareQuantitiesDict[drug.quantity]?.isSelected,
        };
    }

    const packageAwareQuantities = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_, value] of Object.entries(packageAwareQuantitiesDict)) {
        packageAwareQuantities.push(value);
    }

    forms.sort(function (a: any, b: any) {
        if (a.form < b.form) {
            return -1;
        }
        if (a.form > b.form) {
            return 1;
        }
        return 0;
    });
    dosages.sort(function (a: any, b: any) {
        if (a.dosageQuantity < b.dosageQuantity) {
            return -1;
        }
        if (a.dosageQuantity > b.dosageQuantity) {
            return 1;
        }
        return 0;
    });
    packageAwareQuantities.sort(function (a: any, b: any) {
        if (a.quantity < b.quantity) {
            return -1;
        }
        if (a.quantity > b.quantity) {
            return 1;
        }
        return 0;
    });
    return [forms, dosages, packageAwareQuantities];
}

export const useDrugDetailsFromServerStore = defineStore('drugDetailsFromServer', {
    state: (): DrugDetailsFromServer => {
        return {
            drugDetails: null,
            drugFormulations: []
        };
    },
    getters: {
        getLabels: (state) => {
            if (state.drugDetails == null) {
                if (state.drugFormulations.length < 1) {
                    return null;
                }
                const brandGeneric = state.drugFormulations[0].isGeneric ? "G" : "B";
                const names = [
                    {
                        drugName: state.drugFormulations[0].name,
                        brandGeneric: brandGeneric,
                        isSelected: true,
                    }
                ];
                return names;
            }
            const l = state.drugDetails?.names;
            return l;
        },
        getForms: (state) => {
            if (state.drugDetails == null) {
                if (state.drugFormulations.length < 1) {
                    return null;
                }
                const [forms, _, __] = getFormsDosagesQtys(state);
                // all forms should be relevant
                return forms;
            }
            
            const f = state.drugDetails?.forms;
            return f;
        },
        getDosages: (state) => {
            if (state.drugDetails == null) {
                if (state.drugFormulations.length < 1) {
                    return null;
                }
                const [_, dosages, __] = getFormsDosagesQtys(state);
                return dosages;
            }
            
            const d = state.drugDetails?.dosages;
            return d;
        },
        getQuantities: (state) => {
            if (state.drugDetails == null) {
                if (state.drugFormulations.length < 1) {
                    return null;
                }
                const [_, __, packageAwareQuantities] = getFormsDosagesQtys(state);
                return packageAwareQuantities;
            }

            const packageAwareQuantities = state.drugDetails?.packageAwareQuantities;
            if (
                packageAwareQuantities != null &&
                packageAwareQuantities.length > 0 &&
                packageAwareQuantities[packageAwareQuantities.length - 1].quantity !=
                "Custom" &&
                packageAwareQuantities[0]?.quantityToDisplay != null &&
                packageAwareQuantities[0].quantityToDisplay.length > 1 &&
                packageAwareQuantities[0].quantityToDisplay.substring(0, 2) == "1 " // implied it is a packaged product if == '1 '
            ) {
                packageAwareQuantities.push({
                    gsn: packageAwareQuantities[0].gsn,
                    quantity: "Custom",
                    quantityLabel: "",
                    quantityToDisplay: "Custom",
                    ranking: packageAwareQuantities.length,
                    isSelected: false,
                });
            } else if (
                packageAwareQuantities != null &&
                packageAwareQuantities.length > 0 &&
                packageAwareQuantities[packageAwareQuantities.length - 1].quantity !=
                "Custom"
            ) {
                packageAwareQuantities.push({
                    gsn: packageAwareQuantities[0].gsn,
                    quantity: "Custom",
                    quantityLabel: "",
                    quantityToDisplay: "Custom",
                    ranking: packageAwareQuantities.length,
                    isSelected: false,
                });
            }

            return packageAwareQuantities;
        },
        getPackageAwareQuantities: (state) => {
            if (state.drugDetails == null) {
                if (state.drugFormulations.length < 1) {
                    return null;
                }
                return state.drugFormulations;
            }

            const packageAwareQuantities = state.drugDetails?.packageAwareQuantities;
            if (
                packageAwareQuantities != null &&
                packageAwareQuantities.length > 0 &&
                packageAwareQuantities[packageAwareQuantities.length - 1].quantity !=
                "Custom"
            ) {
                // note: this code path is (almost) never taken
                packageAwareQuantities.push({
                    gsn: packageAwareQuantities[0].gsn,
                    quantity: "Custom",
                    quantityLabel: "",
                    quantityToDisplay: "Custom",
                    ranking: packageAwareQuantities.length,
                    isSelected: false,
                });
            }
            return packageAwareQuantities;
        },
    },
    actions: {
    }
});

const store = createPinia()

export default store
