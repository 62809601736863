const employerProgramPitches = {
    banner: {
        imageSrc: "family-smiling-1250-900.jpg",
    },
    highlightOverlayThomas: {
        title: "Expertise Matters",
        text: "With over 10 years of clinical pharmacy experience, CEO PharmD. Thomas Kosgei and our team of certified pharmacy technicians have already helped 350,000 patients save over 20 million dollars",
        imageSrc: "employer-program/thomas-headshot-transparent-background.png",
    },
    healthPlanhighlightOverlayThomas: {
        title: "Expertise Matters",
        text: "With over 10 years of clinical pharmacy experience, CEO PharmD. Thomas Kosgei and our team of certified pharmacy technicians have already helped 350,000 patients save over 20 million dollars",
        imageSrc: "employer-program/thomas-headshot-transparent-background.png",
    },
    commonProblemSolutionStats: [
        {
            title: "Where Does Pharmacy Spending Go?",
            iconInAssets: "employer-program/school_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "educational icon",
            pitchImageInAssets: "employer-program/pills-on-money.jpg",
            pitchImageAltText: "Pills on Money",
            stats: [
                {
                    stat: "50%",
                    description:
                        "of spending is on expensive specialty drugs taken by 2% of Americans",
                },
                {
                    stat: "Up to 30%",
                    description:
                        "savings from switching to less expensive drugs and formulations",
                },
                {
                    stat: "$224,800,000",
                    description:
                        "Ohio's 1-year Medicaid Losses due to Spread Pricing",
                },
                {
                    stat: "$123,500,000",
                    description: "Kentucky's 1-year Losses due to Spread Pricing",
                },
            ],
        },
        {
            title: "Why Spending Is Unhinged",
            iconInAssets: "employer-program/question_mark_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Question Mark Icon",
            pitchImageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up a Drug Price and Looking Concerned",
            stats: [
                {
                    stat: "0",
                    description:
                        "typical monetary incentive for prescribers to prescribe less expensive drugs",
                },
                {
                    stat: "Negative",
                    description:
                        "incentive for traditional insurance companies to fix the problem; they make larger profits when employers and employees spend more",
                },
                {
                    stat: "90%+",
                    description:
                        "of pharmacy claims go through traditional insurance companies which profit from spread pricing and kickbacks",
                },
                {
                    stat: "Negative",
                    description: "patient incentive to use inexpensive drugs when using a \"copay card\" which pays off patient deductibles while costing employers thousands a month",
                }
            ],
        },
        {
            title: "IntelligentRx's Solution",
            iconInAssets: "employer-program/emoji_objects_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Light Bulb Icon",
            pitchImageInAssets: "employer-program/pharmacist-discussing-with-patient.png",
            pitchImageAltText: "Pharmacist talking to a patient",
            stats: [
                {
                    stat: "30%",
                    description:
                        "savings target we achieve by switching employees to less expensive drugs and formulations",
                },
                {
                    stat: "100%",
                    description:
                        "of realized savings are directed to patients and the employer",
                },
                {
                    stat: "3:1",
                    description:
                        "Employer ROI target 24 months after implementation",
                },
                {
                    stat: "$0 / month",
                    description:
                        "cost until the employer's monthly savings exceed the program's monthly cost",
                },
            ],
        },
    ],
    healthPlancommonProblemSolutionStats: [
        {
            title: "Where Does Pharmacy Spending Go?",
            iconInAssets: "employer-program/school_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "educational icon",
            pitchImageInAssets: "employer-program/pills-on-money.jpg",
            pitchImageAltText: "Pills on money",
            stats: [
                {
                    stat: "50%",
                    description:
                        "of spending is on expensive specialty drugs taken by 2% of Americans",
                },
                {
                    stat: "Up to 30%",
                    description:
                        "savings from switching to less expensive drugs and formulations",
                },
                {
                    stat: "$224,800,000",
                    description:
                        "Ohio's 1-year Medicaid Losses due to Spread Pricing",
                },
                {
                    stat: "$123,500,000",
                    description: "Kentucky's 1-year Losses due to Spread Pricing",
                },
            ],
        },
        {
            title: "Why Spending Is Unhinged",
            iconInAssets: "employer-program/question_mark_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "question mark icon",
            pitchImageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up a Drug Price and Looking Concerned",
            stats: [
                {
                    stat: "0",
                    description:
                        "typical monetary incentive for prescribers to prescribe less expensive drugs",
                },
                {
                    stat: "Negative",
                    description:
                        "incentive for traditional PBMs to fix the problem; they make larger profits when health plans and members spend more",
                },
                {
                    stat: "90%+",
                    description:
                        "of pharmacy claims go through traditional PBMs which profit from spread pricing and kickbacks",
                },
                {
                    stat: "Negative",
                    description: "patient incentive to use inexpensive drugs when using a \"copay card\" which pays off patient deductibles while costing health plans thousands a month",
                }
            ],
        },
        {
            title: "IntelligentRx's Solution",
            iconInAssets: "employer-program/emoji_objects_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Idea icon",
            pitchImageInAssets: "employer-program/pharmacist-discussing-with-patient.png",
            pitchImageAltText: "Pharmacist talking to a patient",
            stats: [
                {
                    stat: "30%",
                    description:
                        "savings target we achieve by switching health plans to less expensive drugs and formulations",
                },
                {
                    stat: "100%",
                    description:
                        "of realized savings are directed to patients and the members",
                },
                {
                    stat: "3:1",
                    description:
                        "Health Plan ROI target 24 months after implementation",
                },
                {
                    stat: "$0 / month",
                    description:
                        "cost until the health plan's monthly savings exceed the program's monthly cost",
                },
            ],
        },
    ],
    commonProsCons: {
        prosHeader: "Large Cost Savings",
        prosBullets: [
            "Both employers and employees save money",
            "Service is FREE until savings exceed costs",
            "Transparent Savings Reporting",
            '"Hands Off" for employers after implementation',
        ],
        consHeader: "Important Limitations",
        consBullets: [
            "Employer must encourage employees to sign up",
            "Small Employers typically must facilitate PBM <-> IntelligentRx relationship",
        ],
    },
    healthPlancommonProsCons: {
        prosHeader: "Large Cost Savings",
        prosBullets: [
            "Both health plans and members save money",
            "Service is FREE until savings exceed costs",
            "Transparent Savings Reporting",
            '"Hands Off" for health plans after implementation',
        ],
        consHeader: "Important Limitations",
        consBullets: [
            "Health Plans must encourage members to sign up",
            "Health Plans must facilitate PBM <-> IntelligentRx relationship",
        ],
    },
    integratingWithExistingPlan: [
        {
            title: "Any PBM",
            summary: "",
            details: "We can easily plug and play with any PBM by using industry standard data interchange formats",
            iconInAssets: "employer-program/domain_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "PBM Building Icon",
            moreDetailsLink: "",
        },
        {
            title: "Any Benefit Design",
            summary: "",
            details: "Savings suggestions can vary based on plan benefit design, but any plan design can work with our systems",
            iconInAssets: "employer-program/medical_information_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Medical Badge Icon",
            moreDetailsLink: "",
        },
        {
            title: "Any Pharmacy",
            summary: "",
            details: "We provide savings suggestions across a wide variety of pharmacy networks",
            iconInAssets: "employer-program/store_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Store Icon",
            moreDetailsLink: "",
        },
    ],
    employerROI: [
        {
            title: "Lowering Drug Spend For You and Your Employees",
            summary: "",
            details: "IntelligentRx's solution helps you and your employees reduce prescription drug spend",
            iconInAssets: "employer-program/trending_down_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Arrow Trending Downward Icon",
            imageInAssets: "employer-program/provider-months-supply.jpg",
            pitchImageAltText: "Provider Months Supply",
            moreDetailsLink: "",
        },
        {
            title: "Positive ROI for Every Customer",
            summary: "",
            details: "Our services are free until we're saving you more than we charge.",
            iconInAssets: "employer-program/add_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Plus Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
        {
            title: "Employee Savings",
            summary: "",
            details: "Employees often save through reduced copays, which can increase medication adherence and improve employees health.",
            iconInAssets: "employer-program/payments_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Money Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        }
    ],
    healthPlanemployerROI: [
        {
            title: "Lowering Drug Spend For You and Your Members",
            summary: "",
            details: "IntelligentRx's solution helps you and your members reduce prescription drug spend",
            iconInAssets: "employer-program/trending_down_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Arrow Trending Downward Icon",
            imageInAssets: "employer-program/provider-months-supply.jpg",
            pitchImageAltText: "Provider Months Supply",
            moreDetailsLink: "",
        },
        {
            title: "Positive ROI for Every Customer",
            summary: "",
            details: "Our services are free until we're saving you more than we charge.",
            iconInAssets: "employer-program/add_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Plus Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
        {
            title: "Member Savings",
            summary: "",
            details: "Members often save through reduced copays, which can increase medication adherence and improve members health.",
            iconInAssets: "employer-program/payments_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Money Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        }
    ],
    employerOurValuesInOurProduct: [
        {
            title: "Experienced Pharmacy Teams",
            summary: "",
            details: "IntelligentRx clinical pharmacists and pharmacy technicians provide timely and personalized support to ensure employees are receiving cost effective therapeutic options.",
            iconInAssets: "employer-program/groups_2_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Group of People Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
        {
            title: "Transparency",
            summary: "",
            details: "We pride ourselves on our transparency. As our valued customer, you'll be able to see how much money you're saving and how we're helping you save that money without compromising patient privacy.",
            iconInAssets: "employer-program/query_stats_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Examining A Chart Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
        {
            title: "Continuously Improving",
            summary: "",
            details: "We continuously work to reduce drug spending through a combination of clinical reviews of drug usage and continuous improvement of our automated systems to catch \"quick wins\" for our customers.",
            iconInAssets: "employer-program/trending_up_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Arrow trending upwards Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
    ],
    healthPlanemployerOurValuesInOurProduct: [
        {
            title: "Experienced Pharmacy Teams",
            summary: "",
            details: "IntelligentRx clinical pharmacists and pharmacy technicians provide timely and personalized support to ensure members are receiving cost effective therapeutic options.",
            iconInAssets: "employer-program/groups_2_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Group of People Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
        {
            title: "Transparency",
            summary: "",
            details: "We pride ourselves on our transparency. As our valued customer, you'll be able to see how much money you're saving and how we're helping you save that money without compromising patient privacy.",
            iconInAssets: "employer-program/query_stats_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Examining A Chart Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
        {
            title: "Continuously Improving",
            summary: "",
            details: "We continuously work to reduce drug spending through a combination of clinical reviews of drug usage and continuous improvement of our automated systems to catch \"quick wins\" for our customers.",
            iconInAssets: "employer-program/trending_up_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Arrow trending upwards Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
    ],
    managingComplexity: [
        {
            title: "Handling Complexity For You",
            summary: "Handling Complexity For You",
            details:
                "Managing pharmacy benefits is increasing in complexity year over year as more sophisticated and costly drugs are being approved. The traditional strategy of using formulary tiers and preauthorizations to encourage patients to use less expensive generic drugs has broken down as specialty drugs, which rarely have a generic, now account for half of all spending. With IntelligentRx for employers, we become your partner in simplifying this complexity while lowering costs.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/pharmacist-pointing-to-drug-with-patient.jpg",
            pitchImageAltText: "Pharmacist pointing to drug with patient",
            moreDetailsLink: "",
        },
        {
            title: "Wrangling the Specialty Drug Beast",
            summary: "Wrangling the Specialty Drug Beast",
            details:
                "Specialty drug spend is the single fastest growing healthcare cost. Providers prescribe them because of their potential clinical value, and sometimes these drugs are the only option for patients. However, some specialty drug spending is done prior to investigating other therapies. For example, some patients who have osteoarthritis are prescribed Humira (which costs thousands of dollars per month) even though there are effective generic medications which cost 10 dollars per month. When prescriber missteps like this happen, most employers are none the wiser. IntelligentRx's pharmacy team conducts clinical reviews of all specialty drugs to ensure mistakes like this one are corrected, saving employers and their employees tens of thousands of dollars.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
        {
            title: "Automatic Optimizations",
            summary: "Automatic Optimizations",
            details:
                "We use automatic optimizations to save money on non-specialty drugs, which make up 50% of drug spending. IntelligentRx leverages an unmatched combination of pharmacy expertise, analytics, tech, industry experience, and pragmatism to provide employers and health plans with the support that they need to control the ever rising cost of drugs across their pharmacy and medical benefits. We take all of this knowledge and expertise and apply it to our technology, which allows us to automatically suggest optimizations on non-specialty drugs. The savings on this spending are often unimpressive on a drug-by-drug basis, but in aggregate they represent a large savings for employers.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/provider-with-money-symbols.jpg",
            pitchImageAltText: "Provider with money symbols",
            moreDetailsLink: "",
        },
    ],
    healthPlanmanagingComplexity: [
        {
            title: "Handling Complexity For You",
            summary: "Handling Complexity For You",
            details:
                "Managing pharmacy benefits is increasing in complexity year over year as more sophisticated and costly drugs are being approved. The traditional strategy of using formulary tiers and preauthorizations to encourage patients to use less expensive generic drugs has broken down as specialty drugs, which rarely have a generic, now account for half of all spending. With IntelligentRx for Health Plans, we become your partner in simplifying this complexity while lowering costs.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/pharmacist-pointing-to-drug-with-patient.jpg",
            pitchImageAltText: "Pharmacist pointing to drug with patient",
            moreDetailsLink: "",
        },
        {
            title: "Wrangling the Specialty Drug Beast",
            summary: "Wrangling the Specialty Drug Beast",
            details:
                "Specialty drug spend is the single fastest growing healthcare cost. Providers prescribe them because of their potential clinical value, and sometimes these drugs are the only option for patients. However, some specialty drug spending is done prior to investigating other therapies. For example, some patients who have osteoarthritis are prescribed Humira (which costs thousands of dollars per month) even though there are effective generic medications which cost 10 dollars per month. When prescriber missteps like this happen, most health plans are none the wiser. IntelligentRx's pharmacy team conducts clinical reviews of all specialty drugs to ensure mistakes like this one are corrected, saving health plans and their members tens of thousands of dollars.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/consumer-looking-up-drug-price-and-concerned.png",
            pitchImageAltText: "Consumer Looking Up Drug Price and Concerned",
            moreDetailsLink: "",
        },
        {
            title: "Automatic Optimizations",
            summary: "Automatic Optimizations",
            details:
                "We use automatic optimizations to save money on non-specialty drugs, which make up 50% of drug spending. IntelligentRx leverages an unmatched combination of pharmacy expertise, analytics, tech, industry experience, and pragmatism to provide health plans and health plans with the support that they need to control the ever rising cost of drugs across their pharmacy and medical benefits. We take all of this knowledge and expertise and apply it to our technology, which allows us to automatically suggest optimizations on non-specialty drugs. The savings on this spending are often unimpressive on a drug-by-drug basis, but in aggregate they represent a large savings for health plans.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/provider-with-money-symbols.jpg",
            pitchImageAltText: "Provider with money symbols",
            moreDetailsLink: "",
        },
    ],
    securityPitch: {
        title: "Healthcare Grade Security",
        text: "IntelligentRx makes every effort to ensure the security of patient data. We are HIPAA compliant and conduct routine safety and security reviews to ensure the safety and security of our systems and processes.",
        imageSrc: "employer-program/pharmacist.jpg",
    },
    discountCards: {
        title: "Consumer Savings with Discount Cards",
        text: "Due to the complex pricing arrangements between PBMs and pharmacies, sometimes using a discount card can yield better prices than using your insurance. When this happens, we can pull upon our partnerships with 12 different discount card issuers to ensure you're paying a low price.",
        imageSrc: "employer-program/patient-checking-prescription-price.png",
    },

    /*IntelligentRx is a s a FREE, confidential pharmacy benefit program that works in conjunction with your prescription coverage to provide transparency into prescription costs and provides lower-cost alternative options and offers personalized prescription guidance for every member through their health plan or employer. 

The IntelligentRx program offers several ways to save money through the convenience of your mobile device or online portal.
Helps locate better prices for your prescription drugs.
Identifies medications that perform the same as your current or prescribed medication, but with a lower out-of-pocket cost.
Allows you to speak with a clinical pharmacist or a certified pharmacy tech for personal assistance.
Provides discounts on medications that are not covered by your health plan

IntelligentRx is the easiest, most comprehensive online prescription savings tool that shows you all the ways to spend less money on your prescription medications. It will also automatically notify you if you are paying too much for your prescription drugs and finds you other ways to get the same treatment for less money. Now you can easily see all the ways you can save money on your prescriptions, according to your health insurance plan. This service is offered to you through your health insurance or your employer. */
    healthPlans: [
        {
            title: "With Your Plan",
            summary: "",
            details:
                "We can integrate with any plan, PBM, formulary, or pharmacy network because all we need to succeed is claim data and member consent.",
            iconInAssets: "employer-program/article_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Document Icon",
            imageInAssets: "",
            moreDetailsLink: "",
        },
        {
            title: "Improved Adherence",
            summary: "",
            details:
                "Medication adherence is correlated with drug cost, and by reducing drug costs we're able to increase adherence.",
            iconInAssets: "employer-program/medication_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Medication Icon",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Always Positive ROI",
            summary: "",
            details:
                "We're so confident that we'll achieve savings that we charge health plans nothing until our program's savings exceed the program's cost.",
            iconInAssets: "employer-program/attach_money_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Dollar Sign Icon",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
    ],
    benefitConsultants: [
        {
            title: "With Your Plan",
            summary: "",
            details:
                "We can integrate with any plan, PBM, formulary, or pharmacy network because all we need to succeed is claim data and employee consent.",
            iconInAssets: "employer-program/article_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Document Icon",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Flexible Implementation",
            summary: "",
            details:
                "We're able to implement at any time during the plan year and achieve implementation as fast (or as slow!) as the customer desires. We can do this because our largest investment in our customers comes from clincial decision making which starts after implementation.",
            iconInAssets: "employer-program/turn_sharp_right_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Medication Icon",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Always Positive ROI",
            summary: "",
            details:
                "We're so confident that we'll achieve savings that we charge customers nothing until our program's savings exceed the program's cost.",
            iconInAssets: "employer-program/attach_money_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Dollar Sign Icon",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
    ],
    savingsForMembers: [
        {
            title: "Switching and Saving",
            summary: "",
            details:
                "With your approval, IntelligentRx's pharmacy team does the clinical work needed to switch you to drugs which have the same clinical value, but which are less expensive on your specific insurance plan. Some patients save thousands of dollars a year!",
            iconInAssets: "employer-program/payments_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Money Icon",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Saving Recommendations",
            summary: "",
            details:
                "With your approval, we offer recommendations to reduce the cost of your prescriptions. These recommendations take advantage of the complicated drug pricing industry to help you pay the least amount of money possible for prescriptions. The savings from following these recommendations can be surprisingly high - cutting your out of pocket costs in half or more!",
            iconInAssets: "employer-program/star_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Star Icon",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Member Portal",
            summary: "",
            details:
                "IntelligentRx's member portal is your view into our prescription savings services. After you sign up, our pharmacy staff will be able to reach out to you to let you know if there's a way for you to save money. Making the switch is as simple as clicking a button and then waiting for your updated prescription to be ready for pickup. We handle everything else for you!",
            iconInAssets: "employer-program/devices_FILL0_wght400_GRAD0_opsz48.svg",
            iconAltText: "Laptop and Smart Phone Icon",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
    ],
    healthPlanhowItWorks: [
        {
            title: "Implementation",
            summary: "Implementation",
            details: "Implementation is coordinated between IntelligentRx and your member benefits team. IntelligentRx works directly with your PBM to receive the historical and new clinical data we need. Meanwhile, we work together to encourage adoption of the patient portal so that patients can opt in to pharmacy savings. As soon as a patient opts in, we begin reviewing their clinical data and reach out directly to them to suggest ways to save on their prescriptions.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/person-typing.png",
            pitchImageAltText: "Person Typing",
            moreDetailsLink: "",
        },
        {
            title: "Patient Experience",
            summary: "Patient Experience",
            details: "Employees must opt-in to our services. After they have done so, we can pull their clinical data and reach out to them via email or a phone call to receive their consent to let us help them save money on their prescriptions. If a change to their prescription needs to be made, we handle the change by working directly with their provider. Savings can be in the hundreds of dollars, and all the patient has to do is consent to the saving suggestion!",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/person-typing.png",
            pitchImageAltText: "Person Typing",
            moreDetailsLink: "",
        },
        {
            title: "Health Plan Experience",
            summary: "Health Plan Experience",
            details: "Health plans receive on demand reporting from our web portal and periodic support calls with their implementation and support team. We pride ourselves on our transparency, and that means giving health plans access to the same detailed reporting our internal members view. We are also able to answer questions about plan design to help health plans negotiate effectively with their insurance company.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/person-typing.png",
            pitchImageAltText: "Person Typing",
            moreDetailsLink: "",
        }
    ],
    howItWorks: [
        {
            title: "Implementation",
            summary: "Implementation",
            details: "Implementation is coordinated between IntelligentRx and your employee benefits team. IntelligentRx works directly with your PBM to receive the historical and new clinical data we need. Meanwhile, we work together to encourage adoption of the patient portal so that patients can opt in to pharmacy savings. As soon as a patient opts in, we begin reviewing their clinical data and reach out directly to them to suggest ways to save on their prescriptions.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/person-typing.png",
            pitchImageAltText: "Person Typing",
            moreDetailsLink: "",
        },
        {
            title: "Patient Experience",
            summary: "Patient Experience",
            details: "Employees must opt-in to our services. After they have done so, we can pull their clinical data and reach out to them via email or a phone call to receive their consent to let us help them save money on their prescriptions. If a change to their prescription needs to be made, we handle the change by working directly with their provider. Savings can be in the hundreds of dollars, and all the patient has to do is consent to the saving suggestion!",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/person-typing.png",
            pitchImageAltText: "Person Typing",
            moreDetailsLink: "",
        },
        {
            title: "Employer Experience",
            summary: "Employer Experience",
            details: "Employers receive on demand reporting from our web portal and periodic support calls with their implementation and support team. We pride ourselves on our transparency, and that means giving employers access to the same detailed reporting our internal employees view. We are also able to answer questions about plan design to help employers negotiate effectively with their insurance company.",
            iconInAssets: "checkbox.webp",
            iconAltText: "Checkbox Icon",
            imageInAssets: "employer-program/person-typing.png",
            pitchImageAltText: "Person Typing",
            moreDetailsLink: "",
        }
    ],
    outcomesAndCosts: [
        {
            title: "Improve Outcomes",
            summary: "",
            details:
                "Clinical interventions in outpatient pharmacy: a new paradigm",
            iconInAssets: "",
            iconAltText: "",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Reduce Costs",
            summary: "",
            details:
                "Eliminate wasteful treatments",
            iconInAssets: "",
            iconAltText: "",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
    ],
    activeAndClinical: [
        {
            title: "Active Not Passive",
            summary: "",
            details:
                "We talk to patients and clinicians",
            iconInAssets: "",
            iconAltText: "",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Clinical Not Billing",
            summary: "",
            details:
                "We make medical decisions",
            iconInAssets: "",
            iconAltText: "",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
    ],
    cooperativeAndPharmacists: [
        {
            title: "Cooperative Not Antagonistic",
            summary: "",
            details:
                "Physicians who fight insurance partner with IntelligentRx",
            iconInAssets: "",
            iconAltText: "",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Pharmacists Not Doctors",
            summary: "",
            details:
                "We act after diagnosis and prescription",
            iconInAssets: "",
            iconAltText: "",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
    ],
    outcomesOverMoney: [
        {
            title: "Outcomes Not Money",
            summary: "",
            details:
                "Efficacy > Savings",
            iconInAssets: "",
            iconAltText: "",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
        {
            title: "Not Raising Costs",
            summary: "",
            details:
                "$ Savings > $ Cost",
            iconInAssets: "",
            iconAltText: "",
            imageInAssets: "",
            pitchImageAltText: "",
            moreDetailsLink: "",
        },
    ],
};
export default employerProgramPitches;
