import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45014d35"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col col-12 col-lg-6 d-flex flex-column justify-content-center" }
const _hoisted_4 = { class: "display-4" }
const _hoisted_5 = { class: "pitch-font" }
const _hoisted_6 = { class: "col col-6 col-lg-3" }
const _hoisted_7 = { class: "h1 font-weight-bold" }
const _hoisted_8 = { class: "col col-3 col-sm-2" }
const _hoisted_9 = { class: "h-100 w-100 d-flex flex-row align-items-center justify-content-start" }
const _hoisted_10 = { class: "col col-9 col-sm-10 mb-0 align-self-center pitch-font" }
const _hoisted_11 = { class: "col col-6 col-lg-3" }
const _hoisted_12 = { class: "h1 font-weight-bold" }
const _hoisted_13 = { class: "col col-3 col-sm-2" }
const _hoisted_14 = { class: "h-100 w-100 d-flex flex-row align-items-center justify-content-start" }
const _hoisted_15 = { class: "col col-9 col-sm-10 mb-0 align-self-center pitch-font" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.getOverallClass)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString($props.title), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString($props.description), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h3", _hoisted_7, _toDisplayString($props.prosCons?.prosHeader), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.prosCons?.prosBullets, (prosBullet) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row pb-4 pt-2",
              key: prosBullet
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa fa-check-square",
                    "aria-hidden": "true",
                    class: "pros-cons-icon"
                  })
                ])
              ]),
              _createElementVNode("p", _hoisted_10, _toDisplayString(prosBullet), 1)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("h3", _hoisted_12, _toDisplayString($props.prosCons?.consHeader), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.prosCons?.consBullets, (consBullet) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "row pb-4 pt-2",
              key: consBullet
            }, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa fa-minus",
                    "aria-hidden": "true",
                    class: "pros-cons-icon pitch-font"
                  })
                ])
              ]),
              _createElementVNode("p", _hoisted_15, _toDisplayString(consBullet), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ], 2))
}