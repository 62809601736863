import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a9cb416"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["value", "type", "placeholder", "disabled", "pattern", "name", "id"]
const _hoisted_3 = {
  key: 0,
  class: "error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: "label",
      for: $props.name
    }, _toDisplayString($props.label), 9, _hoisted_1),
    _createElementVNode("div", {
      class: _normalizeClass(["input-wrapper", $options.getStateClass])
    }, [
      _createElementVNode("input", {
        value: $props.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        type: $props.type,
        class: "form-control irx-input text-left",
        placeholder: $props.placeholder,
        disabled: $props.disabled,
        pattern: $props.pattern,
        name: $props.name,
        id: $props.name,
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => ($props.enterMethod()), ["enter"]))
      }, null, 40, _hoisted_2)
    ], 2),
    (!$options.isValidState)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}