import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1309b0c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "display-4" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "three-detailed-pitch-hr text-primary"
}
const _hoisted_5 = {
  key: 1,
  class: "three-detailed-pitch-hr"
}
const _hoisted_6 = { class: "h1" }
const _hoisted_7 = {
  key: 0,
  class: "col col-12"
}
const _hoisted_8 = { class: "pitch-font" }
const _hoisted_9 = {
  key: 1,
  class: "container"
}
const _hoisted_10 = { class: "display-4" }
const _hoisted_11 = { class: "row three-detailed-pitch-selector" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "three-detailed-pitch-hr text-primary"
}
const _hoisted_14 = {
  key: 1,
  class: "three-detailed-pitch-hr"
}
const _hoisted_15 = { class: "h1" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col col-12 col-lg-6" }
const _hoisted_18 = { class: "h3" }
const _hoisted_19 = { class: "pitch-font" }
const _hoisted_20 = { class: "col col-12 col-lg-6 d-none d-lg-block" }
const _hoisted_21 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.getOverallClass)
  }, [
    (_ctx.isMobile())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString($props.title), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pitches, (pitch, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: pitch.title,
              class: "row"
            }, [
              _createElementVNode("div", {
                class: "col col-12",
                style: {"cursor":"pointer"},
                onClick: ($event: any) => ($options.switchPitch(i))
              }, [
                ($data.selectedPitch == pitch)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
                  : (_openBlock(), _createElementBlock("hr", _hoisted_5)),
                _createElementVNode("h3", _hoisted_6, _toDisplayString(pitch.title), 1)
              ], 8, _hoisted_3),
              ($data.selectedPitch == pitch)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString($data.selectedPitch.details), 1),
                    _createVNode(_component_router_link, {
                      to: "/request-a-demo",
                      class: "btn btn-lg my-4",
                      style: {"background":"var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box","color":"black"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Request a Demo "),
                        _createVNode(_component_font_awesome_icon, {
                          icon: "fa fa-arrow-right",
                          "aria-hidden": "true"
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("h2", _hoisted_10, _toDisplayString($props.title), 1),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pitches, (pitch, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col col-4",
                key: pitch.title,
                style: {"cursor":"pointer"},
                onClick: ($event: any) => ($options.switchPitch(i))
              }, [
                ($data.selectedPitch == pitch)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_13))
                  : (_openBlock(), _createElementBlock("hr", _hoisted_14)),
                _createElementVNode("h3", _hoisted_15, _toDisplayString(pitch.title), 1)
              ], 8, _hoisted_12))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("h2", _hoisted_18, _toDisplayString($data.selectedPitch.summary), 1),
              _createElementVNode("p", _hoisted_19, _toDisplayString($data.selectedPitch.details), 1),
              _createVNode(_component_router_link, {
                to: "/request-a-demo",
                class: "btn btn-lg my-4",
                style: {"background":"var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box","color":"black"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Request a Demo "),
                  _createVNode(_component_font_awesome_icon, {
                    icon: "fa fa-arrow-right",
                    "aria-hidden": "true"
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("img", {
                src: require(`@/assets/${$data.selectedPitch.imageInAssets}`),
                class: "three-detailed-pitch-image mh-100",
                height: "400",
                alt: $data.selectedPitch.pitchImageAltText
              }, null, 8, _hoisted_21)
            ])
          ])
        ]))
  ], 2))
}