import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08816015"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "four-stats-overarching-title h1" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "h1" }
const _hoisted_5 = {
  key: 0,
  class: "three-detailed-pitch-hr w-100"
}
const _hoisted_6 = {
  key: 1,
  class: "three-detailed-pitch-hr w-100"
}
const _hoisted_7 = {
  key: 0,
  class: "col col-12"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "animate-counting-intersection-target h3" }
const _hoisted_10 = {
  key: 1,
  class: "container"
}
const _hoisted_11 = { class: "four-stats-overarching-title display-4" }
const _hoisted_12 = { class: "row pb-5" }
const _hoisted_13 = { class: "col col-12 col-lg-4" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "col col-4 col-lg-2 align-right justify-content-center d-flex" }
const _hoisted_16 = ["src", "alt"]
const _hoisted_17 = { class: "col col-8 col-lg-10 align-items-center d-flex" }
const _hoisted_18 = { class: "h1 float-left" }
const _hoisted_19 = {
  key: 0,
  class: "three-detailed-pitch-hr w-100 text-primary"
}
const _hoisted_20 = {
  key: 1,
  class: "three-detailed-pitch-hr w-100"
}
const _hoisted_21 = { class: "col col-12 col-lg-8 text-center" }
const _hoisted_22 = ["src", "alt"]
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "animate-counting-intersection-target h1" }
const _hoisted_25 = { class: "pitch-font" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.getOverallClass)
  }, [
    (_ctx.isMobile())
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString($props.title), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fourStats, (statWrapper, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: statWrapper.title,
              class: "row"
            }, [
              _createElementVNode("div", {
                class: "col col-12",
                style: {"cursor":"pointer"},
                onClick: ($event: any) => ($options.switchStat(i))
              }, [
                _createElementVNode("h3", _hoisted_4, _toDisplayString(statWrapper.title), 1),
                (statWrapper == $data.selectedStatWrapper)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_5))
                  : (_openBlock(), _createElementBlock("hr", _hoisted_6))
              ], 8, _hoisted_3),
              (statWrapper == $data.selectedStatWrapper)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedStatWrapper.stats, (stat, statIndex) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "col col-6 col-lg-3",
                          key: statIndex
                        }, [
                          _createElementVNode("h3", _hoisted_9, _toDisplayString(stat.stat), 1),
                          _createElementVNode("p", null, _toDisplayString(stat.description), 1)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("h2", _hoisted_11, _toDisplayString($props.title), 1),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.fourStats, (statWrapper, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "row stat-row",
                  onClick: ($event: any) => ($options.switchStat(index)),
                  style: {"cursor":"pointer"},
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("img", {
                      src: require(`@/assets/${statWrapper.iconInAssets}`),
                      class: _normalizeClass(["stat-wrapper-icon", $options.getIconClass]),
                      alt: statWrapper.iconAltText
                    }, null, 10, _hoisted_16)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("h3", _hoisted_18, _toDisplayString(statWrapper.title), 1)
                  ]),
                  (statWrapper == $data.selectedStatWrapper)
                    ? (_openBlock(), _createElementBlock("hr", _hoisted_19))
                    : (_openBlock(), _createElementBlock("hr", _hoisted_20))
                ], 8, _hoisted_14))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("img", {
                src: require(`@/assets/${$data.selectedStatWrapper.pitchImageInAssets}`),
                height: "400",
                alt: $data.selectedStatWrapper.pitchImageAltText,
                class: "stat-image"
              }, null, 8, _hoisted_22)
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedStatWrapper.stats, (stat, statIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col col-6 col-lg-3",
                key: statIndex
              }, [
                _createElementVNode("h3", _hoisted_24, _toDisplayString(stat.stat), 1),
                _createElementVNode("p", _hoisted_25, _toDisplayString(stat.description), 1)
              ]))
            }), 128))
          ])
        ]))
  ], 2))
}