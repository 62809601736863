<template>
    <div :class="getOverallClass">
        <div class="container">
            <div class="row pitch-image-background">
                <div v-if="imageLeft" class="col col-12 col-lg-6">
                    <img :src="require(`@/assets/${imageSrc}`)" class="img-fluid pitch-image" :alt="imgAltText" />
                </div>
                <div class="col col-12 col-lg-6">
                    <div class="
            d-flex
            flex-column
            align-items-left
            justify-content-center
            h-100
          ">
                        <h2 class="display-4 font-weight-bold">{{ title }}</h2>
                        <p class="pitch-font">{{ text }}</p>
                    </div>
                </div>
                <div v-if="!imageLeft" class="col col-12 col-lg-6">
                    <img :src="require(`@/assets/${imageSrc}`)" class="img-fluid pitch-image" :alt="imgAltText" />
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { accessibilityMixin } from '../../mixins/accessibilityMixin';
export default {
    name: "pitch-image",
    props: {
        title: String,
        text: String,
        // default is right
        imageLeft: Boolean,
        imageSrc: String,
        overallClass: String,
    },
    mixins: [accessibilityMixin],
    data() {
        return {
            imgAltText: '',
        };
    },
    computed: {
        getOverallClass() {
            if (this.overallClass != null && this.overallClass.length > 0)
                return "row " + this.overallClass;
            return "row";
        },
    },
    mounted() {
        this.imgAltText = this.getImageAltFromImageURL(this.imageSrc);
    },
};
</script>
<style scoped>
.pitch-image-background {
    padding-top: 120px;
    padding-bottom: 90px;
}

.pitch-image {
    border-radius: 20px;
}

@media (max-width: 992px) {
    .pitch-image-background {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .pitch-image {
        border-radius: 20px;
    }
}
</style>