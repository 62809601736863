import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42548fc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container search-section" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "drug-name heading-font" }
const _hoisted_5 = {
  key: 0,
  class: "drug-brand-name"
}
const _hoisted_6 = { style: {"text-transform":"capitalize"} }
const _hoisted_7 = {
  key: 1,
  class: "drug-brand-name"
}
const _hoisted_8 = { style: {"text-transform":"capitalize"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_search_drug_options = _resolveComponent("search-drug-options")!
  const _component_search_drug_result_section = _resolveComponent("search-drug-result-section")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.initialDrugName), 1),
            (_ctx.brandDrugName &&
                            _ctx.genericDrugName &&
                            _ctx.genericDrugName.toUpperCase().startsWith(_ctx.initialDrugName?.toUpperCase() ?? '')
                        )
              ? (_openBlock(), _createElementBlock("h1", _hoisted_5, [
                  _createTextVNode(" ("),
                  _createVNode(_component_router_link, { to: "/blog/brand-name-vs-generic-drugs-cost-effectiveness-quality-savings" }, {
                    default: _withCtx(() => [
                      _createTextVNode("brand name")
                    ]),
                    _: 1
                  }),
                  _createTextVNode("  "),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.brandDrugName), 1),
                  _createTextVNode(") ")
                ]))
              : (_ctx.brandDrugName &&
                            _ctx.genericDrugName
                        )
                ? (_openBlock(), _createElementBlock("h1", _hoisted_7, [
                    _createTextVNode(" ("),
                    _createVNode(_component_router_link, { to: "/blog/brand-name-vs-generic-drugs-cost-effectiveness-quality-savings" }, {
                      default: _withCtx(() => [
                        _createTextVNode("generic name")
                      ]),
                      _: 1
                    }),
                    _createTextVNode("  "),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.genericDrugName), 1),
                    _createTextVNode(") ")
                  ]))
                : _createCommentVNode("", true)
          ])
        ]),
        _createVNode(_component_search_drug_options, {
          haveLoadedFullDrugDetails: _ctx.haveLoadedFullDrugDetails,
          onLoadDrugDetails: $options.loadDrugDetailsSearchDrugPage,
          onReload: $options.reload,
          ref: "searchDrugOptions"
        }, null, 8, ["haveLoadedFullDrugDetails", "onLoadDrugDetails", "onReload"])
      ]),
      _createVNode(_component_search_drug_result_section, {
        popularDrugs: _ctx.popularDrugs,
        unpopularDrugs: _ctx.unpopularDrugs,
        alternatives: _ctx.alternatives,
        patientNoticeWarnings: _ctx.patientNoticeWarnings,
        mailOrderDrugs: _ctx.mailOrderDrugs,
        onPharmacySelected: $options.pharmacySelected,
        onShowUnpopularPharmacies: $options.showUnpopularPharmaciesMethod,
        hasLoaded: _ctx.hasLoaded,
        drugName: _ctx.selectedLabelValue,
        showSpinner: _ctx.showSpinner
      }, null, 8, ["popularDrugs", "unpopularDrugs", "alternatives", "patientNoticeWarnings", "mailOrderDrugs", "onPharmacySelected", "onShowUnpopularPharmacies", "hasLoaded", "drugName", "showSpinner"])
    ])
  ]))
}