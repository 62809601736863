<template>
    <div class="site-styles">
        <header-section v-if="showHeader" />
        <div class="container-fluid p-0 overflow-hidden" style="margin-top:72px;">
            <router-view />
        </div>
        <footer-section v-if="showFooter" />
        <a href="#" v-if="scrollToTop" class="btn btn-light btn-lg back-to-top btn-outline-dark" role="button"><img
                src="./assets/scroll-up.svg" alt="scroll up" /></a>
    </div>
</template>

<script>
import FooterSection from "./components/layout/footer-section";
import HeaderSection from "./components/layout/header-section";
import { mixinDetectingMobile } from "./mixins/mixinDetectingMobile";

export default {
    name: "app",
    components: {
        FooterSection,
        HeaderSection,
    },
    mixins: [mixinDetectingMobile],
    data() {
        return {
            showHeader: true,
            showFooter: true,
            scrollToTop: false,
        };
    },
    methods: {
        onScroll() {
            if (this.$route.path === "/p") {
                this.showHeader = false;
                this.showFooter = false;
                return;
            }
            this.showFooter = true;

            if (this.$route.path === "/" || this.$route.path === "/how-it-works") {
                this.showHeader = false;
            }
            // based on the approximate height where you can no longer see the search bar nor recent searches
            if (
                document.body.scrollTop > 600 ||
                document.documentElement.scrollTop > 600
            ) {
                this.showHeader = true;
            }

            // https://app.asana.com/0/0/1201715804292253/1201721726205402/f
            if (
                document.body.scrollTop > 10 ||
                document.documentElement.scrollTop > 10
            ) {
                this.scrollToTop = true;
            } else {
                this.scrollToTop = false;
            }
        },
        maybeDoAnalytics() {
            // The only safe way to do this is to have an allow list of pages where we send analytics
            let allowedPaths = ['', '/', '/for-employers', '/for-members', '/for-benefit-consultants', '/for-health-plans', '/blog', '/faq', '/how-it-works', '/request-a-demo', '/about', '/careers', '/privacy-policy', '/terms-of-use', '/consumer-discount-cards', '/pharmacist-information', '/provider-information', '/contact', '/goodrx-comparison', '/cvs-savings-finder-comparison', '/optum-perks-comparison', '/mobile-app', '/do-not-overcharge-for-prescriptions'];
            let allowedStartsWith = ['/blog/'];
            let sw = false;
            for (let i = 0; i < allowedStartsWith.length;i++) {
                if (location.pathname.startsWith(allowedStartsWith[i])) {
                    sw = true;
                    break;
                }
            }
            let safeReferrer = document?.referrer ?? null;
            if (safeReferrer != null && safeReferrer.includes('intelligentrx.com') || safeReferrer.includes('localhost')) {
                safeReferrer = 'https://intelligentrx.com';
            }
            if (allowedPaths.includes(location.pathname) || sw) {
                console.debug('analytics_allowed');
                gtag('event', 'page_view', {
                    page_title: document?.title,
                    page_location: window?.location,
                    page_referrer: safeReferrer,
                });
            } else {
                console.debug('obfuscated_analytics')
                let pageTitle = document?.title;
                if (pageTitle == 'IntelligentRx | Prescription Discount Coupon Search' || pageTitle.endsWith(' Prescription Discount Coupon Prices | IntelligentRx')) {
                    gtag('event', 'page_view', {
                        path_title: 'Prescription Discount Coupon Prices | IntelligentRx',
                        page_location: location.origin + '/prescription-discount-coupon-search',
                        page_referrer: safeReferrer,
                    });
                } else if (pageTitle == 'Prescription Discount Coupon | IntelligentRx') {
                    gtag('event', 'page_view', {
                        path_title: pageTitle,
                        page_location: location.origin + '/coupon',
                        page_referrer: safeReferrer,
                    });
                }
            }
        }
    },
    created() {
        window.addEventListener("scroll", this.onScroll);
        this.onScroll();
    },
    unmounted() {
        window.removeEventListener("scroll", this.onScroll);
    },
    setup() {

    },
    watch: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        $route(to, from) {
            this.showHeader = true;
            this.onScroll();

            this.maybeDoAnalytics();
        }
    },
};
</script>

<style>
/* Discontinued in favor of google-fonts-webpack-plugin */
/* See comment in vue.config.js for more information */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap"); */
/* @import url("https://fonts.googleapis.com/css?family=DM+Serif+Display&display=swap"); */

.site-styles {
    background-color: #fff;
}

.main-font {
    font-weight: var(--heading-font-weight);
    font-size: 20px;
    color: rgb(14, 144, 230);
}

.large-font {
    font-size: 30px;
    color: rgb(14, 144, 230);
    font-weight: var(--heading-font-weight);
}

.small-font {
    color: rgb(14, 144, 230);
    font-size: 12px;
    font-weight: none;
}

.secondary-font {
    font-size: 14px;
    color: rgb(14, 144, 230);
    text-align: center;
}

.list-item {
    display: list-item;
    border: none;
    padding: 2px 0;
    margin: 10px 0 10px 15px;
}

.irx-input {
    font: var(--body-font-weight) 16px/19px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525 !important;
}

.irx-input-fixed-height {
    font: var(--body-font-weight) 16px/19px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525 !important;
    /* must be fixed to support .autocomplete-overlay in typeahead.vue */
    height: 22px;
}

.font-inform {
    text-align: center;
    font: var(--body-font-weight) 16px/19px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 0.51;
}

 /* I tried gray-900 but it had insufficient contrast */
.font-inform::placeholder {
    font-weight: bold;
    color: black;
    opacity: 1;
}

.font-inform-dark {
    text-align: center;
    font: var(--body-font-weight) 16px/19px var(--body-font-family);
    letter-spacing: 0px;
    color: white;
    background-color: black;
    opacity: 1;
}

.font-inform-dark::placeholder {
    color: white;
}

.irx-btn-primary {
    background: var(--primary-color) !important;
    /* Bold 19px/23px only works for prc-button and breaks with btn-lg */
    font-family: var(--body-font-family) !important;
    letter-spacing: 0px;
    color: #f5f5f5 !important;
    opacity: 1;
}
.irx-btn-primary:hover {
    background: var(--background-color-light-emphasis) 0% 0% no-repeat padding-box !important;
    box-shadow: 1px 1px 1px #00000029 !important;
    opacity: 1;
}
.irx-btn-primary:disabled {
    background: #a2b3ba 0% 0% no-repeat padding-box !important;
    opacity: 1;
}
.pitch-font {
    font-size: 20px;
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
        url('~@/assets/fonts/open-sans-v29-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('~@/assets/fonts/open-sans-v29-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('~@/assets/fonts/open-sans-v29-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('~@/assets/fonts/open-sans-v29-latin-regular.svg#OpenSans') format('svg');
    /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
        url('~@/assets/fonts/open-sans-v29-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('~@/assets/fonts/open-sans-v29-latin-600.woff') format('woff'),
        /* Modern Browsers */
        url('~@/assets/fonts/open-sans-v29-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('~@/assets/fonts/open-sans-v29-latin-600.svg#OpenSans') format('svg');
    /* Legacy iOS */
}

:root {
    --heading-font-family: 'Open Sans', sans-serif;
    --heading-font-weight: 600;
    --body-font-family: 'Open Sans', sans-serif;
    --body-font-weight: 400;
    --text-color-on-dark-background: #ffffff;
    --primary-color: #2349a9;
    --background-color-light: #ffffff;
    --background-color-off-light: rgba(43, 89, 207, 0.08);
    --background-color-grey: rgba(100, 100, 100, 0.05);
    --background-color-light-emphasis: #b3c9ff;
    --background-color-gray: #F8F8F8;
    --background-color-dark: var(--primary-color);
    --background-color-off-dark: #000000;
    --background-color-dark-emphasis: #1a377f;
    --background-color-attention-emphasis: #eba447;
    --background-color-attention-emphasis-text-color: black;

    /* Overwrite Bootstrap colors */
    --primary: var(--primary-color) !important;
}

/* always use this with additional css classes: "text-light row" */
.background-slanted-primary {
    background-color: var(--background-color-dark);
    clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 100%);
    padding-top: 100px;
    margin-top: 100px;
}

.background-slanted-primary-negative-margin {
    margin-top: -250px;
}

.light-link {
    text-decoration: underline;
}

/* Overwrite links since on white backgrounds they lack sufficient color contrast */
/* I limited usage to h1 to cover the search-page and p to cover blog posts. Other places like buttons ought not change. */
h1 > a {
    color: unset !important;
    text-decoration-line: underline !important;
}
h1 > a:hover {
    text-decoration-line: unset !important;
}
p > a {
    color: unset !important;
    text-decoration-line: underline !important;
}
p > a:hover {
    text-decoration-line: unset !important;
}

@media (max-width: 992px) {
    .background-slanted-primary {
        margin-top: 50px;
        padding-top: 50px;
        clip-path: polygon(0 0, 100% 0.5%, 100% 100%, 0 100%);
    }

    .background-slanted-primary-negative-margin {
        margin-top: -120px;
    }
    
    .pitch-font {
        font-size: 18px;
    }
}

.background-slanted-primary a {
    color: var(--background-color-gray);
    text-decoration: underline;
}

.background-slanted-primary a:hover {
    color: var(--background-color-gray);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
}

.page-title {
    margin: 10px 0px;
    text-align: center;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    font-size: 42px;
    line-height: 68px;
    letter-spacing: 0px;
}

.section-title {
    text-align: center;
    font: Bold 28px/33px var(--heading-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.subsection-title {
    text-align: center;
    font: Bold 21px/25px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.subsection-content {
    text-align: center;
    font: var(--body-font-weight) 18px/26px var(--body-font-family);
    letter-spacing: 0.45px;
    color: #292525;
    opacity: 1;
}

.font-label {
    text-align: center;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.font-blue-large {
    text-align: center;
    font: Bold 48px/57px var(--body-font-family);
    letter-spacing: 0px;
    color: #52bce8;
    opacity: 1;
}

.font-sale-price {
    text-align: center;
    font: Bold 28px/34px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.font-description-title {
    text-align: left;
    font: Bold 21px/25px var(--heading-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.font-description {
    text-align: left;
    font: var(--body-font-weight) 16px/24px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525cc;
    opacity: 1;
}

.font-medium {
    text-align: center;
    font: var(--body-font-weight) 21px/28px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
}

.font-label {
    font: var(--body-font-weight) 14px/17px var(--body-font-family);
    letter-spacing: 0px;
    color: #404040;
    opacity: 1;
}

.mt-10 {
    margin-top: 10px;
}

.mt-22 {
    margin-top: 22px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-3p {
    margin-top: 3px;
}

.mt-38 {
    margin-top: 38px;
}

.mt-160 {
    margin-top: 160px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-14 {
    margin-top: 14px;
}

.mt-24 {
    margin-top: 24px;
}

.img-wrapper img {
    width: 100%;
    height: 100%;
}

.back-to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
}

/* custom-btn-xl is designed to take up about 1/4 to 1/5 of the screen maxing out at 170px width */
.custom-btn-xl {
    width: 170px;
    height: 55px;
}

@media (max-width: 992px) {
    .custom-btn-xl {
        width: 140px;
        height: 60px;
    }
}

@media (min-width: 1440px) {
    .container {
        max-width: 80% !important;
    }
}
</style>