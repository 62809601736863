<template>
    <div :class="getOverallClass">
        <div class="col col-12 pt-4">
            <h2 class="text-center font-weight-bold pitch-overarching-header display-4">
                {{ title }}
            </h2>
        </div>
        <div class="col col-12 col-lg-4 text-center" v-for="pitch in pitches" :key="pitch.title">
            <div class="row">
                <!-- <div class="col col-2 d-block d-lg-none">
                    <img :src="require(`@/assets/${pitch.iconInAssets}`)" class="pitch-image" :class="getIconClass"
                        :alt="pitch.iconAltText" />
                </div> -->
                <div class="col col-12 offset-lg-1 col-lg-10 pb-2 pitch-content-container">
                    <!-- <div class="d-none d-lg-block">
                        <img :src="require(`@/assets/${pitch.iconInAssets}`)" class="pitch-image" :class="getIconClass"
                            :alt="pitch.iconAltText" />
                    </div> -->
                    <h3 class="h1">{{ pitch.title }}</h3>
                    <hr class="bolder-hr d-none d-lg-block" />
                    <h4 class="h3">{{ pitch.summary }}</h4>
                    <p class="pitch-font">{{ pitch.details }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { PropType } from 'vue';

export default {
    name: "three-column-pitch",
    props: {
        title: String,
        pitches: Array as PropType<any[]>,
        // pitches: [
        //   {
        //     title: String,
        //     summary: String,
        //     details: String,
        //     iconInAssets: String,
        //     pitchImageInAssets: String,
        //   },
        // ],
        overallClass: String,
        iconClass: String,
    },
    computed: {
        getOverallClass() {
            if (this.overallClass != null && this.overallClass.length > 0)
                return "row " + this.overallClass;
            return "row pitch-row";
        },
        getIconClass() {
            if (this.iconClass != null && this.iconClass != '') {
                return this.iconClass;
            }
            return '';
        }
    },
};
</script>
<style scoped>
.pitch-image {
    width: 60px;
    height: 60px;
}

.pitch-row {
    padding-top: 120px;
    padding-bottom: 80px;
}

.pitch-overarching-header {
    padding-bottom: 60px;
}

.pitch-content-container {
    text-align: center;
}

.bolder-hr {
    border-top: 2px solid grey;
    width: 50px;
}

@media (max-width: 992px) {
    .pitch-image {
        width: 24px;
        height: 24px;
    }

    .pitch-row {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pitch-overarching-header {
        padding-bottom: 40px;
    }

    .pitch-content-container {
        text-align: left;
    }
}
</style>
