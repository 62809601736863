import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10df3379"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "col col-12 col-md-6 my-md-4 py-3"
}
const _hoisted_2 = { class: "pharmacy-name mb-0" }
const _hoisted_3 = {
  key: 1,
  class: "col col-12 col-md-6"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 2,
  class: "col col-12 py-3"
}
const _hoisted_6 = { class: "pharmacy-name mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.searchDrugResultItemHighlighted)
  }, [
    ($props.buttonText != null && $props.buttonText.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString($props.leftText), 1)
        ]))
      : _createCommentVNode("", true),
    ($props.buttonText != null && $props.buttonText.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("a", {
            class: "btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg",
            href: $props.buttonHref
          }, _toDisplayString($props.buttonText), 9, _hoisted_4)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("h2", _hoisted_6, _toDisplayString($props.leftText), 1)
        ]))
  ], 2))
}