<template>
  <div class="logo-carousel-background d-flex justify-content-center flex-wrap">
    <img
      src="../../assets/logos/transparent-cvs.webp"
      class="max-height-adjusted"
      alt="CVS logo"
      height="300px"
    />
    <img
      src="../../assets/logos/transparent-walgreens.webp"
      class="max-height-adjusted"
      alt="Walgreens logo"
      height="300px"
    />
    <img
      src="../../assets/logos/transparent-kroger.webp"
      class="max-height-adjusted"
      alt="Kroger logo"
      height="300px"
    />
    <img
      src="../../assets/logos/transparent-walmart.webp"
      class="max-height-adjusted"
      alt="Walmart logo"
      height="300px"
    />
    <img
      src="../../assets/logos/transparent-costco.webp"
      class="max-height-adjusted"
      alt="Costco logo"
      height="300px"
    />
    <img
      src="../../assets/logos/transparent-safeway.webp"
      class="max-height-adjusted"
      alt="Safeway logo"
      height="300px"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: "logo-carousel",
};
</script>

<style scoped>
.logo-carousel-background {
  background-color: #ffffff;
}
.max-height-adjusted {
  max-height: 200px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (max-width: 1400px) {
  .max-height-adjusted {
    max-height: 150px;
    padding-right: 5px;
    padding-left: 5px;
  }
}
@media (max-width: 992px) {
  .max-height-adjusted {
    max-height: 100px;
    padding-right: 5px;
    padding-left: 5px;
  }
}
.carousel-background {
  background-color: rgb(89, 197, 243);
  color: rgb(89, 197, 243);
  width: 200%;
  position: relative;
  left: -50%;
}
@keyframes slidy-huge {
  from {
    left: 5000px;
  }
  to {
    left: -19500px;
  }
}
@keyframes slidy-bigger {
  from {
    left: 2500px;
  }
  to {
    /* left: -4000px; */
    left: -18000px;
  }
}
@keyframes slidy-big {
  from {
    left: 2500px;
  }
  to {
    /* left: -4000px; */
    left: -14000px;
  }
}
@keyframes slidy-medium {
  from {
    left: 1400px;
  }
  to {
    /* left: -3000px; */
    left: -6000px;
  }
}
@keyframes slidy-small {
  from {
    left: 1000px;
  }
  to {
    left: -2700px;
  }
}
.slider-container {
  width: 500%;
  margin: 0;
  left: 0;
  position: relative;
  font-size: 0;
  animation-name: slidy-huge;
  animation-duration: 75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards; /* prevent resetting when scrolling */
}
@media (max-width: 2000px) {
  .slider-container {
    width: 500%;
    margin: 0;
    left: 0;
    position: relative;
    font-size: 0;
    animation-name: slidy-bigger;
    animation-duration: 75s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards; /* prevent resetting when scrolling */
  }
}
@media (max-width: 1450px) {
  .slider-container {
    width: 500%;
    margin: 0;
    left: 0;
    position: relative;
    font-size: 0;
    animation-name: slidy-big;
    animation-duration: 50s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards; /* prevent resetting when scrolling */
  }
}
@media (max-width: 992px) {
  .slider-container {
    width: 500%;
    margin: 0;
    left: 0;
    position: relative;
    font-size: 0;
    animation-name: slidy-medium;
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards; /* prevent resetting when scrolling */
  }
}
@media (max-width: 425px) {
  .slider-container {
    width: 500%;
    margin: 0;
    left: 0;
    position: relative;
    font-size: 0;
    animation-name: slidy-small;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards; /* prevent resetting when scrolling */
  }
}
.images-side-by-side {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.secondary-images {
  display: block;
}
@media (max-width: 425px) {
  .secondary-images {
    display: none;
  }
}
</style>
