import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { VueKeycloakInstance } from "./dsb-norge-keycloak-types";
import router from './router';
import App from './App.vue';

// Importing only the bootstrap plugins we need
import 'bootstrap/js/dist/util';

import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/badge'; // does not exist
// import 'bootstrap/js/dist/breadcrumb'; // does not exist
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/button-group'; // does not exist
// import 'bootstrap/js/dist/card'; // does not exist
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/form'; // does not exist
// import 'bootstrap/js/dist/input-group'; // does not exist
// import 'bootstrap/js/dist/jumbotron'; // does not exist
// import 'bootstrap/js/dist/list-group'; // does not exist
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/navs'; // does not exist
// import 'bootstrap/js/dist/navbar'; // does not exist
// import 'bootstrap/js/dist/pagination';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/progress'; // does not exist
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'bootstrap/dist/css/bootstrap.min.css';

import { setupCalendar } from 'v-calendar';

import VueKeyCloak from './dsb-norge-keycloak-index'

// Font awesome icons - try to import only necessary icons here
import { library } from '@fortawesome/fontawesome-svg-core'
// import { config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowLeft, faArrowRight, faComment, faEnvelope, faDownload, faCaretDown, faCaretUp, faSearch, faDollarSign, faCircleInfo, faHospitalUser, faUserPlus, faUserDoctor, faRectangleAd, faTags, faBuilding, faLockOpen, faLock, faPencil, faQuoteLeft, faCheckSquare, faMinus, faPaperPlane, faCopy, faShareNodes } from '@fortawesome/free-solid-svg-icons'
import { faSquareFacebook, faSquareInstagram, faSquareTwitter } from '@fortawesome/free-brands-svg-icons'
// Used for employer work
// import { faQuestion } from '@fortawesome/free-brands-svg-icons'

// Config is used because we need to deal with Content Security Policy CSP
// https://github.com/FortAwesome/vue-fontawesome/issues/67
// config.autoAddCss = false;
// I had to remove this because it isn't working at all

library.add(faArrowLeft, faArrowRight, faComment, faEnvelope, faDownload, faCaretDown, faCaretUp, faSearch, faDollarSign, faCircleInfo, faHospitalUser, faUserPlus, faUserDoctor, faRectangleAd, faTags, faBuilding, faLockOpen, faLock, faPencil, faQuoteLeft, faCheckSquare, faMinus, faSquareFacebook, faSquareInstagram, faSquareTwitter, faPaperPlane, faCopy, faShareNodes);

// Previously, the website loaded as follows:
// 1. Loads dev.intelligentrx.com HTML
// 2. Preconnects to font APIs while loading JS and app CSS and app JS and app images, etc.
// 3. Loaded CSS
// 4. Recalculated style
// 5. Layout
// 6. FCP
// After this change, the fonts should be packed locally.
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";

let keycloakUrl = 'https://keycloak.dev.intelligentrx.com';
if (location.hostname === 'localhost') {
    keycloakUrl = 'https://localhost:8443';
} else if (location.hostname === 'dev.intelligentrx.com') {
    keycloakUrl = 'https://keycloak.dev.intelligentrx.com';
} else if (location.hostname === 'staging.intelligentrx.com') {
    keycloakUrl = 'https://keycloak.staging.intelligentrx.com';
} else if (location.hostname === 'intelligentrx.com') {
    keycloakUrl = 'https://keycloak.prod.intelligentrx.com';
}
const pinia = createPinia();

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
if (isSafari) {
    console.warn("Safari detected. Authentication is not supported on Safari");
} else {
    app.use(VueKeyCloak, {
        init: {
            // onLoad: 'check-sso', // login is NOT always required
            // checkLoginIframe: false,
            // silentCheckSsoFallback: false,
            // flow: 'standard',
            // enableLogging: true,
            onLoad: 'login-required'
            // Previously it was possible to silently check for a login, but after Chrome disabled
            // third party cookies this no longer works on Localhost. As a result, we now need to do login-required to sign in.
            // silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`
        },
        config: {
            realm: 'intelligentrxcom',
            url: keycloakUrl,
            clientId: 'admin-website'
        },
        // onInitSuccess(authenticated) {
        //     console.log('onInitSuccess');
        //     console.log(authenticated);
        // },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onReady: (keycloak) => {
            // console.log(JSON.stringify(keycloak));
            /* eslint-disable no-new */
            // console.log('onready');
            // console.log(keycloak);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onInitError: (error, _) => {
            // Note: This is not triggered if the certificate is not valid for Keycloak
            console.log('keycloak onInitError');
            console.error(error);
        }
    });
}

app.use(router)
app.use(setupCalendar, {});
app.use(pinia);
app.mount('#app');


// Allow usage of this.$keycloak in components
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $keycloak: VueKeycloakInstance
    }
}

// // document.addEventListener('DOMContentLoaded', function () {
//     if (document.getElementById('app') != null) {
//         root.$mount('#app');
//     } else {
//         // <div id="app"> was removed due to prerendering
//         root.$mount('body > div');
//     }
// // });
