import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a437a14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row dropdowns-row" }
const _hoisted_2 = { class: "col-md-3 col-sm-12" }
const _hoisted_3 = { class: "col-md-3 col-sm-12" }
const _hoisted_4 = { class: "col-md-3 col-sm-12" }
const _hoisted_5 = { class: "col-md-3 col-sm-12" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "col col-md-6 col-lg-3 col-12"
}
const _hoisted_9 = {
  key: 1,
  class: "col col-md-6 col-lg-3 col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_labeled_dropdown = _resolveComponent("labeled-dropdown")!
  const _component_prc_input = _resolveComponent("prc-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_labeled_dropdown, {
        modelValue: _ctx.selectedLabelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedLabelValue) = $event)),
        name: "labels",
        options: _ctx.store.getLabels,
        "option-value-key": "drugName",
        "option-label-selector": "drugName",
        "no-default-option": "",
        "field-class": "dropdown-light irx-input",
        "label-text": "Label",
        "label-class": "font-label text-left",
        onInput: _ctx.labelChanged,
        disabled: $options.shouldDisableLabels
      }, null, 8, ["modelValue", "options", "onInput", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_labeled_dropdown, {
        modelValue: _ctx.selectedFormValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFormValue) = $event)),
        name: "forms",
        options: _ctx.store.getForms,
        "option-value-key": "form",
        "option-label-selector": "form",
        "no-default-option": "",
        "field-class": "dropdown-light irx-input",
        "label-text": "Form",
        "label-class": "font-label text-left",
        disabled: $options.shouldDisableForms,
        onInput: _ctx.formChanged
      }, null, 8, ["modelValue", "options", "disabled", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_labeled_dropdown, {
        modelValue: _ctx.selectedDosageValue,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedDosageValue) = $event)),
        name: "dosages",
        options: _ctx.store.getDosages,
        "option-value-key": "strength",
        "option-label-selector": "strength",
        "no-default-option": "",
        "field-class": "dropdown-light irx-input",
        "label-text": "Dosage",
        "label-class": "font-label text-left",
        disabled: $options.shouldDisableDosages,
        onInput: _ctx.dosageChanged
      }, null, 8, ["modelValue", "options", "disabled", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (this.isPackaged)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_labeled_dropdown, {
              modelValue: _ctx.selectedQuantityValue,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedQuantityValue) = $event)),
              name: "quantities",
              options: _ctx.store.getQuantities,
              "option-value-key": "quantity",
              "option-label-selector": "quantityToDisplay",
              "no-default-option": "",
              "field-class": "dropdown-light irx-input",
              "label-text": "Quantity",
              "label-class": "font-label text-left",
              disabled: $options.shouldDisableQuantities,
              onInput: _ctx.quantityChanged
            }, null, 8, ["modelValue", "options", "disabled", "onInput"])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_labeled_dropdown, {
              modelValue: _ctx.selectedQuantityValue,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedQuantityValue) = $event)),
              name: "quantities",
              options: _ctx.store.getQuantities,
              "option-value-key": "quantity",
              "option-label-selector": "quantityToDisplay",
              "no-default-option": "",
              "field-class": "dropdown-light irx-input",
              "label-text": "Quantity",
              "label-class": "font-label text-left",
              disabled: $options.shouldDisableQuantities,
              onInput: _ctx.quantityChanged
            }, null, 8, ["modelValue", "options", "disabled", "onInput"])
          ]))
    ]),
    (_ctx.selectedQuantityValue == 'Custom' && this.isPackaged)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_prc_input, {
            modelValue: _ctx.customQuantityPackaged,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customQuantityPackaged) = $event)),
            step: "any",
            type: "number",
            placeholder: "Quantity",
            disabled: _ctx.selectedQuantityValue != 'Custom',
            errorMessage: "You must enter a quantity",
            state: _ctx.customQuantityPackaged != null,
            name: "customQuantityPackaged",
            label: "Custom Quantity",
            enterMethod: _ctx.quantityChanged,
            onInput: _ctx.quantityChanged
          }, null, 8, ["modelValue", "disabled", "state", "enterMethod", "onInput"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedQuantityValue == 'Custom' && !this.isPackaged)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_prc_input, {
            modelValue: _ctx.customQuantity,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.customQuantity) = $event)),
            step: "any",
            type: "number",
            placeholder: "Quantity",
            disabled: _ctx.selectedQuantityValue != 'Custom',
            errorMessage: "You must enter a quantity",
            state: _ctx.customQuantity != null,
            name: "customQuantity",
            label: "Custom Quantity",
            enterMethod: _ctx.quantityChanged,
            onInput: _ctx.quantityChanged
          }, null, 8, ["modelValue", "disabled", "state", "enterMethod", "onInput"])
        ]))
      : _createCommentVNode("", true)
  ]))
}