// TODO I want to gather more cookies and report their values to the server via the errormixin or something like it.

export const cookiemixin = {
    methods: {
        // readCookie returns the string value at this cookie name. If there is no value this function returns null.
        readCookie: function (cookieName) {
            if (cookieName == null) {
                return null;
            }
            const cookies = document.cookie;
            if (cookies == "") {
                return null;
            }
            const splitCookies = cookies.split("; ");
            if (splitCookies != null) {
                const foundCookies = splitCookies.find((row) => row.startsWith(cookieName + "="));
                if (foundCookies != null) {
                    return foundCookies.split("=")[1];
                }
            }
            return null;
        },
        // getCookie is an alias for readCookie.
        getCookie: function (cookieName) {
            return this.readCookie(cookieName);
        },
        // setCookie sets a cookie's value at this cookie name. It is a SET, NOT an UPDATE.
        setCookie: function (cookieName, cookieValue) {
            if (cookieName == null) {
                return null;
            }
            if (cookieValue == null || cookieValue.length < 1) {
                return;
            }
            const d = new Date();
            d.setTime(d.getTime() + 14 * 24 * 60 * 60 * 1000);
            const expires = "expires=" + d.toUTCString();
            document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
        },
    }
}
