<template>
    <div class="container-fluid">
        <banner-pitch :image="pitches.banner.imageSrc" pageDescription="Savings For Employers">
            <template v-slot:bannerHTMLLarge>
                <h2 class="display-4">Watch Your Pharmacy Spending Go Down</h2>
                <br />
                <p class="pitch-font">IntelligentRx + Your Insurance Company = Up To 30% Savings</p>
                <router-link to="/request-a-demo" class="btn btn-lg" style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">
                    Request a Demo
                    <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                </router-link>
            </template>
            <template v-slot:bannerHTMLMobile>
                <h2 class="d-inline-block">Watch Your Pharmacy Spending Go Down</h2>
                <br />
                <p class="pitch-font">
                    IntelligentRx's Pharmacy Team + Your Existing Insurance Company = Up
                    To 30% Savings
                </p>
                <router-link to="/request-a-demo" class="btn btn-lg my-4"
                    style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">Request a Demo
                    <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                </router-link>
            </template>
        </banner-pitch>

        <highlight-overlay :title="pitches.highlightOverlayThomas.title" :text="pitches.highlightOverlayThomas.text"
            :imageSrc="pitches.highlightOverlayThomas.imageSrc">
        </highlight-overlay>

        <four-stats-pitch title="How We Save Money" :fourStats="pitches.commonProblemSolutionStats"
            :overallClass="'pitch-background-default'">
        </four-stats-pitch>

        <pros-cons-pitch title="Is IntelligentRx Right For You?" description="" :prosCons="pitches.commonProsCons"
            :overallClass="'pitch-background-dark'">
        </pros-cons-pitch>

        <three-detailed-pitch title="Managing Complexity" :pitches="pitches.managingComplexity"
            :overallClass="'pitch-background-gradient'"></three-detailed-pitch>

        <three-column-pitch title="Our Offerings" :pitches="pitches.employerOurValuesInOurProduct"
            :overallClass="'pitch-background-default'"></three-column-pitch>


        <three-column-pitch title="Employer's Return On Investment" :pitches="pitches.employerROI"
            :overallClass="'pitch-background-dark'" :iconClass="'pitch-background-dark-invert-icon-class'">
        </three-column-pitch>

        <three-detailed-pitch title="How IntelligentRx For Employers Works" :pitches="pitches.howItWorks" :overallClass="'pitch-background-gradient'">
        </three-detailed-pitch>

        <pitch-image :title="pitches.discountCards.title" :text="pitches.discountCards.text" :imageLeft="true"
            :imageSrc="pitches.discountCards.imageSrc">
        </pitch-image>

        <three-column-pitch title="Integrating With Your Existing Plan" :pitches="pitches.integratingWithExistingPlan"
            :overallClass="'pitch-background-dark'" :iconClass="'pitch-background-dark-invert-icon-class'">
        </three-column-pitch>

        <pitch-image :title="pitches.securityPitch.title" :text="pitches.securityPitch.text" :imageLeft="false"
            :imageSrc="pitches.securityPitch.imageSrc" :overallClass="'pitch-background-gradient'">
        </pitch-image>
    </div>
</template>

<script lang="ts">
import FourStatsPitch from "../controls/four-stats-pitch.vue";
import HighlightOverlay from "../controls/highlight-overlay.vue";
import ThreeColumnPitch from "../controls/three-column-pitch.vue";
import ThreeDetailedPitch from "../controls/three-detailed-pitch.vue";
// import ThreeRowPitch from "../controls/three-row-pitch.vue";
// import FourCardStatsPitch from "../controls/four-card-stats-pitch.vue";
import PitchImage from "../controls/pitch-image.vue";
// import QuotePitch from "../controls/quote-pitch.vue";
import ProsConsPitch from "../controls/pros-cons-pitch.vue";
import BannerPitch from "../banner-pitch.vue";
import employerProgramPitches from './employer-program-pitches';
export default {
    name: "cost-savings-for-employers",
    components: {
        HighlightOverlay,
        ThreeColumnPitch,
        ThreeDetailedPitch,
        FourStatsPitch,
        // ThreeRowPitch,
        // FourCardStatsPitch,
        PitchImage,
        // QuotePitch,
        ProsConsPitch,
        BannerPitch,
    },
    // Types of pitches
    // (3) title, summary, details -> three-row-pitch, three-column-pitch, three-detailed-pitch
    // (3 or 4) stats % w/ description; times (3 or 4) stat groupings -> four-stats-pitch, four-card-stats-pitch
    // (1) quote with an author -> quote-pitch
    // (1) title, details -> pitch-image
    // (3) pros, (3) cons, title, description -> pros-cons-pitch
    //
    // Page strategies:
    // Each page should have a "landing" which is targeting the #1 selling point of the page
    // Each page should have a "highlight" which lends credibility to our ability to accomplish our #1 selling point
    // Each page needs ~7 other sections, including probably 2 (title, summary, details), 1 (title, details) pitch-image, 1 stats (common; shared), 1 pros-cons (common; shared), 1 mission statement (common; title, details), 1 thomas's original story (common; title, details)
    //
    // Common Stats: "The Problem" - overspending for no clinical gain compared to other drugs; "Why It's Unfixed"; "IntelligentRx's Solution"
    // "How Overspending Happens": X% of spending is on specialty drugs; Y% of people on specialty drugs could take a different drug; Z% amount of drug spending which could be saved by switching drugs; ?% - Number of prescribers who know the prescription's cost
    //
    // "Why It's Unfixed": Most PBMs managing prescription benefits profit when patients spend more on prescriptions
    // https://www.commonwealthfund.org/publications/explainer/2019/apr/pharmacy-benefit-managers-and-their-role-drug-spending
    // https://ncpa.org/spread-pricing-101 $224,800,000 Ohio's 1-year Medicaid Losses due to Spread Pricing
    // $123,500,000 Kentucky's 1-year Losses due to Spread Pricing
    // https://www.pbgh.org/6-things-employers-should-know-about-pbms/ 90% of prescription claims are through the "Big 3" PBMs which engage in these practices
    //
    // "IntelligentRx's Solution"
    // 30% savings target across all prescriptions by optimizing drug spend for YOU
    // 100% of realized savings are directed to patients and the employer
    // $7,500 / month - estimated cost for employer with 1,000 employees with automated intervention only
    // $0 / month - IntelligentRx charges nothing until the employer's monthly savings exceed the program's monthly cost
    //
    // Common Pros/Cons
    // Wondering if IntelligentRx is right for your company?
    // + Both employers and employees save money
    // + Service is FREE until savings exceed costs
    // + Transparent Savings Reporting: Daily and monthly savings reports
    // + "Hands Off" for employers after implementation
    // - Employer must encourage employees to sign up
    // - Small Employers typically must facilitate PBM <-> IntelligentRx relationship
    //
    // Common Mission Statement
    // Our Mission
    // Founded in 2018,
    //
    // Common Story
    //
    data() {
        return {
            // --- New Sections ---
            // "Our Track Record"
            // "helped 350,000 patients", "save over 20 million dollars", "since 2019"
            pitches: employerProgramPitches,
        };
    },
};
</script>
<style scoped>
.pitch-background-dark {
    /* background-color: hsl(206, 24%, 66%); */
    padding-top: 120px;
    padding-bottom: 90px;
    background-color: var(--background-color-dark);
    color: white;
}

.pitch-background-dark-invert-icon-class {
    filter: invert(1);
}

.pitch-background-default {
    padding-top: 120px;
    padding-bottom: 90px;
}

.pitch-background-gradient {
    /* background-color: hsl(206, 24%, 66%); */
    background: linear-gradient(var(--background-color-light-emphasis), #ffffff);
    padding-top: 120px;
    padding-bottom: 90px;
}

@media (max-width: 992px) {
    .pitch-background-dark {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .pitch-background-default {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .pitch-background-gradient {
        padding-top: 40px;
        padding-bottom: 30px;
    }
}
</style>