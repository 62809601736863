<template>
    <div class="labeled-dropdown-wrapper">
        <label class="label-wrapper" :class="labelClass" :for="selectId">{{ labelText }}</label>
        <div class="dropdown-wrapper">
            <select v-if="optionValueKey" class="select-main text-truncate custom-select" :id="selectId"
                :value="modelValue"
                :title="modelValue == null ? '' : buildOptionLabel(modelValue)" :name="name" :class="fieldClass"
                :disabled="disableDropDown" @input="change">
                <option :value="null" v-if="!noDefaultOption">
                    {{ optionDefaultLabel }}
                </option>
                <option class="text-truncate" v-for="(option, index) of options" :key="index"
                    :value="option[optionValueKey]" :disabled="isOptionDisabled()">
                    {{ buildOptionLabel(option) }}
                </option>
            </select>

            <select v-else class="select-main text-truncate custom-select" :id="selectId"
                :value="modelValue"
                :title="modelValue == null ? '' : buildOptionLabel(modelValue)"
                :name="name" :class="fieldClass" :disabled="disableDropDown" @input="change">
                <option :value="null" v-if="!noDefaultOption">
                    {{ optionDefaultLabel }}
                </option>
                <option class="text-truncate" v-for="(option, index) of options" :key="index"
                    :value="option" :disabled="isOptionDisabled()">
                    {{ buildOptionLabel(option) }}
                </option>
            </select>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { uuidMixin } from '../../mixins/uuidMixin';

export default {
    name: "labeled-dropdown",
    props: {
        fieldClass: { type: [String, Object, Array], default: null },
        name: { type: String, default: null },
        options: { type: Array as PropType<any[]>, default: null },
        optionValueKey: { type: String, default: null },
        optionDefaultLabel: { type: String, default: "" },
        noDefaultOption: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        optionLabelSelector: { type: String, default: null },
        optionAdditionalText: { type: String, default: null },
        modelValue: { default: null },
        labelText: { type: String, default: null },
        labelClass: { type: [String, Object, Array], default: null },
    },
    emits: ['update:modelValue'],
    mixins: [uuidMixin],
    data() {
        return {
            selectId: null
        };
    },

    computed: {
        disableDropDown() {
            return !this.options?.length || this.disabled;
        },
    },

    methods: {
        isOptionDisabled() {
            return (
                this.disabled
            );
        },

        buildOptionLabel(option: any) {
            const s = this.optionLabelSelector;
            let buildedText = null;
            if (typeof s === "string" || s instanceof String) {
                //@ts-expect-error
                buildedText = option[s];
            }
            if (typeof option === "string" || option instanceof String) {
                buildedText = option;
            }
            if (this.optionAdditionalText) {
                buildedText += " " + option[this.optionAdditionalText];
            }
            return buildedText;
        },

        change(event: any) {
            this.$emit("update:modelValue", event.target.value);
        },
    },
    created() {
        this.selectId = uuidMixin.methods.getUuid().toString();
    }
};
</script>

<style scoped>
.select-main {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #1a191980;
    border-radius: 24px;
    opacity: 1;
    width: 100%;
    height: 48px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 35px;
}

/* localhost works without this, but 'dev' does not */
.select-main:disabled {
    color: #6c757d !important;
    background-color: #e9ecef !important;
}

.select-main:focus {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #52bce8;
    border-radius: 24px;
    opacity: 1;
    outline: none;
}

.select-main:disabled~.caret {
    color: #292525;
}

.select-main::-ms-expand {
    display: none;
}

.dropdown-wrapper:after {
    content: "\25BC";
    font-family: monospace, monospace;
    position: absolute;
    top: calc(50% - 10px);
    right: 15px;
    color: #52bce8;
    pointer-events: none;
}

.dropdown-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.label-wrapper {
    margin: 0 0 8px 10px;
    display: unset;
    cursor: unset;
}
</style>