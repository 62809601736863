const ASSETS = require.context('@/assets/logos', true)

export const logoURLMixin = {
    methods: {
        getLogoURL: function (pharmacyName) {
            switch (pharmacyName) {
                case "WALGREENS":
                    return ASSETS("./transparent-walgreens.webp");
                case "KROGER":
                case "KROGER PHARMACY":
                    return ASSETS("./transparent-kroger.webp");
                case "ALBERTSONS":
                case "ALBERTSONS PHARMACY":
                    return ASSETS("./albertsons.png");
                case "WALMART":
                case "WALMART PHARMACY":
                    return ASSETS("./transparent-walmart.webp");
                case "RITE AID":
                case "RITE AID PHARMACY":
                    return ASSETS("./rite-aid.png");
                case "CVS":
                case "CVS PHARMACY":
                    return ASSETS("./transparent-cvs.webp");
                default:
                    return "";
            }
        }
    }
}