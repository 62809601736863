import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50b8c634"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "type"]
const _hoisted_2 = ["type", "aria-label"]
const _hoisted_3 = { class: "align-items-center" }
const _hoisted_4 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.to)
    ? (_openBlock(), _createElementBlock("a", _mergeProps({
        key: 0,
        href: $props.to,
        type: $props.type
      }, $options.attrs, { exact: "" }), [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 16, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", _mergeProps({
        key: 1,
        type: $props.type,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.clicked && $options.clicked(...args)))
      }, $options.attrs, { "aria-label": $props.ariaLabel }), [
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          ($props.showCaret)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                name: $options.caretName,
                class: "caret"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ])
      ], 16, _hoisted_2))
}