import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59ca096a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "",
  style: {"padding-top":"120px"}
}
const _hoisted_2 = { class: "d-none d-md-block display-2 text-center" }
const _hoisted_3 = { class: "d-block d-md-none display-3 text-center" }
const _hoisted_4 = { class: "pitch-content-container border rounded mb-1" }
const _hoisted_5 = { class: "justify-content-around d-flex flex-column inner-container" }
const _hoisted_6 = { class: "d-none d-md-block display-3" }
const _hoisted_7 = { class: "d-block d-md-none display-4" }
const _hoisted_8 = { class: "lead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString($props.title), 1),
    _createElementVNode("h2", _hoisted_3, _toDisplayString($props.title), 1),
    _createElementVNode("div", {
      class: _normalizeClass($options.getOverallClass)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pitches, (pitch) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "col col-12 col-md-6 col-lg-6",
          key: pitch.title
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(pitch.title), 1),
              _createElementVNode("h3", _hoisted_7, _toDisplayString(pitch.title), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(pitch.details), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  to: "/request-a-demo",
                  class: "btn btn-lg",
                  style: {"background":"var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box","color":"black"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Learn More "),
                    _createVNode(_component_font_awesome_icon, {
                      icon: "fa fa-arrow-right",
                      "aria-hidden": "true"
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]))
      }), 128))
    ], 2)
  ]))
}