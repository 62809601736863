import { createRouter, createWebHistory } from 'vue-router';
import { cookiemixin } from '../mixins/cookiemixin';

// Key pages in the typical user flows which need to be in the main bundle
import HomePage from '../components/home-page.vue';
import PPage from '../components/p.vue';
import GoodRxComparisonPage from '../components/goodrx-comparison/goodrx-comparison-page.vue';
import SearchDrugPage from '../components/search-page/search-drug-page.vue';
import CouponDetailsPage from '../components/coupon-details-page.vue';
import ManufacturerCouponPage from '../components/manufacturer-coupon-page.vue';
import AlternativeMedicationPage from '../components/alternative-medication-page.vue';
import SavingsForMembers from '../components/employer-program/savings-for-members.vue';
import SavingsForEmployers from '../components/employer-program/savings-for-employers.vue';
import BenefitConsultants from '../components/employer-program/benefit-consultants.vue';
import ForPayers from '../components/employer-program/for-payers.vue';
import PBMs from '../components/employer-program/health-plans.vue';
import RequestADemo from '../components/request-a-demo.vue';
import CouponAPI from '../components/coupon-api/coupon-api.vue';
import APIContact from '../components/coupon-api/api-contact.vue';

// Landing pages which ?need to be in the main bundle?
import TechLandingPage from '../components/land/tech-landing-page.vue';
import ReimburseMe from '../components/reimburse-me.vue';
import PrescriptionTransfer from '../components/prescription-transfer.vue';

// Pages you can reach from the navigation buttons which ?do NOT need to be in the main bundle?
const HowItWorksPage = () => import(/* webpackChunkName: "navable-pages" */ '../components/how-it-works-page.vue');
const AboutPage = () => import(/* webpackChunkName: "navable-pages" */ '../components/about-page.vue');
const ConsumerDiscountCardsPage = () => import(/* webpackChunkName: "navable-pages" */ '../components/consumer-discount-cards-page.vue');
const ContactPage = () => import(/* webpackChunkName: "navable-pages" */ '../components/contact-page.vue');
const BlogPost = () => import(/* webpackChunkName: "navable-pages" */ '../components/blog-post.vue');
const CVSSavingsFinderComparison = () => import(/* webpackChunkName: "navable-pages" */ '../components/cvs-savings-finder-comparison/cvs-savings-finder-comparison.vue');
const OptumPerksComparison = () => import(/* webpackChunkName: "navable-pages" */ '../components/optum-perks-comparison/optum-perks-comparison.vue');

// AWS Marketplace pages
const AWSMarketplaceRegisterCustomer = () => import(/* webpackChunkName: "aws-marketplace" */ '../components/aws-marketplace/register-customer.vue');
const AWSMarketplaceResolveCustomerError = () => import(/* webpackChunkName: "aws-marketplace" */ '../components/aws-marketplace/resolve-customer-error.vue');

// Marketplace pages are for admin and other events which aren't tied to any one cloud platform
const MarketplaceIntelligentRxAdmin = () => import(/* webpackChunkName: "marketplace-admin" */ '../components/marketplace/intelligentrx-admin.vue');
const MarketplaceAdmin = () => import(/* webpackChunkName: "marketplace-admin" */ '../components/marketplace/admin.vue');

// Caremark has its own, separate APIs and its own monitoring. Consequently, it needs its own admin pages
const CaremarkIntelligentRxAdmin = () => import(/* webpackChunkName: "caremark-admin" */ '../components/caremark/intelligentrx-admin.vue');

// Uncommon pages
const LocalPage = () => import(/* webpackChunkName: "less-common" */ '../components/local-page.vue');
const CareersPage = () => import(/* webpackChunkName: "less-common" */ '../components/careers-page.vue');
const PrivacyPolicyPage = () => import(/* webpackChunkName: "less-common" */ '../components/privacy-policy-page.vue');
const TermsOfUsePage = () => import(/* webpackChunkName: "less-common" */ '../components/terms-of-use-page.vue');
const HelpAndFAQPage = () => import(/* webpackChunkName: "less-common" */ '../components/faq-page.vue');
const PharmacistInformationPage = () => import(/* webpackChunkName: "less-common" */ '../components/pharmacist-information-page.vue');
const ProviderInformationPage = () => import(/* webpackChunkName: "less-common" */ '../components/provider-information-page.vue');
const BlogIndex = () => import(/* webpackChunkName: "less-common" */ '../components/blog-index.vue');
const NotFoundPage = () => import(/* webpackChunkName: "less-common" */ '../components/not-found-page.vue');
const ShouldIUseACoupon = () => import(/* webpackChunkName: "less-common" */ '../components/should-i-use-a-coupon.vue');
const MobileAppPage = () => import(/* webpackChunkName: "less-common" */ '../components/mobile-app-page.vue');
const IrxSavvyPage = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/irxsavvy-page.vue');
const PillSplitCompatibility = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/pill-split-compatibility.vue');
const DrugClass = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/drug-class.vue');
const DrugClassList = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/drug-class-list.vue');
const DrugClassMembers = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/drug-class-members.vue');
const EquivalentEffects = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/equivalent-effects.vue');
const ExpectedCostOfDrug = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/expected-cost.vue');
const NADACPricing = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/nadac-pricing.vue');
const DrugClassCosts = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/drug-class-costs.vue');
const MedGuide = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/medguide.vue');
const EstimatedCostSharing = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/estimated-cost-sharing.vue');
const AllDrugInfo = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/all-drug-info.vue');
const DrugAlternatives = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/drug-alternatives.vue');
const PatientCopayWithInsuranceTool = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/patient-copay-with-insurance.vue');
const MailOrderCost = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/patient-copay-with-mail-order.vue');

const CouponsAtAllPbms = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/coupons-at-all-pbms.vue');
const GoodrxComparison = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/goodrx-comparison.vue');
const OptumComparison = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/optum-comparison.vue');
const Pharmacies = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/pharmacies.vue');
const PharmaciesNearby = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/pharmacies-nearby.vue');
const DiagnosesForAMedication = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/diagnoses-for-a-medication.vue');
const DiagnosisList = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/diagnosis-list.vue');
const NonRxTreatmentOptionsForDx = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/non-rx-treatment-options-for-dx.vue');
const GoodPriceOrBadPrice = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/good-price-or-bad-price.vue');
const AbleToAdhereToTreatmentPlan = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/able-to-adhere-to-treatment-plan.vue');
const ListDrugNdcs = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/list-drug-ndcs.vue');
const InsuranceReimbursement = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/insurance-reimbursement.vue');
const CopayPredictor = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/copay-predictor.vue');
const Use90Or30DaySupply = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/use-90-or-30-day-supply.vue');
const PbmNdcPrice = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/pbm-ndc-price.vue');
const MailOrderPharmacyOptions = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/mail-order-pharmacy-options.vue');
const PbmMailOrderPharmacyOptions = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/pbm-mail-order-pharmacy-options.vue');
const CharityOptions = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/charity-options.vue');
const EquivalentFormDosageQtyDaysSupplyInThisLabel = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/equivalent-form-dosage-qty-days-supply-in-this-label.vue');
const ActiveIngredients = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/active-ingredients.vue');
const MedicationsWithActiveIngredients = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/medications-with-active-ingredients.vue');
const ActiveIngredientsToLabels = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/active-ingredients-to-labels.vue');
const AlternativeMedicationFrequencies = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/alternative-medication-frequencies.vue');
const AlternativeMedicationLabels = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/alternative-medication-labels.vue');
const ListManufacturers = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/list-manufacturers.vue');
const ListDistributors = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/list-distributors.vue');
const ListPaymentOptions = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/list-payment-options.vue');
const ManufacturerCoupon = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/manufacturer-coupon.vue');
const UseSecondaryInsurance = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/use-secondary-insurance.vue');
const ChangeMultiPillFrequencies = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/change-multi-pill-frequencies.vue');
const ChangeDeliveryToPillPacks = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/change-delivery-to-pill-packs.vue');
const ChangeMedicationInstructions = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/change-medication-instructions.vue');
const ListCommonRoutineTriggers = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/list-common-routine-triggers.vue');
const SignUpForMedicationReminders = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/sign-up-for-medication-reminders.vue');
const SignUpForMedicationCheckIn = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/sign-up-for-medication-check-in.vue');
const SignUpForThankYous = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/sign-up-for-thank-yous.vue');
const SignUpForPersonalizedThankYous = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/sign-up-for-personalized-thank-yous.vue');
const SignUpForMonetaryIncentives = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/sign-up-for-monetary-incentives.vue');
const SignUpForFamilyNotifications = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/sign-up-for-family-notifications.vue');
const SignUpForEducationalContactToDiscussMedications = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/sign-up-for-educational-contact-to-discuss-medications.vue');
const SignUpForRefillNotifications = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/sign-up-for-refill-notifications.vue');
const Icd10GetDescription = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/icd-10-get-description.vue');
const Icd10List = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/icd-10-list.vue');
const RxcuiGetInformation = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/rxcui-get-information.vue');
const RxcuiList = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/rxcui-list.vue');
const IdentifyIcd10Terms = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/identify-icd-10-terms.vue');
const IdentifyRxnormConcepts = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/identify-rxnorm-concepts.vue');
const DrugNameAutocomplete = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/drug-name-autocomplete.vue');
const DrugFormulationsIrxSavvy = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/drug-formulations.vue');
const AllComparison = () => import(/* webpackChunkName: "less-common" */ '../components/irxsavvy/all-comparison.vue');

const IrxStatusPage = () => import(/* webpackChunkName: "less-common" */ '../components/public-admin/irxsavvy-status-page.vue');

const LambdaStatus = () => import(/* webpackChunkName: "group-admin" */ '../components/admin/lambda-status.vue');
const PriceExplainer = () => import(/* webpackChunkName: "group-admin" */ '../components/admin/price-explainer.vue');
const PharmacyExplainer = () => import(/* webpackChunkName: "group-admin" */ '../components/admin/pharmacy-explainer.vue');
const PriceHistory = () => import(/* webpackChunkName: "group-admin" */ '../components/admin/price-history.vue');
const ClearCache = () => import(/* webpackChunkName: "group-admin" */ '../components/admin/clear-cache.vue');
const ClaimAnalysis = () => import(/* webpackChunkName: "group-admin" */ '../components/admin/claim-analysis.vue');

// If you are updating this list and want to allow analytics, check out App.vue method maybeDoAnalytics()
const routes = [
    {
        path: '/',
        name: 'home-page',
        component: HomePage,
        meta: {
            title: "IntelligentRx | Prescription Discount Coupon Search",
            description: "Save up to 85% on prescriptions at your local pharmacy. IntelligentRx finds the lowest prescription price at a pharmacy near you."
        }
    },
    {
        path: '/p',
        name: 'p-page',
        component: PPage,
        meta: {
            title: "IntelligentRx | Prescription Discount Coupon Search",
            description: "Save up to 85% on prescriptions at your local pharmacy. IntelligentRx finds the lowest prescription price at a pharmacy near you."
        }
    },
    {
        path: '/how-it-works',
        name: 'how-it-works-page',
        component: HowItWorksPage,
        meta: {
            title: "How it Works | IntelligentRx",
            description: "We provide the best savings on prescriptions to help everyone afford their medications. How does IntelligentRx work?"
        }
    },
    {
        path: '/for-members',
        name: 'savings-for-members',
        component: SavingsForMembers,
        meta: {
            title: "Savings for Members | IntelligentRx",
            description: "Reduce your medication spending"
        }
    },
    {
        path: '/for-employers',
        name: 'savings-for-employers',
        component: SavingsForEmployers,
        meta: {
            title: "Savings for Employers | IntelligentRx",
            description: "Reduce your prescription benefit spending"
        }
    },
    {
        path: '/for-benefit-consultants',
        name: 'benefit-consultants',
        component: BenefitConsultants,
        meta: {
            title: "Benefit Consultants | IntelligentRx",
            description: "Reduce prescription benefit spending"
        }
    },
    {
        path: '/for-health-plans',
        name: 'health-plans',
        component: PBMs,
        meta: {
            title: "Employer Savings for PBMs | IntelligentRx",
            description: "Reduce your prescription benefit spending"
        }
    },
    {
        path: '/for-payers',
        name: 'for-payers',
        component: ForPayers,
        meta: {
            title: "Payer Savings | IntelligentRx",
            description: "Improve Outcomes & Reduce Spending"
        }
    },
    {
        path: '/request-a-demo',
        name: 'request-a-demo',
        component: RequestADemo,
        meta: {
            title: "Request a Demo | IntelligentRx",
            description: "Request a demonstration of our premier services",
        }
    },
    {
        path: '/coupon-api',
        name: 'coupon-api',
        component: CouponAPI,
        meta: {
            title: "Coupon API | IntelligentRx",
        }
    },
    {
        path: '/api-contact',
        name: 'api-contact',
        component: APIContact,
        meta: {
            title: "Contact Us | API | IntelligentRx"
        }
    },
    {
        path: '/about',
        name: 'about-page',
        component: AboutPage,
        meta: {
            title: "About | IntelligentRx",
            description: "IntelligentRx is a healthcare savings innovation company. Our mission is to increase access to affordable healthcare to everyone."
        }
    },
    {
        path: '/careers',
        name: 'careers-page',
        component: CareersPage,
        meta: {
            title: "Careers | IntelligentRx",
            description: "IntelligentRx is a healthcare savings innovation company. Our mission is to increase access to affordable healthcare to everyone."
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy-page',
        component: PrivacyPolicyPage,
        meta: {
            title: "Privacy Policy | IntelligentRx",
            description: "IntelligentRx's Privacy Policy"
        }
    },
    {
        path: '/terms-of-use',
        name: 'terms-of-use-page',
        component: TermsOfUsePage,
        meta: {
            title: "Terms of Use | IntelligentRx",
            description: "IntelligentRx's Terms of Use"
        }
    },
    {
        path: '/faq',
        name: 'faq-page',
        component: HelpAndFAQPage,
        meta: {
            title: "Frequently Asked Questions | IntelligentRx",
            description: "Frequently Asked Questions about prescription savings and IntelligentRx"
        }
    },
    {
        path: '/consumer-discount-cards',
        name: 'consumer-discount-cards-page',
        component: ConsumerDiscountCardsPage,
        meta: {
            title: "Consumer Discount Cards | IntelligentRx",
            description: "IntelligentRx's Consumer Discount Cards"
        }
    },
    {
        path: '/pharmacist-information',
        name: 'pharmacist-information-page',
        component: PharmacistInformationPage,
        meta: {
            title: "Pharmacist Information | IntelligentRx",
            description: "IntelligentRx's Pharmacist Information"
        }
    },
    {
        path: '/provider-information',
        name: 'provider-information-page',
        component: ProviderInformationPage,
        meta: {
            title: "Provider Information | IntelligentRx",
            description: "IntelligentRx's Provider Information"
        }
    },
    {
        path: '/contact',
        name: 'contact-page',
        component: ContactPage,
        meta: {
            title: "Contact | IntelligentRx",
            description: "IntelligentRx's Contact"
        }
    },
    {
        path: '/search',
        name: 'search-drug-page',
        component: SearchDrugPage,
        meta: {
            title: "Drug Discount Search | IntelligentRx",
            description: "Search for drug discounts at pharmacies near you"
        }
    },
    {
        path: '/coupon',
        name: 'coupon-details-page',
        component: CouponDetailsPage,
        props: true,
        meta: {
            title: "Prescription Discount Coupon | IntelligentRx",
            description: "View a prescription discount coupon"
        }
    },
    {
        path: '/manufacturer-coupon',
        name: 'manufacturer-coupon-page',
        component: ManufacturerCouponPage,
        props: true,
        meta: {
            title: "Manufacturer Coupon | IntelligentRx",
            description: "View a manufacturer coupon"
        }
    },
    {
        path: '/alternative-medication',
        name: 'alternative-medication-page',
        component: AlternativeMedicationPage,
        props: true,
        meta: {
            title: "Alternative Medication | IntelligentRx",
            description: "View an alternative prescription medication",
        }
    },
    {
        path: '/local',
        name: 'local-page',
        component: LocalPage,
        props: true,
        meta: {
            title: "",
            description: "",
        }
    },
    {
        path: '/admin',
        redirect: '/admin/price-explainer',
    },
    {
        path: '/admin/lambda-status',
        name: 'lambda-status',
        component: LambdaStatus,
        meta: {
            title: "Admin | IntelligentRx",
            description: "",
        }
    },
    {
        path: '/admin/price-explainer',
        name: 'price-explainer',
        component: PriceExplainer,
        meta: {
            title: "Admin | IntelligentRx",
            description: "",
        }
    },
    {
        path: '/admin/pharmacy-explainer',
        name: 'pharmacy-explainer',
        component: PharmacyExplainer,
        meta: {
            title: "Admin | IntelligentRx",
            description: "",
        }
    },
    {
        path: '/admin/price-history',
        name: 'price-history',
        component: PriceHistory,
        meta: {
            title: "Admin | IntelligentRx",
            description: "",
        }
    },
    {
        path: '/admin/clear-cache',
        name: 'clear-cache',
        component: ClearCache,
        meta: {
            title: "Admin | IntelligentRx",
            description: "",
        }
    },
    {
        path: '/admin/claim-analysis',
        name: 'claim-analysis',
        component: ClaimAnalysis,
        meta: {
            title: "Admin | IntelligentRx",
            description: "",
        }
    },
    {
        path: '/blog/:id',
        name: 'blog-post',
        props: true,
        component: BlogPost,
        meta: {
            title: "Blog | IntelligentRx",
            description: "",
        }
    },
    {
        path: '/blog',
        name: 'blog-index',
        component: BlogIndex,
        meta: {
            title: "Blog | IntelligentRx",
            description: "",
        }
    },
    {
        path: '/goodrx-comparison',
        name: 'goodrx-comparison-page',
        component: GoodRxComparisonPage,
        meta: {
            title: "GoodRx vs. IntelligentRx 2022",
            description: "Search for prescription drug prices from IntelligentRx and GoodRx and compare to save money on your prescriptions! IntellientRx is a prescription discount savings coupon service which connects YOU with the biggest and best prescription discounts."
        }
    },
    {
        path: '/cvs-savings-finder-comparison',
        name: 'cvs-savings-finder-comparison',
        component: CVSSavingsFinderComparison,
        meta: {
            title: "CVS Savings Finder Comparison",
            description: "Search for prescription drug prices from IntelligentRx and the CVS Savings Finder Comparison to find out which one is better! IntellientRx is a prescription discount savings coupon service which connects YOU with the biggest and best prescription discounts."
        }
    },
    {
        path: '/optum-perks-comparison',
        name: 'optum-perks-comparison',
        component: OptumPerksComparison,
        meta: {
            title: "Optum Perks Comparison",
            description: "Search for prescription drug prices from IntelligentRx and the Optum Perks Site to find out which one is better! IntellientRx is a prescription discount savings coupon service which connects YOU with the biggest and best prescription discounts",
        }
    },
    {
        path: '/aws-marketplace/register-customer',
        name: 'aws-marketplace-register-customer',
        component: AWSMarketplaceRegisterCustomer,
        meta: {
            title: "Register Customer | Marketplace | IntelligentRx",
            description: "Register as an IntelligentRx customer after purchasing access to our product from a Marketplace"
        }
    },
    {
        path: '/aws-marketplace/resolve-customer-error',
        name: 'aws-marketplace-resolve-customer-error',
        component: AWSMarketplaceResolveCustomerError,
        meta: {
            title: "Resolve Customer Error | Marketplace | IntelligentRx",
            description: "Error page shown when a customer's purchase of an IntelligentRx product via a Marketplace fails to work"
        }
    },
    {
        path: '/marketplace/intelligentrx-admin',
        name: 'marketplace-intelligentrx-admin',
        component: MarketplaceIntelligentRxAdmin,
        meta: {
            title: "IntelligentRx Admin | Marketplace | IntelligentRx",
            description: "N/A"
        }
    },
    {
        path: '/marketplace/admin',
        name: 'marketplace-admin',
        component: MarketplaceAdmin,
        meta: {
            title: "Admin | Marketplace | IntelligentRx",
            description: "Manage your marketplace subscriptions and access to IntelligentRx services",
        }
    },
    {
        path: '/caremark/intelligentrx-admin',
        name: 'caremark-intelligentrx-admin',
        component: CaremarkIntelligentRxAdmin,
        meta: {
            title: "IntelligentRx Admin | Caremark | IntelligentRx",
            description: "N/A"
        }
    },
    {
        path: '/should-i-use-a-coupon',
        name: 'should-i-use-a-coupon',
        component: ShouldIUseACoupon,
        meta: {
            title: "Should I Use a Coupon?",
            description: "Work out if you should use a prescription discount coupon or not."
        }
    },
    {
        path: '/mobile-app',
        name: 'mobile-app',
        component: MobileAppPage,
        meta: {
            title: "IntelligentRx Mobile App",
            description: "Link to IntelligentRx's Mobile App for downloading"
        }
    },
    {
        path: '/do-not-overcharge-for-prescriptions',
        name: 'do-not-overcharge-for-prescriptions',
        component: TechLandingPage,
        meta: {
            title: "Don't Overcharge For Prescriptions",
            description: "With lower pharmacy fees than GoodRx and better or comparable prices, IntelligentRx is better for your pharmacy and better for consumers, and we have the analyses to prove it.",
        }
    },
    {
        path: '/irxsavvy',
        name: 'irxsavvy',
        component: IrxSavvyPage,
        meta: {
            title: "IRxSavvy by IntelligentRx",
            description: "Savvy ways to save money on prescription medications",
        }
    },
    {
        path: '/irxsavvy/pill-split-compatibility',
        name: 'PillSplitCompatibility',
        component: PillSplitCompatibility,
        meta: {
            title: "Pill Split Compatibility",
            description: "Determine whether or not your prescription medication can be split using a pill splitter",
        }
    },
    {
        path: '/irxsavvy/drug-class',
        name: 'DrugClass',
        component: DrugClass,
        meta: {
            title: "Drug Class Tool",
            description: "Find similar prescription medications to this medication",
        }
    },
    {
        path: '/irxsavvy/drug-class-list',
        name: 'DrugClassList',
        component: DrugClassList,
        meta: {
            title: "Drug Class List",
            description: "A list of drug classes",
        }
    },
    {
        path: '/irxsavvy/drug-class-members',
        name: 'DrugClassMembers',
        component: DrugClassMembers,
        meta: {
            title: "Drug Class Members",
            description: "A list of prescription medications inside a single drug class.",
        }
    },
    {
        path: '/irxsavvy/equivalent-effects',
        name: 'EquivalentEffects',
        component: EquivalentEffects,
        meta: {
            title: 'Drugs with Equivalent Effects',
            description: 'A list of prescription medications which have equivalent therapeutic effects to a selected medication.',
        }
    },
    {
        path: '/irxsavvy/expected-cost',
        name: 'ExpectedCostOfDrug',
        component: ExpectedCostOfDrug,
        meta: {
            title: "Expected Cost Of Drug",
            description: "Get an estimate for how much a drug should cost",
        }
    },
    {
        path: '/irxsavvy/nadac-pricing',
        name: 'NADACPricing',
        component: NADACPricing,
        meta: {
            title: "NADAC Pricing Of Drug",
            description: "Get an estimate for how much a drug should cost",
        }
    },
    {
        path: '/irxsavvy/drug-class-costs',
        name: 'DrugClassCosts',
        component: DrugClassCosts,
        meta: {
            title: "Drug Class Costs",
            description: "Get an estimate for how much different drugs in a drug class cost.",
        }
    },
    {
        path: '/irxsavvy/medguide',
        name: 'MedGuide',
        component: MedGuide,
        meta: {
            title: "Medguide for a drug",
            description: "Get a variety of information about a prescription medication",
        }
    },
    {
        path: '/irxsavvy/estimated-cost-sharing',
        name: 'EstimatedCostSharing',
        component: EstimatedCostSharing,
        meta: {
            title: "Expected Cost of a Drug for a Patient",
            description: "Expected cost of a drug for a Patient",
        }
    },
    {
        path: '/irxsavvy/all-drug-info',
        name: 'AllDrugInfo',
        component: AllDrugInfo,
        meta: {
            title: "All IRx Savvy Information",
            description: "This tool collects data on a single prescription medication from all over our website and gives it to you in one place.",
        }
    },
    {
        path: '/irxsavvy/drug-alternatives',
        name: 'DrugAlternatives',
        component: DrugAlternatives,
        meta: {
            title: "Prescription Coupon Alternatives | IntelligentRx",
            description: "Many prescription drugs have alternative ways to save other than directly using prescription discount coupons. This tool finds some of those ways to save.",
        }
    },
    {
        path: '/irxsavvy/patient-copay-with-insurance',
        name: 'PatientCopayWithInsuranceTool',
        component: PatientCopayWithInsuranceTool,
        meta: {
            title: "Patient Copay With Insurance",
            description: "The copay a patient pays for a prescription drug with their insurance.",
        }
    },
    {
        path: '/irxsavvy/patient-copay-with-mail-order',
        name: 'MailOrderCost',
        component: MailOrderCost,
        meta: {
            title: "Patient Copay with Mail Order",
            description: "The amount of money a patient would pay if they used a mail order service. This page may return multiple mail order services for comparison",
        }
    },
    {
        path: '/irxsavvy/coupons-at-all-pbms',
        name: 'CouponsAtAllPbms',
        component: CouponsAtAllPbms,
        meta: {
            title: "Coupons At All Pbms",
            description: "Shows coupons for a particular drug formulation at all PBMs which are integrated with IntelligentRx. This is the same API call and setup as you see on the website",
        }
    }, {
        path: '/irxsavvy/goodrx-comparison',
        name: 'GoodrxComparison',
        component: GoodrxComparison,
        meta: {
            title: "Goodrx Comparison",
            description: "A GoodRx Comparison can return a GoodRx price as copied from their website. This is not necessarily a current, up to date price - only a price from when we last went onto the website and copied the price into our own database.",
        }
    }, {
        path: '/irxsavvy/optum-comparison',
        name: 'OptumComparison',
        component: OptumComparison,
        meta: {
            title: "Optum Comparison",
            description: "An Optum Comparison page can return an Optum Perks price as copied from their website.",
        }
    },
    {
        path: '/irxsavvy/pharmacies',
        name: 'Pharmacies',
        component: Pharmacies,
        meta: {
            title: "Pharmacies",
            description: "Search for a Pharmacy by Name, NCPDP, or NPI",
        }
    },
    {
        path: '/irxsavvy/pharmacies-nearby',
        name: 'PharmaciesNearby',
        component: PharmaciesNearby,
        meta: {
            title: "Pharmacies Nearby",
            description: "Search for a Pharmacy by Latitude and Longitude",
        }
    },
    {
        path: '/irxsavvy/diagnoses-for-a-medication',
        name: 'DiagnosesForAMedication',
        component: DiagnosesForAMedication,
        meta: {
            title: "Diagnoses For A Medication",
            description: "list common diagnoses associated with a medication",
        }
    }, {
        path: '/irxsavvy/diagnosis-list',
        name: 'DiagnosisList',
        component: DiagnosisList,
        meta: {
            title: "Diagnosis List",
            description: "list all known diagnoses",
        }
    }, {
        path: '/irxsavvy/non-rx-treatment-options-for-dx',
        name: 'NonRxTreatmentOptionsForDx',
        component: NonRxTreatmentOptionsForDx,
        meta: {
            title: "Non Rx Treatment Options For Dx",
            description: "list non-rx treatment options associated with a diagnosis",
        }
    }, {
        path: '/irxsavvy/good-price-or-bad-price',
        name: 'GoodPriceOrBadPrice',
        component: GoodPriceOrBadPrice,
        meta: {
            title: "Good Price Or Bad Price",
            description: "returns the nadac price plus a reasonable markup for the medication at a particular pharmacy to determine if the listed price is good",
        }
    }, {
        path: '/irxsavvy/able-to-adhere-to-treatment-plan',
        name: 'AbleToAdhereToTreatmentPlan',
        component: AbleToAdhereToTreatmentPlan,
        meta: {
            title: "Able To Adhere To Treatment Plan",
            description: "determines if a patient is able to adhere to a medication's treatment plan",
        }
    }, {
        path: '/irxsavvy/list-drug-ndcs',
        name: 'ListDrugNdcs',
        component: ListDrugNdcs,
        meta: {
            title: "List Drug Ndcs",
            description: "for a drug label, form and dosage, return all NDCs",
        }
    }, {
        path: '/irxsavvy/insurance-reimbursement',
        name: 'InsuranceReimbursement',
        component: InsuranceReimbursement,
        meta: {
            title: "Insurance Reimbursement",
            description: "Submit a request to be reimbursed by your insurance for a medication you have taken and for which you paid in cash or with a prescription discount coupon.",
        }
    }, {
        path: '/irxsavvy/copay-predictor',
        name: 'CopayPredictor',
        component: CopayPredictor,
        meta: {
            title: "Copay Predictor",
            description: "Predict your copay you will pay with your insurance",
        }
    }, {
        path: '/irxsavvy/use-90-or-30-day-supply',
        name: 'Use90Or30DaySupply',
        component: Use90Or30DaySupply,
        meta: {
            title: "Use 90 Or 30 Day Supply",
            description: "Given a drug name (label name), calculate the cost per day of a 30 and 90 day supply of a medication.",
        }
    }, {
        path: '/irxsavvy/pbm-ndc-price',
        name: 'PbmNdcPrice',
        component: PbmNdcPrice,
        meta: {
            title: "Pbm Ndc Price",
            description: "Given an NDC and a particular PBM, calculate the price of that NDC at that PBM.",
        }
    }, {
        path: '/irxsavvy/mail-order-pharmacy-options',
        name: 'MailOrderPharmacyOptions',
        component: MailOrderPharmacyOptions,
        meta: {
            title: "Mail Order Pharmacy Options",
            description: "Get a list of all mail order pharmacies which are known to our system",
        }
    }, {
        path: '/irxsavvy/pbm-mail-order-pharmacy-options',
        name: 'PbmMailOrderPharmacyOptions',
        component: PbmMailOrderPharmacyOptions,
        meta: {
            title: "Pbm Mail Order Pharmacy Options",
            description: "Get a list of all mail order pharmacies used or endorsed by a particular PBM",
        }
    }, {
        path: '/irxsavvy/charity-options',
        name: 'CharityOptions',
        component: CharityOptions,
        meta: {
            title: "Charity Options",
            description: "Get a list of all charities which are known to our system which can provide free or reduced price medications",
        }
    }, {
        path: '/irxsavvy/equivalent-form-dosage-qty-days-supply-in-this-label',
        name: 'EquivalentFormDosageQtyDaysSupplyInThisLabel',
        component: EquivalentFormDosageQtyDaysSupplyInThisLabel,
        meta: {
            title: "Equivalent Form Dosage Qty Days Supply In This Label",
            description: "Get a list of all form/dosage/qty/days supply values which are equivalent to this current value. Values returned are limited to taking twelve doses on the same day",
        }
    }, {
        path: '/irxsavvy/active-ingredients',
        name: 'ActiveIngredients',
        component: ActiveIngredients,
        meta: {
            title: "Active Ingredients",
            description: "for a label, lists the active ingredients in this medication",
        }
    }, {
        path: '/irxsavvy/medications-with-active-ingredients',
        name: 'MedicationsWithActiveIngredients',
        component: MedicationsWithActiveIngredients,
        meta: {
            title: "Medications With Active Ingredients",
            description: "for an active ingredient, lists the labels which use at least one active ingredient",
        }
    }, {
        path: '/irxsavvy/active-ingredients-to-labels',
        name: 'ActiveIngredientsToLabels',
        component: ActiveIngredientsToLabels,
        meta: {
            title: "Active Ingredients To Labels",
            description: "for a list of active ingredients, finds combinations of labels which, together, contain those active ingredients",
        }
    }, {
        path: '/irxsavvy/alternative-medication-frequencies',
        name: 'AlternativeMedicationFrequencies',
        component: AlternativeMedicationFrequencies,
        meta: {
            title: "Alternative Medication Frequencies",
            description: "give alternatives to taking a medication once a day, twice a day, etc., up to 12 times per day or down to once per week",
        }
    }, {
        path: '/irxsavvy/alternative-medication-labels',
        name: 'AlternativeMedicationLabels',
        component: AlternativeMedicationLabels,
        meta: {
            title: "Alternative Medication Labels",
            description: "List other medication labels (names) which have the same active ingredient",
        }
    }, {
        path: '/irxsavvy/list-manufacturers',
        name: 'ListManufacturers',
        component: ListManufacturers,
        meta: {
            title: "List Manufacturers",
            description: "list the manufacturers of a drug without changing its label, form, dosage, or quantity",
        }
    }, {
        path: '/irxsavvy/list-distributors',
        name: 'ListDistributors',
        component: ListDistributors,
        meta: {
            title: "List Distributors",
            description: "list distributors which carry a particular NDC",
        }
    }, {
        path: '/irxsavvy/list-payment-options',
        name: 'ListPaymentOptions',
        component: ListPaymentOptions,
        meta: {
            title: "List Payment Options",
            description: "list all payment options known for this label-form-dosage-qty like cash, HSA, FSA, coupons, and using insurance",
        }
    }, {
        path: '/irxsavvy/manufacturer-coupon',
        name: 'ManufacturerCoupon',
        component: ManufacturerCoupon,
        meta: {
            title: "Manufacturer Coupon",
            description: "given a label name, get a link to the manufacturer's website and eligibility information",
        }
    }, {
        path: '/irxsavvy/use-secondary-insurance',
        name: 'UseSecondaryInsurance',
        component: UseSecondaryInsurance,
        meta: {
            title: "Use Secondary Insurance",
            description: "given an NDC, quantity, and primary and secondary insurance information, this calculates the cost of the drug using only primary insurance, the cost with only secondary insurance, and the cost with both primary and secondary insurance applied",
        }
    }, {
        path: '/irxsavvy/change-multi-pill-frequencies',
        name: 'ChangeMultiPillFrequencies',
        component: ChangeMultiPillFrequencies,
        meta: {
            title: "Change Multi Pill Frequencies",
            description: "create multi-medication plans which include synchronizing medication frequencies so they are all taken at the same time",
        }
    }, {
        path: '/irxsavvy/change-delivery-to-pill-packs',
        name: 'ChangeDeliveryToPillPacks',
        component: ChangeDeliveryToPillPacks,
        meta: {
            title: "Change Delivery To Pill Packs",
            description: "provides options for using pill packs, which pack multiple medications into individually wrapped packages to be taken all at once",
        }
    }, {
        path: '/irxsavvy/change-medication-instructions',
        name: 'ChangeMedicationInstructions',
        component: ChangeMedicationInstructions,
        meta: {
            title: "Change Medication Instructions",
            description: "alternative medication instructions, such as moving the time of day at which a medication is taken",
        }
    }, {
        path: '/irxsavvy/list-common-routine-triggers',
        name: 'ListCommonRoutineTriggers',
        component: ListCommonRoutineTriggers,
        meta: {
            title: "List Common Routine Triggers",
            description: "list all known routine triggers which occur daily for most patients which can be used to trigger the action to take your medication",
        }
    }, {
        path: '/irxsavvy/sign-up-for-medication-reminders',
        name: 'SignUpForMedicationReminders',
        component: SignUpForMedicationReminders,
        meta: {
            title: "Sign Up For Medication Reminders",
            description: "sign up for a medication reminder, which is when you are notified to remind you to take your medication",
        }
    }, {
        path: '/irxsavvy/sign-up-for-medication-check-in',
        name: 'SignUpForMedicationCheckIn',
        component: SignUpForMedicationCheckIn,
        meta: {
            title: "Sign Up For Medication Check In",
            description: "sign up for notifying the service that you have already taken your medication",
        }
    }, {
        path: '/irxsavvy/sign-up-for-thank-yous',
        name: 'SignUpForThankYous',
        component: SignUpForThankYous,
        meta: {
            title: "Sign Up For Thank Yous",
            description: "receive texts, emails, phone calls, physical mail, and more when you pick up and take your medication as required",
        }
    }, {
        path: '/irxsavvy/sign-up-for-personalized-thank-yous',
        name: 'SignUpForPersonalizedThankYous',
        component: SignUpForPersonalizedThankYous,
        meta: {
            title: "Sign Up For Personalized Thank Yous",
            description: "receive a personalized version of thank yous in the form of texts, emails, phone calls, physical mail, and more when you pick up and take your medication as required",
        }
    }, {
        path: '/irxsavvy/sign-up-for-monetary-incentives',
        name: 'SignUpForMonetaryIncentives',
        component: SignUpForMonetaryIncentives,
        meta: {
            title: "Sign Up For Monetary Incentives",
            description: "receive a payment when you take a medication correctly",
        }
    }, {
        path: '/irxsavvy/sign-up-for-family-notifications',
        name: 'SignUpForFamilyNotifications',
        component: SignUpForFamilyNotifications,
        meta: {
            title: "Sign Up For Family Notifications",
            description: "we will notify your family when you are taking or not taking your medications",
        }
    }, {
        path: '/irxsavvy/sign-up-for-educational-contact-to-discuss-medications',
        name: 'SignUpForEducationalContactToDiscussMedications',
        component: SignUpForEducationalContactToDiscussMedications,
        meta: {
            title: "Sign Up For Educational Contact To Discuss Medications",
            description: "ask to be called, texted, emailed, or sent mail regarding why you need to take your medication",
        }
    }, {
        path: '/irxsavvy/sign-up-for-refill-notifications',
        name: 'SignUpForRefillNotifications',
        component: SignUpForRefillNotifications,
        meta: {
            title: "Sign Up For Refill Notifications",
            description: "ask to be notified when a refill for your medication should be available",
        }
    },
    {
        path: '/irxsavvy/icd-10-get-description',
        name: 'Icd10GetDescription',
        component: Icd10GetDescription,
        meta: {
            title: "Icd 10 Get Description",
            description: "given an ICD-10 code, get a description of that ICD-10 code",
        }
    }, {
        path: '/irxsavvy/icd-10-list',
        name: 'Icd10List',
        component: Icd10List,
        meta: {
            title: "Icd 10 List",
            description: "get a list of all ICD-10 codes",
        }
    }, {
        path: '/irxsavvy/rxcui-get-information',
        name: 'RxcuiGetInformation',
        component: RxcuiGetInformation,
        meta: {
            title: "Rxcui Get Information",
            description: "get all related information of an RXCUI, aka an RX Norm Concept in a specified version",
        }
    }, {
        path: '/irxsavvy/rxcui-list',
        name: 'RxcuiList',
        component: RxcuiList,
        meta: {
            title: "Rxcui List",
            description: "get a list of all RXCUI, aka an RX Norm Concept in a specified version",
        }
    }, {
        path: '/irxsavvy/identify-icd-10-terms',
        name: 'IdentifyIcd10Terms',
        component: IdentifyIcd10Terms,
        meta: {
            title: "Identify Icd 10 Terms",
            description: "given some text, identify and list all terms which are ICD-10 codes or ICD-10 descriptions",
        }
    }, {
        path: '/irxsavvy/identify-rxnorm-concepts',
        name: 'IdentifyRxnormConcepts',
        component: IdentifyRxnormConcepts,
        meta: {
            title: "Identify Rxnorm Concepts",
            description: "given some text, identify and list all rxnorm concepts",
        }
    },
    {
        path: '/irxsavvy/drug-name-autocomplete',
        name: 'DrugNameAutocomplete',
        component: DrugNameAutocomplete,
        meta: {
            title: "Drug Name Autocomplete",
            description: "given some text, try to autocomplete the text as a drug name",
        }
    },
    {
        path: '/irxsavvy/status-page',
        name: 'IrxStatusPage',
        component: IrxStatusPage,
        meta: {
            title: "IrxSavvy Status Page",
            description: "",
        }
    },
    {
        path: '/irxsavvy/drug-formulations',
        name: 'DrugFormulationsIrxSavvy',
        component: DrugFormulationsIrxSavvy,
        meta: {
            title: "Drug Formulations",
            description: "Used to retrieve all formulations of a drug given a drug's name.",
        }
    },
    {
        path: '/irxsavvy/all-comparison',
        name: 'AllComparison',
        component: AllComparison,
        meta: {
            title: "All Comparison",
            description: "A list of non-IntelligentRx websites and their page associated with an IntelligentRx drug name.",
        }
    },
    {
        path: '/reimburse-me',
        name: 'ReimburseMe',
        component: ReimburseMe,
        meta: {
            title: "Medical Insurance Reimbursement | IntelligentRx",
            description: "Learn how to be reimbursed for medical expenses which you paid for out of pocket"
        }
    },
    {
        path: '/prescription-transfer',
        name: 'PrescriptionTransfer',
        component: PrescriptionTransfer,
        meta: {
            title: "Prescription Transfer | IntelligentRx",
            description: "Transfer your prescription using this page",
        }
    },
    {
        path: '/:drugName',
        name: 'Discount Search | IntelligentRx',
        component: SearchDrugPage,
        meta: {
            title: "Discount Search | IntelligentRx",
            description: "Search for discounts at pharmacies near you"
        }
    },
    {
        // path: '*',
        path: "/:catchAll(.*)",
        name: 'not-found-page',
        component: NotFoundPage,
    }
];

const router = createRouter({
    history: createWebHistory(),
    //@ts-expect-error
    routes,
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    scrollBehavior(to, from, savedPosition): ScrollToOptions {
        // savedPosition is only available for popstate navigations.
        if (savedPosition != null) return savedPosition

        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        //@ts-ignore
        const emptyPosition: any = {};
        if (to.params.savePosition != null) return emptyPosition

        // scroll to anchor by returning the selector
        if (to.hash != null && to.hash !== "") {
            const position: any = { selector: to.hash }

            return position
        }

        // scroll to top by default
        const p: any = { left: 0, top: 0 };
        return p;
    }
})

router.beforeEach((to, from) => {
    if (from.fullPath === '/' || from.fullPath === '/after-auth')
        return;
    cookiemixin.methods.setCookie("irx-last-page", from.fullPath);
    return true;
});

router.afterEach((to) => {
    try {
        let routeIsForADrug = true;
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            if (to.path == route.path) {
                routeIsForADrug = false;
                break;
            }
        }
        if (!routeIsForADrug && !to.fullPath.startsWith('/admin') && to.meta != null && to.meta.title !== '') {
            //@ts-expect-error
            document.title = to.meta.title;
            const d = document.querySelector('head meta[name="description"]');
            if (d == null) {
                return;
            }
            //@ts-expect-error
            d.setAttribute('content', to.meta.description);
        }
    } catch (_) {
        // pass
    }
});

export default router
