<template>
    <div class="typeahead" :class="outerClass">
        <!-- @vue-expect-error -->
        <input type="search" class="typeahead-input" :class="inputClass" :placeholder="placeHolder" aria-autocomplete="list"
            aria-label="Type medication name here to search for a prescription discount coupon" autocomplete="off"
            spellcheck="false" v-bind:value="userQuery" v-on:input="updateUserQuery($event)" @keyup="keyup" @keydown.enter="hit(-1)" @keydown.esc="reset" @blur="reset" ref="typeaheadinput" />

        <div class="autocomplete-overlay" @click="focusInput">
            <div class="autocomplete-overlay-black">{{ autocompleteBlack }}</div><div class="autocomplete-overlay-grey">{{ autocompleteGrey }}</div>
        </div>

        <ul v-show="hasResults && !isEmpty" class="text-left autocomplete-results" style="">
            <li v-for="(item, index) in results" :key="index" class="form-inform" @mousedown="
            hit(index);
            ">
                <font-awesome-icon class="d-inline-block" icon="fa fa-search" size="1x"
                    aria-hidden="true"></font-awesome-icon>&nbsp;&nbsp;{{ item }}
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { errormixin } from "../../mixins/errormixin";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";
import { searchDrugNames } from "../../mixins/searchDrugNames";
import { useAutocompleteStore } from '../../stores/autocomplete';
import { mapWritableState } from 'pinia'
export function debounce(func: any, wait: number, immediate: boolean) {
    var timeout: number | null;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
export default {
    name: "typeahead-control",
    mixins: [mixinDetectingMobile, searchDrugNames, errormixin],
    data() {
        const d: {
            limit: number
            minChars: number
            // The user's query is what the user has typed. It is NOT the value of the <input>
            userQuery: string
            greyQuery: string
            loading: boolean
            showList: boolean
            selectFirst: boolean
            queryParamName: string
            updatedFromList: boolean
        } = {
            limit: 10,
            minChars: 1,
            userQuery: "",
            greyQuery: "",
            loading: false,
            showList: false,
            selectFirst: false,
            queryParamName: "q",
            updatedFromList: false,
        };
        return d;
    },

    props: {
        inputData: { type: String, default: "" },
        // searchNow can be called to immediately search with the inputData.
        searchNow: { type: Function },
        inputClass: { type: String, default: "" },
        outerClass: { type: String, default: "" },
    },
    emits: ['searchNow'],

    computed: {
        ...mapWritableState(useAutocompleteStore, ['results', 'oldResults', 'selectedDrugName']),

        autocompleteBlack() {
            let r = this.greyQuery.length == this.userQuery.length && this.userQuery.length > 0 ? this.userQuery : this.userQuery;
            return r;
        },

        autocompleteGrey() {
            // 2800 is braile pattern blank. Without this, the grey bit has no height and the autocompleteBlack becomes unaligned
            let r = this.greyQuery.length == this.userQuery.length && this.userQuery.length > 0 ? "\u{2800}" : this.greyQuery.substring(this.userQuery.length);
            console.log(r);
            return r;
        },

        hasResults() {
            return this.results.length > 0;
        },

        isEmpty() {
            return !this.userQuery;
        },

        isDirty() {
            return !!this.userQuery;
        },

        placeHolder() {
            return this.isMobile()
                ? "🔎︎   Type medication name here"
                : "🔎︎   Type medication name here";
        },
    },

    methods: {
        updateUserQuery(e) {
            // This will trigger update() after debounce runs
            // console.debug(e.target.value);
            this.userQuery = e.target.value;
        },
        focusInput() {
            this.$refs.typeaheadinput.focus();
            // let toFocus: HTMLInputElement | nu`ll = document.querySelector("[placeholder^=🔎︎]");
            // if (toFocus != null)
            //     toFocus.focus();`
        },
        // This is triggered when the user types anything
        async update() {
            this.cancel();

            if (this.minChars && this.userQuery.length < this.minChars) {
                this.greyQuery = '';
                return;
            }

            this.showList = true;
            this.loading = true;

            var arr = await this.queryDrugName(this.userQuery, this.limit);
            //   console.debug(arr);
            this.results = arr;
            if (this.results.length > 0) {
                this.greyQuery = this.results[0];
                // console.debug(`this.greyQuery updated to ${this.greyQuery}`);
            }
            this.loading = false;
        },

        cancel() {
            // used to 'cancel' previous searches
        },

        reset() {
            this.oldResults = [
                ...this.results,
            ];
            this.results = [];

            // Bug / edge case:
            // 1. type in something like "ator"
            // 2. Click on "Search". This activates blur in this component and onclick in the parent search-bar.vue
            // 3. Blur is activated in this component. This method - reset() - is called
            // 4. greyQuery WAS being set. It is now not. Let's look at what happens now: it's not immediately set
            // 5. userQuery watch is activated first. It starts a 200ms timer. After that timer finishes, greyQuery will be set to "". That triggers another watch, which updates this.selectedDrugName
            // 6. [search-bar.vue] onclick runs. this.selectedDrugName is read. The 200ms timer hasn't finished yet, so this.$router.push, which uses this.selectedDrugName's value, is using the value which was from before it is set to "" by the 200ms timer indirectly
            // 7. Navigation starts to the new URL
            // 8. Hypothetically, the value of greyQuery is set to "" and the value updates
            // Result: Everything works.
            // If we set greyQuery now then in step (6) the value of this.selectedDrugName is "" when the search-bar.vue code runs and it doesn't work
            // It's a race. IE this should be fixed....
            // this.greyQuery = "";

            this.userQuery = "";
            this.loading = false;
            this.updatedFromList = false;
        },

        hit(index: number) {
            if (index != null && index >= 0) {
                this.selectedDrugName = this.results[index];
                // console.log(this.results[index]);
            }
            this.$emit("searchNow");
        },

        hideList() {
            this.results = [];
        },
    },

    watch: {
        //@ts-expect-error
        userQuery: debounce(function () {
            if (this.updatedFromList) return;
            this.update();
        }, 200),
        // ?Needing a watch to do this seems like bad architecture to me? ?But this is the easiest way to do it?
        greyQuery: function(newVal, oldVal) {
            this.selectedDrugName = newVal;
        }
    },

    // ugh this should be easy but it's not.
    //   mounted() {
    //     let d = this.$route.query.drugName;
    //     if (d != null && d.length > 2) {
    //       this.query = d;
    //       this.hideList();
    //     }
    //   },
    mounted() {
        this.reset();
    },
};
</script>



<style scoped>
.typeahead {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.typeahead-input:focus {
    outline: 0;
}

.typeahead-input::-ms-clear {
    display: none;
}

ul {
    position: absolute;
    left: 0;
    padding: 0;
    margin-top: 8px;
    min-width: 50%;
    background-color: #fff;
    list-style: none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

li {
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

li:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

li:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 0;
}

span {
    display: block;
}

.autocomplete-results {
    margin-top: 17px;
    margin-left: -18px;
    width: calc(100% + 18px);
    /* only works at my large screen width */
}

.typeahead-input {
    width: 100%;
    height: 22px;
    border: 0;
    position: relative;
    /* color: rgba(0, 0, 0, 1) !important; */
    color: rgba(0, 0, 0, 0.001) !important;
    caret-color: black;
    background-color: transparent !important;
}

.autocomplete-overlay {
    color: grey;
    position: relative;
    padding: 0;
    height: 22px;
    width: 100%;
    margin-top: -21.5px;
    margin-left: 2px !important;
    margin-right: 0;
    margin-bottom: 0;
    background-color: transparent;
    z-index: 1;
    letter-spacing: 0px;
    font: var(--body-font-weight) 16px / 19px var(--body-font-family);

    cursor: text;
}
.autocomplete-overlay-black {
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 100%;
    color: rgba(0, 0, 0, 1) !important;
    /* color: transparent; */
}
.autocomplete-overlay-grey {
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 100%;
}

@media (max-width: 992px) {
    .autocomplete-results {
        margin-left: -0px;
        width: calc(100% + 0px);
    }
    .autocomplete-overlay {
        margin-top: -21.5px;
    }
}
</style>
