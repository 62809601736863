<template>
    <div v-if="!isMobile()" class="row head-image-overlay grey-background-color dark-text-color">
        <div class="col col-12 col-lg-6 d-flex align-items-center pl-4 pr-5 pt-4"
            style="padding-left: 120px !important">
            <div class="px-3">
                <h1 class="small pb-2" style="letter-spacing: 2px; text-transform: uppercase">
                    {{ pageDescription }}
                </h1>
                <slot name="bannerHTMLLarge"></slot>
            </div>
        </div>
        <div class="col col-12 col-lg-6">
            <div class="d-flex justify-content-right">
                <img src="../assets/family-smiling-1250-900.jpg" class="head-image image-mask" alt="A smiling family" />
            </div>
        </div>
    </div>
    <div v-else class="row d-lg-none d-flex grey-background-color dark-text-color">
        <div class="col col-12 pl-4 pr-5 pt-4">
            <div class="">
                <h1 class="small pb-2" style="letter-spacing: 2px; text-transform: capitalize">
                    {{ pageDescription }}
                </h1>
                <slot name="bannerHTMLMobile"></slot>
            </div>
        </div>
        <div class="col col-12 px-0">
            <img :src="require(`@/assets/${image}`)" class="head-image image-mask mh-100" :alt="imgAlt" />
        </div>
    </div>
</template>

<script lang="ts">
import { accessibilityMixin } from '../mixins/accessibilityMixin';
import { mixinDetectingMobile } from "../mixins/mixinDetectingMobile";
export default {
    name: "banner-pitch",
    mixins: [mixinDetectingMobile, accessibilityMixin],
    props: {
        // family-smiling-1250-900.jpg
        image: String,
        pageDescription: String,
    },
    data() {
        return {
            imgAlt: '',
        };
    },
    mounted() {
        this.imgAlt = this.getImageAltFromImageURL(this.image);
    }
};
</script>
<style scoped>
:root {
    --down-color: var(--background-color);
    --off-blue-darker-text-color: var(--text-color-on-dark-background);
}

.dark-text-color {
    color: var(--text-color-on-dark-background);
}

.grey-background-color {
    background-color: var(--background-color-dark);
}

.head-image-overlay {
    height: fit-content;
    border-style: hidden;
    border-radius: 10px;
    min-height: 600px;
    max-height: 600px;
}

.head-image {
    min-height: 600px;
    max-height: 600px;
}

.image-mask {
    mask-image: linear-gradient(0.25turn,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 1) 33%,
            rgba(0, 0, 0, 1));
    mask-size: cover;
    mask-repeat: inherit;
    mask-position: 0% 100%;
}

@keyframes drop {
    0% {
        transform: translateY(-100px) scaleY(0.9);
        opacity: 0;
    }

    100% {
        transform: translateY(0px) scaleY(1);
        opacity: 1;
    }
}

@media (max-width: 992px) {
    .head-image-overlay {
        min-height: 200px;
        max-height: 200px;
    }

    .head-image {
        min-height: initial;
        max-height: initial;
        max-width: 100vw;
    }

    .image-mask {
        mask-image: linear-gradient(0.5turn,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 1) 20%,
                rgba(0, 0, 0, 1));
        mask-size: cover;
        mask-repeat: inherit;
        mask-position: 0% 100%;
    }
}
</style>