<template>
  <div class="d-flex align-items-stretch try-the-app py-3 container-fluid">
    <div class="row d-flex align-items-center">
        <div class="col-md-2 offset-md-1 align-self-start">
            <img src="../../assets/try-app-tablets-upper-left.webp" class="rounded-circle try-app-tablets-img" alt="decoration of tablets" />
        </div>
        <div class="col col-12 col-md-6">
        <div class="row col col-12">
            <h2 class="col col-12" style="color: var(--primary-color); font-weight:bold;">Try the IntelligentRx App for Free!</h2>
        </div>
        <div class="row col col-12">
            <p class="col col-12 text-secondary">Download our free app and start saving on your prescription medications today!</p>
        </div>
        <div class="row justify-content-center col col-12">
            <div class="col col-6 col-md-5 col-lg-4">
                <a href="https://play.google.com/store/apps/details?id=com.intelligentrx" class="w-50 h-100">
                    <img src="../../assets/get-it-google-play.webp" class="w-100 h-100 rounded" alt="Play Store (Android) button" />
                </a>
            </div>
            <div class="col col-6 col-md-5 col-lg-4">
                <a href="https://apps.apple.com/us/app/intelligentrx/id1611042374" class="w-50 h-100">
                    <img src="../../assets/apple-app-link.webp" class="w-100 h-100 rounded" alt="Apple Store button" />
                </a>
            </div>
        </div>
        </div>
        <div class="col-md-2 align-self-end">
            <img src="../../assets/try-app-tablets-lower-right.webp" class="rounded-circle try-app-tablets-img" alt="decoration of tablets" />
        </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
    name: "try-the-app",
  mixins: [],
  components: {
  },
  props: {
    pinHeader: { type: Boolean, default: true },
  },
  computed: {
    },
  methods: {

  }
}
</script>
<style scoped>
.try-app-tablets-img {
    height: 200px;
    width: 200px;
}
.try-the-app {
    min-height: 500px;
}

@media (max-width: 1200px) {
.try-app-tablets-img {
    height: 150px;
    width: 150px;
}

}

@media (max-width: 992px) {
    .try-app-tablets-img {
        display: none;
    }
.try-the-app {
    min-height: 400px;
}
}
</style>
