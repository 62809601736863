import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08dd317e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "labeled-dropdown-wrapper" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "dropdown-wrapper" }
const _hoisted_4 = ["id", "value", "title", "name", "disabled"]
const _hoisted_5 = {
  key: 0,
  value: null
}
const _hoisted_6 = ["value", "disabled"]
const _hoisted_7 = ["id", "value", "title", "name", "disabled"]
const _hoisted_8 = {
  key: 0,
  value: null
}
const _hoisted_9 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["label-wrapper", $props.labelClass]),
      for: $data.selectId
    }, _toDisplayString($props.labelText), 11, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      ($props.optionValueKey)
        ? (_openBlock(), _createElementBlock("select", {
            key: 0,
            class: _normalizeClass(["select-main text-truncate custom-select", $props.fieldClass]),
            id: $data.selectId,
            value: $props.modelValue,
            title: $props.modelValue == null ? '' : $options.buildOptionLabel($props.modelValue),
            name: $props.name,
            disabled: $options.disableDropDown,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.change && $options.change(...args)))
          }, [
            (!$props.noDefaultOption)
              ? (_openBlock(), _createElementBlock("option", _hoisted_5, _toDisplayString($props.optionDefaultLabel), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, index) => {
              return (_openBlock(), _createElementBlock("option", {
                class: "text-truncate",
                key: index,
                value: option[$props.optionValueKey],
                disabled: $options.isOptionDisabled()
              }, _toDisplayString($options.buildOptionLabel(option)), 9, _hoisted_6))
            }), 128))
          ], 42, _hoisted_4))
        : (_openBlock(), _createElementBlock("select", {
            key: 1,
            class: _normalizeClass(["select-main text-truncate custom-select", $props.fieldClass]),
            id: $data.selectId,
            value: $props.modelValue,
            title: $props.modelValue == null ? '' : $options.buildOptionLabel($props.modelValue),
            name: $props.name,
            disabled: $options.disableDropDown,
            onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.change && $options.change(...args)))
          }, [
            (!$props.noDefaultOption)
              ? (_openBlock(), _createElementBlock("option", _hoisted_8, _toDisplayString($props.optionDefaultLabel), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, index) => {
              return (_openBlock(), _createElementBlock("option", {
                class: "text-truncate",
                key: index,
                value: option,
                disabled: $options.isOptionDisabled()
              }, _toDisplayString($options.buildOptionLabel(option)), 9, _hoisted_9))
            }), 128))
          ], 42, _hoisted_7))
    ])
  ]))
}