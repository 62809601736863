import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/search.svg'


const _withScopeId = n => (_pushScopeId("data-v-6fc6e0f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { class: "input-group input-wrapper align-items-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "mobile-search",
  src: _imports_0,
  alt: "search icon"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 1,
  class: "in-nav"
}
const _hoisted_7 = { class: "input-group input-wrapper align-items-center" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  class: "mobile-search",
  src: _imports_0,
  alt: "search icon"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 2,
  class: "search-section container-fluid"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3" }
const _hoisted_14 = { class: "input-group mb-3 input-wrapper align-items-center rounded" }
const _hoisted_15 = {
  key: 0,
  class: "mobile-search",
  src: _imports_0,
  alt: "search icon"
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  key: 0,
  class: "alert alert-warning",
  role: "alert"
}
const _hoisted_18 = {
  key: 0,
  class: "row welcome-subheader p-2"
}
const _hoisted_19 = { class: "col col-sm-12 col-md-4 offset-md-4 text-light" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = {
  key: 1,
  class: "row"
}
const _hoisted_23 = { class: "col col-sm-12 col-md-8 offset-md-2 d-flex justify-content-center" }
const _hoisted_24 = { class: "list-inline" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_typeahead = _resolveComponent("typeahead")!
  const _component_prc_button = _resolveComponent("prc-button")!

  return ($props.inNav && _ctx.isMobile())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($data.showSpinner)
          ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_typeahead, {
            onSearchNow: $options.searchDrug,
            inputClass: "irx-input-fixed-height text-left search-input-class",
            outerClass: "form-control border-0 search-outer-class my-1"
          }, null, 8, ["onSearchNow"]),
          (_ctx.selectedDrugName != null && _ctx.selectedDrugName.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_prc_button, {
                  type: "emphasis",
                  onClick: $options.searchDrug,
                  ariaLabel: "Search"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isMobile())
                      ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                      : _createCommentVNode("", true),
                    (!_ctx.isMobile())
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Search"))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : ($props.inNav)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          ($data.showSpinner)
            ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_typeahead, {
              onSearchNow: $options.searchDrug,
              inputClass: "irx-input-fixed-height text-left search-input-class",
              outerClass: "form-control border-0 search-outer-class my-1"
            }, null, 8, ["onSearchNow"]),
            (_ctx.selectedDrugName != null && _ctx.selectedDrugName.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_prc_button, {
                    type: "emphasis",
                    onClick: $options.searchDrug,
                    ariaLabel: "Search"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isMobile())
                        ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                        : _createCommentVNode("", true),
                      (!_ctx.isMobile())
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Search"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            ($data.showSpinner)
              ? (_openBlock(), _createBlock(_component_loading_spinner, { key: 0 }))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_typeahead, {
                  onSearchNow: $options.searchDrug,
                  inputClass: "irx-input-fixed-height text-left search-input-class",
                  outerClass: "form-control border-0 search-outer-class my-1"
                }, null, 8, ["onSearchNow"]),
                _createElementVNode("div", null, [
                  _createVNode(_component_prc_button, {
                    type: "emphasis",
                    onClick: $options.searchDrug,
                    ariaLabel: "Search"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isMobile())
                        ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                        : _createCommentVNode("", true),
                      (!_ctx.isMobile())
                        ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Search"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              ($data.searchDrugFailed)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, " Please enter a valid drug name "))
                : _createCommentVNode("", true)
            ])
          ]),
          ($props.showRecentSearchesAndOtherInfo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  ($options.showRecentSearches)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, "Recent Searches"))
                    : _createCommentVNode("", true),
                  (!$options.showRecentSearches)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Popular Searches"))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          ($props.showRecentSearchesAndOtherInfo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("ul", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.showRecentSearches
                        ? $options.recentSearches
                        : $options.popularSearches, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: "list-inline-item p-2",
                        key: item,
                        onClick: ($event: any) => ($options.searchThis(item))
                      }, [
                        _createElementVNode("a", {
                          href: "#",
                          onClick: ($event: any) => ($options.goToHref(item)),
                          class: "btn btn-lg px-5 py-3",
                          style: {"background-color":"var(--background-color-dark-emphasis)","color":"white","text-transform":"capitalize","border":"none"}
                        }, _toDisplayString(item), 9, _hoisted_26)
                      ], 8, _hoisted_25))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
}