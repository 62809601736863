<template>
    <a v-if="returnURL != null && returnURL.length > 0" :href="decodeURIComponent(returnURL)"
        class="text-left"><font-awesome-icon icon="fa fa-arrow-left"
            aria-hidden="true"></font-awesome-icon>&nbsp;&nbsp;{{ backText }}</a>
</template>
<script lang="ts">
export default {
    name: 'back-to-previous-page',
    props: {
        returnURL: String,
        backText: String,
    },
}
</script>