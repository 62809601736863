<template>
    <div style="background-color: var(--background-color-dark);" class="height-less-seventy-two">
        <div class="search-section-background" style="">
            <header-section v-bind:pinHeader="false" />
            <div class="welcome-wrapper container-fluid">
                <div class="row">
                    <h1 class="col-sm-12 col-md-8 offset-md-2 welcome-header text-light">
                        Don't Overpay On Your Prescriptions
                    </h1>
                </div>
            </div>
            <div class="search-wrapper">
                <SearchBar :showPromotionalPages="false" />
            </div>
        </div>
    </div>
</template>
  
<script lang="ts">
import HeaderSection from "./layout/header-section.vue";
import SearchBar from "./shared/search-bar.vue";

export default {
    name: "p-page",
    props: {
        msg: String,
    },
    components: {
        SearchBar,
        HeaderSection,
    },
    methods: {
    },
    mounted() {
        const pharmacyNameFilter = this.$route.query['p'];
        const zipCode = this.$route.query['z'];
        if (pharmacyNameFilter == null || zipCode == null || pharmacyNameFilter === '' || zipCode === '') {
            // A Pharmacy Name and Zip Code are required for this service to work
            this.$router.push('/');
        }
        
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.height-less-seventy-two {
    min-height: calc(100vh - 72px);
}
.search-section-background {
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-origin: padding-box;
}

.welcome-wrapper {
    text-align: center;
    padding-top: 45px;
}

.search-wrapper {
    margin-top: 40px;
}

.welcome-header {
    text-align: center;
    font-family: var(--heading-font-family);
    font-weight: 1000;
    font-size: 42px;
    line-height: 68px;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
}

@media (max-width: 992px) {

    /* absolute path should not be translated by webpack. This resource is preloaded from index.html. */
    .search-wrapper {
        margin-left: 10px;
        margin-right: 10px;
    }

    .welcome-wrapper {
        margin-left: 10px;
        margin-right: 10px;
    }

    .welcome-header {
        font-family: var(--heading-font-family);
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 20px;
    }
}

@media (max-width: 1500px) and (min-width: 992px) {
    /* absolute path should not be translated by webpack. This resource is preloaded from index.html. */
}
</style>
  
  