<template>
    <div :class="getOverallClass">
        <div v-if="isMobile()" class="container">
            <h2 class="four-stats-overarching-title h1">{{ title }}</h2>
            <div v-for="(statWrapper, i) in fourStats" :key="statWrapper.title" class="row">
                <div class="col col-12" style="cursor: pointer" @click="switchStat(i)">
                    <h3 class="h1">{{ statWrapper.title }}</h3>
                    <hr v-if="statWrapper == selectedStatWrapper" class="three-detailed-pitch-hr w-100" />
                    <hr v-else class="three-detailed-pitch-hr w-100" />
                </div>
                <div class="col col-12" v-if="statWrapper == selectedStatWrapper">
                    <div class="row">
                        <div class="col col-6 col-lg-3"
                            v-for="(stat, statIndex) in selectedStatWrapper.stats" :key="statIndex">
                            <h3 class="animate-counting-intersection-target h3">{{ stat.stat }}</h3>
                            <p>{{ stat.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="container">
            <h2 class="four-stats-overarching-title display-4">{{ title }}</h2>
            <div class="row pb-5">
                <div class="col col-12 col-lg-4">
                    <div class="row stat-row" @click="switchStat(index)" style="cursor: pointer"
                        v-for="(statWrapper, index) in fourStats" :key="index">
                        <div class="
                            col col-4 col-lg-2
                            align-right
                            justify-content-center
                            d-flex
                        ">
                            <img :src="require(`@/assets/${statWrapper.iconInAssets}`)" class="stat-wrapper-icon"
                                :class="getIconClass" :alt="statWrapper.iconAltText" />
                        </div>
                        <div class="col col-8 col-lg-10 align-items-center d-flex">
                            <h3 class="h1 float-left">{{ statWrapper.title }}</h3>
                        </div>
                        <hr v-if="statWrapper == selectedStatWrapper"
                            class="three-detailed-pitch-hr w-100 text-primary" />
                        <hr v-else class="three-detailed-pitch-hr w-100" />
                    </div>
                </div>
                <div class="col col-12 col-lg-8 text-center">
                    <img :src="require(`@/assets/${selectedStatWrapper.pitchImageInAssets}`)" height="400"  :alt="selectedStatWrapper.pitchImageAltText"
                        class="stat-image" />
                </div>
            </div>
            <div class="row">
                <div class="col col-6 col-lg-3" v-for="(stat, statIndex) in selectedStatWrapper.stats"
                    :key="statIndex">
                    <h3 class="animate-counting-intersection-target h1">{{ stat.stat }}</h3>
                    <p class="pitch-font">{{ stat.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { PropType } from "vue";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";
export default {
    name: "four-stats-pitch",
    props: {
        title: String,
        fourStats: Array as PropType<any[]>,
        // fourStats: [
        //   {
        //     iconInAssets: String,
        //     pitchImageInAssets: String,
        //     title: String,
        //     stats: [
        //       {
        //         stat: String,
        //         description: String,
        //       }
        //     ]
        //   }
        // ]
        overallClass: String,
        iconClass: String,
    },
    mixins: [mixinDetectingMobile],
    data() {
        return {
            haveObserved: false,
            selectedStatWrapper: this.fourStats[0],
            statsObserver: IntersectionObserver,
        };
    },
    methods: {
        switchStat(pitchIndex: number) {
            this.haveObserved = true;
            this.selectedStatWrapper = this.fourStats[pitchIndex];
        },
        onStatsObserved(entries: any[]) {
            entries.forEach(({ target, isIntersecting }) => {
                if (!isIntersecting) return;

                if (this.haveObserved) return;
                this.haveObserved = true;

                this.statsObserver.unobserve(target);
                const animationDuration = 1500;
                const frameDuration = 1000 / 60;
                const totalFrames = Math.round(animationDuration / frameDuration);
                const easeOutQuad = (t: number) => t * (2 - t);
                for (let i = 0; i < this.selectedStatWrapper.stats.length; i++) {
                    let currentValue = this.selectedStatWrapper.stats[i].stat;
                    var currentValueBeforeNonIntChar = currentValue;
                    var currentValueAfterInts = "";
                    for (let ii = 0; ii < currentValue.length; ii++) {
                        if (isNaN(currentValue[ii])) {
                            // is nan?
                            currentValueBeforeNonIntChar = currentValue.substring(0, ii);
                            currentValueAfterInts = currentValue.substring(ii);
                            break;
                        }
                    }
                    if (currentValueBeforeNonIntChar == null || currentValueBeforeNonIntChar == "")
                        continue;
                    let frame = 0;
                    const countTo = parseInt(currentValueBeforeNonIntChar, 10);
                    const counter = setInterval(
                        (targetIndex: number, currentValueAfterIntsInternal: any) => {
                            frame++;
                            // Calculate our progress as a value between 0 and 1
                            // Pass that value to our easing function to get our
                            // progress on a curve
                            const progress = easeOutQuad(frame / totalFrames);
                            // Use the progress value to calculate the current count
                            const currentCount = Math.round(countTo * progress);

                            // If the current count has changed, update the element
                            this.selectedStatWrapper.stats[targetIndex].stat =
                                currentCount + currentValueAfterIntsInternal;
                            this.$forceUpdate()

                            // If we’ve reached our last frame, stop the animation
                            if (frame === totalFrames) {
                                clearInterval(counter);
                            }
                        },
                        frameDuration,
                        i,
                        currentValueAfterInts
                    );
                }
            });
        },
    },
    created() {
        this.statsObserver = new IntersectionObserver(this.onStatsObserved, {
            root: this.$el,
            threshold: 1.0,
        });
    },
    mounted() {
        var targets = document.getElementsByClassName(
            "animate-counting-intersection-target"
        );
        for (let i = 0; i < targets.length; i++) {
            this.statsObserver.observe(targets[i]);
            targets[i].classList.remove("animate-counting-intersection-target");
        }
    },
    beforeDestroy() {
        this.statsObserver.disconnect();
    },
    computed: {
        getOverallClass() {
            if (this.overallClass != null && this.overallClass.length > 0)
                return "row " + this.overallClass;
            return "row four-stats-pitch-row";
        },
        getIconClass() {
            if (this.iconClass != null && this.iconClass != '') {
                return this.iconClass;
            }
            return '';
        },
    },
};
</script>
<style scoped>
.four-stats-pitch-row {
    background-color: var(--background-color-dark-emphasis);
    padding-top: 120px;
    padding-bottom: 100px;
}

.four-stats-overarching-title {
    padding-bottom: 40px;
}

.stat-wrapper-icon {
    max-width: 40px;
    max-height: 40px;
}

.stat-image {
    max-height: 400px;
    border-radius: 20px;
}

.stat-row {
    padding-top: 20px;
    padding-bottom: 40px;
}

.three-detailed-pitch-hr {
    border-top: 2px;
    color: grey;
    border: solid;
}

@media (max-width: 992px) {
    .four-stats-pitch-row {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .four-stats-overarching-title {
        padding-bottom: 20px;
    }

    .stat-wrapper-icon {
        max-width: 20px;
        max-height: 20px;
    }

    .stat-image {
        max-height: 200px;
        border-radius: 10px;
    }

    .stat-row {
        padding-top: 10px;
        padding-bottom: 20px;
    }
}
</style>