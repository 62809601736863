import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return ($props.returnURL != null && $props.returnURL.length > 0)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: decodeURIComponent($props.returnURL),
        class: "text-left"
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: "fa fa-arrow-left",
          "aria-hidden": "true"
        }),
        _createTextVNode("  " + _toDisplayString($props.backText), 1)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}