<template>
    <div class="row dropdowns-row">
        <div class="col-md-3 col-sm-12">
            <labeled-dropdown v-model="selectedLabelValue" name="labels" :options="store.getLabels"
                option-value-key="drugName" option-label-selector="drugName" no-default-option
                field-class="dropdown-light irx-input" label-text="Label"
                label-class="font-label text-left" @input="labelChanged" :disabled="shouldDisableLabels" />
        </div>
        <div class="col-md-3 col-sm-12">
            <labeled-dropdown v-model="selectedFormValue" name="forms" :options="store.getForms"
                option-value-key="form" option-label-selector="form" no-default-option
                field-class="dropdown-light irx-input" label-text="Form"
                label-class="font-label text-left" :disabled="shouldDisableForms" @input="formChanged" />
        </div>
        <div class="col-md-3 col-sm-12">
            <labeled-dropdown v-model="selectedDosageValue" name="dosages" :options="store.getDosages"
                option-value-key="strength" option-label-selector="strength" no-default-option
                field-class="dropdown-light irx-input" label-text="Dosage"
                label-class="font-label text-left" :disabled="shouldDisableDosages"
                @input="dosageChanged" />
        </div>
        <div class="col-md-3 col-sm-12">
            <div v-if="this.isPackaged">
                <labeled-dropdown v-model="selectedQuantityValue" name="quantities" :options="store.getQuantities"
                    option-value-key="quantity" option-label-selector="quantityToDisplay" no-default-option
                    field-class="dropdown-light irx-input" label-text="Quantity"
                    label-class="font-label text-left" :disabled="shouldDisableQuantities"
                    @input="quantityChanged" />
            </div>
            <div v-else>
                <labeled-dropdown v-model="selectedQuantityValue" name="quantities" :options="store.getQuantities"
                    option-value-key="quantity" option-label-selector="quantityToDisplay" no-default-option
                    field-class="dropdown-light irx-input" label-text="Quantity"
                    label-class="font-label text-left" :disabled="shouldDisableQuantities"
                    @input="quantityChanged" />
            </div>
        </div>
        <div v-if="selectedQuantityValue == 'Custom' && this.isPackaged"
            class="col col-md-6 col-lg-3 col-12">
            <prc-input v-model="customQuantityPackaged" step="any" type="number" placeholder="Quantity"
                :disabled="selectedQuantityValue != 'Custom'" errorMessage="You must enter a quantity"
                :state="customQuantityPackaged != null" name="customQuantityPackaged"
                label="Custom Quantity" :enterMethod="quantityChanged" @input="quantityChanged" />
        </div>
        <div v-if="selectedQuantityValue == 'Custom' && !this.isPackaged"
            class="col col-md-6 col-lg-3 col-12">
            <prc-input v-model="customQuantity" step="any" type="number" placeholder="Quantity"
                :disabled="selectedQuantityValue != 'Custom'" errorMessage="You must enter a quantity"
                :state="customQuantity != null" name="customQuantity" label="Custom Quantity"
                :enterMethod="quantityChanged" @input="quantityChanged" />
        </div>
    </div>
</template>

<script lang="ts">
import PrcInput from "../controls/prc-input.vue";``
import LabeledDropdown from "../controls/labeled-dropdown.vue";
import { useSearchDrugOptionsStore } from '../../stores/search-drug-options';
import { useDrugDetailsFromServerStore } from '../../stores/drug-details-from-server';
import { mapWritableState } from 'pinia';

export default {
    name: 'select-label-form-dosage-qty',
    mixins: [
    ],
    components: {
        LabeledDropdown,
        PrcInput,
    },
    props: {
    },
    data() {
        let d: {
            store: any
        } = {
            store: useDrugDetailsFromServerStore()
        };
        return d;
    },
    computed: {
        ...mapWritableState(useSearchDrugOptionsStore, ['selectedLabelValue', 'selectedFormValue', 'selectedDosageValue', 'selectedQuantityValue', 'customQuantity', 'customQuantityPackaged', 'labelChanged', 'formChanged', 'dosageChanged', 'quantityChanged']),

        ...mapWritableState(useDrugDetailsFromServerStore, ['drugDetails']),

        isPackaged() {
            const store = useDrugDetailsFromServerStore();
            return (
                store.getQuantities != null &&
                store.getQuantities.length > 0 &&
                store.getQuantities[0]?.quantityToDisplay != null &&
                store.getQuantities[0].quantityToDisplay.substring(0, 2) == "1 "
            );
        },

        selectedLabel() {
            const store = useDrugDetailsFromServerStore();
            return store.getLabels?.find(
                (label) =>
                    label.isSelected &&
                    label.drugName.toLowerCase() === this.drugName.toLowerCase()
            );
        },

        selectedForm() {
            const store = useDrugDetailsFromServerStore();
            return store.getForms?.find(
                (form) => form.form === this.selectedFormValue
            );
        },

        selectedDosage() {
            const store = useDrugDetailsFromServerStore();
            return store.getDosages?.find(
                (dosage) => dosage.strength === this.selectedDosageValue
            );
        },

        selectedQuantity() {
            const store = useDrugDetailsFromServerStore();
            return store.getQuantities?.find(
                (quantity) => quantity.quantity === this.selectedQuantityValue
            );
        },

        shouldDisableLabels() {
            const store = useDrugDetailsFromServerStore();
            return store.getLabels?.length <= 1;
        },

        shouldDisableForms() {
            const store = useDrugDetailsFromServerStore();
            return store.getForms?.length <= 1;
        },

        shouldDisableDosages() {
            const store = useDrugDetailsFromServerStore();
            return store.getDosages?.length <= 1;
        },

        shouldDisableQuantities() {
            const store = useDrugDetailsFromServerStore();
            return store.getQuantities?.length <= 1;
        },
    },
    methods: {
    },
    mounted() {
    }
}
</script>

<style scoped>
.input-height {
    height: 48px;
}

.labeled-dropdown-wrapper>>>.dropdown-light {
    padding-left: 20px;
    text-align: left;
    letter-spacing: 0.4px;
}

@media (max-width: 992px) {
    .dropdowns-row>div {
        margin-bottom: 20px;
    }

    .search-button {
        margin-top: 10px;
    }
}
</style>
