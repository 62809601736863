<template>
    <div class="container-fluid">
        <banner-pitch :image="pitches.banner.imageSrc" pageDescription="">
            <template v-slot:bannerHTMLLarge>
                <h1 class="">Improve Outcomes</h1>
                <h1 class="">Reduce Costs</h1>
                <br />
                <p class="pitch-font">IntelligentRx's clinically active pharmacy program</p>
                <router-link to="/request-a-demo" class="btn btn-lg"
                    style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">
                    Learn More
                    <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                </router-link>
            </template>
            <template v-slot:bannerHTMLMobile>
                <h1 class="">Improve Outcomes</h1>
                <h1 class="">Reduce Costs</h1>
                <br />
                <p class="pitch-font">IntelligentRx's clinically active pharmacy program</p>
                <router-link to="/request-a-demo" class="btn btn-lg my-4"
                    style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">Request
                    a Demo
                    <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                </router-link>
            </template>
        </banner-pitch>

        <highlight-overlay :title="pitches.highlightOverlayThomas.title" :text="pitches.highlightOverlayThomas.text"
            :imageSrc="pitches.highlightOverlayThomas.imageSrc">
        </highlight-overlay>

        <two-column-pitch title="Good for Patients" :pitches="pitches.outcomesAndCosts" :overallClass="'pitch-background-default'">
        </two-column-pitch>

        <two-column-pitch title="Good for Doctors" :pitches="pitches.activeAndClinical" :overallClass="'pitch-background-default'">
        </two-column-pitch>

        <two-column-pitch title="Easy to Start" :pitches="pitches.cooperativeAndPharmacists" :overallClass="'pitch-background-default'">
        </two-column-pitch>

        <two-column-pitch title="Good for Patients" :pitches="pitches.outcomesOverMoney" :overallClass="'pitch-background-default'">
        </two-column-pitch>
    </div>
</template>

<script lang="ts">
import HighlightOverlay from "../controls/highlight-overlay.vue";
import BannerPitch from "../banner-pitch.vue";
import TwoColumnPitch from '../controls/two-column-pitch.vue';
import employerProgramPitches from './employer-program-pitches';
export default {
    name: "cost-savings-for-employers",
    components: {
        HighlightOverlay,
        BannerPitch,
        TwoColumnPitch,
    },
    data() {
        return {
            pitches: employerProgramPitches,
        };
    },
};
</script>
<style scoped>
.pitch-background-dark {
    /* background-color: hsl(206, 24%, 66%); */
    padding-top: 120px;
    padding-bottom: 90px;
    background-color: var(--background-color-dark);
    color: white;
}

.pitch-background-dark-invert-icon-class {
    filter: invert(1);
}

.pitch-background-default {
    padding-top: 120px;
    padding-bottom: 90px;
}

.pitch-background-gradient {
    /* background-color: hsl(206, 24%, 66%); */
    background: linear-gradient(var(--background-color-light-emphasis), #ffffff);
    padding-top: 120px;
    padding-bottom: 90px;
}

@media (max-width: 992px) {
    .pitch-background-dark {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .pitch-background-default {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .pitch-background-gradient {
        padding-top: 40px;
        padding-bottom: 30px;
    }
}
</style>