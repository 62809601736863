import axios from 'axios';
export const requestmixin = {
    data() {
        return {
            requestmixin_addedInterceptor: false,
        }
    },
    methods: {
        get: function (url, axiosObj) {
            if (!this.requestmixin_addedInterceptor) {
                this.addInterceptor(axios);
            }
            axiosObj.timeout = 10000; // 10 second timeout
            return axios.get(url, axiosObj);
        },
        post: function (url, data, axiosObj) {
            if (!this.requestmixin_addedInterceptor) {
                this.addInterceptor(axios);
            }
            if (axiosObj == null) {
                axiosObj = {};
            }
            axiosObj.timeout = 10000; // 10 second timeout
            axiosObj.data = data;
            var serializedBody = {};
            for (let i = 0; i < Object.keys(data).length; i++) {
                const key = Object.keys(data)[i];
                if (typeof data[key] == 'object') {
                    serializedBody[key] = encodeURIComponent(JSON.stringify(data[key]));
                } else {
                    serializedBody[key] = encodeURIComponent(data[key]);
                }
            }
            axiosObj.params = serializedBody;
            return axios.get(url, axiosObj);
        },
        postToAllowedURL: function(url, data, axiosObj) {
            if (!this.requestmixin_addedInterceptor) {
                this.addInterceptor(axios);
            }
            if (axiosObj == null) {
                axiosObj = {};
            }
            axiosObj.timeout = 60_000; // 60 second timeout
            return axios.post(url, data, axiosObj);
        },
        addInterceptor: function (axios) {
            const maxRetries = 2;
            axios.interceptors.response.use(null, (error) => {
                // error.code === 'ECONNABORTED' implies that it was aborted by the timeout
                // Other error codes imply other things
                if (axios.isCancel(error) && error.code !== 'ECONNABORTED') {
                    // console.log('axios.isCancel');
                    return Promise.reject(error);
                }
                /** @type {import("axios").AxiosRequestConfig} */
                const config = error.config;
                // This is a recursive situation because we call axios(config) to retry the request.
                // We need a variable we can pass between recursions, presumably on the 'config' object.
                let counter = config.counter;
                if (counter == null) {
                    counter = 1;
                }
                var backupSite = 'https://staging.intelligentrx.com';
                if (location.hostname.includes('localhost')) {
                    backupSite = 'http://localhost:5000';
                }
                var urlAllowsBackupPages = true;
                if (location.hostname != 'dev.intelligentrx.com' && location.hostname != 'staging.intelligentrx.com' && location.hostname != 'prod.intelligentrx.com' && location.hostname != 'intelligentrx.com' && location.hostname != 'localhost') {
                    // This is a subdomain or a non-IntelligentRx domain
                    console.debug(`url ${location.hostname} does not allow loading from a backup site`);
                    urlAllowsBackupPages = false;
                }
                // Immediately reject if 401
                if (error.response != null && error.response.status == 401) {
                    return Promise.reject(error);
                }
                if (counter < maxRetries && ((error.response != null && error.response.status >= 400) || error.code === 'ECONNABORTED')) {
                    counter++;
                    config.counter = counter;
                    if (config.timeout == null)
                        config.timeout = 10000; // 10 second timeout
                    // Use some exponential backoff to prevent making 5 requests in quick succession
                    return new Promise(resolve => setTimeout(() => {
                        resolve(axios(config))
                    }, 200 * Math.pow(2, counter)
                    ));
                } else if (urlAllowsBackupPages && ((error.response != null && error.response.status >= 400 && (config.baseURL == null || !config.baseURL.startsWith(backupSite))) || error.code === 'ECONNABORTED')) {
                    counter = 0;
                    console.error(config);
                    config.baseURL = backupSite;
                    config.counter = counter;
                    if (config.timeout == null)
                        config.timeout = 10000; // 10 second timeout
                    try {
                        return new Promise((resolve) => {
                            resolve(axios(config));
                        })
                    }
                    catch {
                        return Promise.reject(error);
                    }
                }
                else {
                    counter++;
                    config.counter = counter;
                    console.error('unexpected and unhandled error');
                    console.error(config);
                    console.error(error);
                    console.error(error.response);
                }
                return Promise.reject(error);
            })
        },
    }
}
