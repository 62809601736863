import { createPinia, defineStore } from 'pinia'
 
interface SearchDrugOptions {
    location: GeolocationPosition | null
    zipCode: string | null
    selectedGsn: string | null
    selectedNdc: string | null
    selectedLabelValue: string | null
    labelSetByHuman: string | null
    selectedFormValue: string | null
    selectedDosageValue: string | null
    selectedQuantityValue: string | null
    customQuantity: string | null
    customQuantityPackaged: string | null
    //
    // Functions - these are placed here so that child components can call them and parents can build their own versions
    updateURL: Function | null
    loadDrugDetails: Function | null
    labelChanged: Function | null
    formChanged: Function | null
    dosageChanged: Function | null
    quantityChanged: Function | null
    //
    pharmacyNameFilter: string | null
}

export const useSearchDrugOptionsStore = defineStore('searchDrugOptionsStore', {
    state: (): SearchDrugOptions => {
        return {
            location: null,
            zipCode: null,
            selectedGsn: null,
            selectedNdc: null,
            selectedLabelValue: null,
            labelSetByHuman: null,
            selectedFormValue: null,
            selectedDosageValue: null,
            selectedQuantityValue: null,
            customQuantity: null,
            customQuantityPackaged: null,
            //
            updateURL: null,
            loadDrugDetails: null,
            labelChanged: null,
            formChanged: null,
            dosageChanged: null,
            quantityChanged: null,
            //
            pharmacyNameFilter: null,
        };
    },
    actions: {
        setSelectedValuesFromFormulations(names: any[], forms: any[], dosages: any[], packageAwareQuantities: any[]) {
            console.log(names);
            this.selectedLabelValue = names.find((n) => n.isSelected)?.drugName || this.selectedLabelValue;
            this.selectedFormValue =
                forms.find((f) => f.isSelected)?.form ||
                this.selectedFormValue;
            this.selectedDosageValue =
                dosages.find((s) => s.isSelected)?.strength ||
                this.selectedDosageValue;
            this.selectedGsn =
                dosages.find((s) => s.isSelected)?.gsn ||
                this.selectedGsn;
            this.selectedNdc =
                dosages.find((s) => s.isSelected)?.ndc ||
                this.selectedNdc;

            const quantityValueInResponse =
                packageAwareQuantities.find(
                    (q) => q.isSelected
                )?.quantity;
            if (
                (quantityValueInResponse == null || quantityValueInResponse === "") &&
                packageAwareQuantities?.length != null &&
                packageAwareQuantities?.length > 0
            ) {
                // This is a bad sign. We need to re-do this request, but with the first quantity selected, because these
                // results are probably wrong.
                this.selectedQuantityValue =
                    packageAwareQuantities[0]?.quantity;
                if (this.updatedURL != null)
                    this.updateURL();
                if (this.loadDrugDetails != null)
                    this.loadDrugDetails();
            }
            // Do NOT set selectedQuantityValue if it is custom; in that case, focus on setting the customQuantityValue.
            if (
                quantityValueInResponse != null &&
                this.selectedQuantityValue != "Custom"
            )
                this.selectedQuantityValue = quantityValueInResponse;
            else if (quantityValueInResponse != null) {
                // this.customQuantityPackaged =
                this.customQuantity = quantityValueInResponse;
            }
            this.lastSearchedQuantity =
                this.selectedQuantityValue == "Custom"
                    ? this.customQuantity
                    : this.selectedQuantityValue;
            if (this.updatedURL != null)
                this.updateURL();
        },
        setSelectedValues(localDrugDetails: any) {
            // for some reason the property hasn't been updated by the time this function is called
            this.selectedLabelValue =
                localDrugDetails.names.find((n) => n.isSelected)?.drugName ||
                this.selectedLabelValue;
            this.selectedFormValue =
                localDrugDetails.forms.find((f) => f.isSelected)?.form ||
                this.selectedFormValue;
            this.selectedDosageValue =
                localDrugDetails.dosages.find((s) => s.isSelected)?.strength ||
                this.selectedDosageValue;
            this.selectedGsn =
                localDrugDetails.dosages.find((s) => s.isSelected)?.gsn ||
                this.selectedGsn;
            this.selectedNdc =
                localDrugDetails.dosages.find((s) => s.isSelected)?.ndc ||
                this.selectedNdc;

            const quantityValueInResponse =
                localDrugDetails.packageAwareQuantities.find(
                    (q) => q.isSelected
                )?.quantity;
            if (
                (quantityValueInResponse == null || quantityValueInResponse === "") &&
                localDrugDetails.packageAwareQuantities?.length != null &&
                localDrugDetails.packageAwareQuantities?.length > 0
            ) {
                // This is a bad sign. We need to re-do this request, but with the first quantity selected, because these
                // results are probably wrong.
                this.selectedQuantityValue =
                    localDrugDetails.packageAwareQuantities[0]?.quantity;
                if (this.updatedURL != null)
                    this.updateURL();
                if (this.loadDrugDetails != null)
                    this.loadDrugDetails();
            }
            // Do NOT set selectedQuantityValue if it is custom; in that case, focus on setting the customQuantityValue.
            if (
                quantityValueInResponse != null &&
                this.selectedQuantityValue != "Custom"
            )
                this.selectedQuantityValue = quantityValueInResponse;
            else if (quantityValueInResponse != null) {
                // this.customQuantityPackaged =
                this.customQuantity = quantityValueInResponse;
            }
            this.lastSearchedQuantity =
                this.selectedQuantityValue == "Custom"
                    ? this.customQuantity
                    : this.selectedQuantityValue;
            if (this.updatedURL != null)
                this.updateURL();
        },
    }
});

const store = createPinia()

export default store
