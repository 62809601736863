<template>
  <div :class="getOverallClass">
    <div class="container">
      <div class="row">
        <div
          class="col col-12 col-lg-6 d-flex flex-column justify-content-center"
        >
          <h2 class="display-4">{{ title }}</h2>
          <p class="pitch-font">{{ description }}</p>
        </div>
        <div class="col col-6 col-lg-3">
          <h3 class="h1 font-weight-bold">{{ prosCons?.prosHeader }}</h3>
          <div
            class="row pb-4 pt-2"
            v-for="prosBullet in prosCons?.prosBullets"
            :key="prosBullet"
          >
            <div class="col col-3 col-sm-2">
              <div
                class="
                  h-100
                  w-100
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-start
                "
              >
                <font-awesome-icon
                  icon="fa fa-check-square"
                  aria-hidden="true"
                  class="pros-cons-icon"
                ></font-awesome-icon>
              </div>
            </div>
            <p class="col col-9 col-sm-10 mb-0 align-self-center pitch-font">
              {{ prosBullet }}
            </p>
          </div>
        </div>
        <div class="col col-6 col-lg-3">
          <h3 class="h1 font-weight-bold">{{ prosCons?.consHeader }}</h3>
          <div
            class="row pb-4 pt-2"
            v-for="consBullet in prosCons?.consBullets"
            :key="consBullet"
          >
            <div class="col col-3 col-sm-2">
              <div
                class="
                  h-100
                  w-100
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-start
                "
              >
                <font-awesome-icon
                  icon="fa fa-minus"
                  aria-hidden="true"
                  class="pros-cons-icon pitch-font"
                ></font-awesome-icon>
              </div>
            </div>
            <p class="col col-9 col-sm-10 mb-0 align-self-center pitch-font">
              {{ consBullet }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "pros-cons-pitch",
  props: {
    title: String,
    description: String,
    // prosCons: {
    //     prosHeader: String,
    //     prosBullets: String[],
    //     consHeader: String,
    //     consBullets: String[],
    // }
    prosCons: Object,
    overallClass: String,
  },
  computed: {
    getOverallClass() {
      if (this.overallClass != null && this.overallClass.length > 0)
        return "row " + this.overallClass;
      return "row pros-cons-pitch-overall";
    },
  },
};
</script>
<style scoped>
.pros-cons-pitch-overall {
  padding-top: 120px;
  padding-bottom: 90px;
}
.pros-cons-icon {
  width: 36px;
  height: 36px;
}
@media (max-width: 992px) {
  .pros-cons-pitch-overall {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .pros-cons-icon {
    width: 24px;
    height: 24px;
  }
}
</style>