<template>
    <div class="" v-if="inNav && isMobile()">
        <loading-spinner v-if="showSpinner" />
        <div class="input-group input-wrapper align-items-center">
            <typeahead @searchNow="searchDrug" inputClass="irx-input-fixed-height text-left search-input-class"
                outerClass="form-control border-0 search-outer-class my-1" />
            <div v-if="selectedDrugName != null && selectedDrugName.length > 0">
                <prc-button type="emphasis" @click="searchDrug" ariaLabel="Search">
                    <img class="mobile-search" v-if="isMobile()" src="../../assets/search.svg" alt="search icon" />
                    <span v-if="!isMobile()">Search</span>
                </prc-button>
            </div>
        </div>
    </div>
    <div class="in-nav" v-else-if="inNav">
        <loading-spinner v-if="showSpinner" />
        <div class="input-group input-wrapper align-items-center">
            <typeahead @searchNow="searchDrug" inputClass="irx-input-fixed-height text-left search-input-class"
                outerClass="form-control border-0 search-outer-class my-1" />
            <div v-if="selectedDrugName != null && selectedDrugName.length > 0">
                <prc-button type="emphasis" @click="searchDrug" ariaLabel="Search">
                    <img class="mobile-search" v-if="isMobile()" src="../../assets/search.svg" alt="search icon" />
                    <span v-if="!isMobile()">Search</span>
                </prc-button>
            </div>
        </div>
    </div>
    <div class="search-section container-fluid" v-else>
        <div class="row">
            <loading-spinner v-if="showSpinner" />
            <div class="col col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div class="input-group mb-3 input-wrapper align-items-center rounded">
                    <!-- vue-ignore -->
                    <typeahead @searchNow="searchDrug" inputClass="irx-input-fixed-height text-left search-input-class"
                        outerClass="form-control border-0 search-outer-class my-1" />
                    <div>
                        <prc-button type="emphasis" @click="searchDrug" ariaLabel="Search">
                            <img class="mobile-search" v-if="isMobile()" src="../../assets/search.svg" alt="search icon" />
                            <span v-if="!isMobile()">Search</span>
                        </prc-button>
                    </div>
                </div>
                <div v-if="searchDrugFailed" class="alert alert-warning" role="alert">
                    Please enter a valid drug name
                </div>
            </div>
        </div>

        <div class="row welcome-subheader p-2" v-if="showRecentSearchesAndOtherInfo">
            <div class="col col-sm-12 col-md-4 offset-md-4 text-light">
                <span v-if="showRecentSearches">Recent Searches</span>
                <span v-if="!showRecentSearches">Popular Searches</span>
            </div>
        </div>
        <div class="row" v-if="showRecentSearchesAndOtherInfo">
            <div class="col col-sm-12 col-md-8 offset-md-2 d-flex justify-content-center">
                <ul class="list-inline">
                    <li class="list-inline-item p-2" v-for="item in showRecentSearches
                        ? recentSearches
                        : popularSearches" :key="item" @click="searchThis(item)">
                        <!-- want these to be big, friendly buttons which appear larger than the search box -->
                        <a href="#" @click="goToHref(item)" class="btn btn-lg px-5 py-3" style="
                            background-color: var(--background-color-dark-emphasis);
                            color: white;
                            text-transform: capitalize;
                            border: none;
                        ">
                            {{ item }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import PrcButton from "../controls/prc-button.vue";
import LoadingSpinner from "../controls/loading-spinner.vue";
import Typeahead from "../controls/typeahead.vue";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";
import { cookiemixin } from "../../mixins/cookiemixin";
import { errormixin } from "../../mixins/errormixin";
import { searchDrugNames } from "../../mixins/searchDrugNames";
import { useAutocompleteStore } from '../../stores/autocomplete';
import { mapWritableState } from 'pinia'

export default {
    name: "search-bar",
    mixins: [mixinDetectingMobile, cookiemixin, errormixin, searchDrugNames],
    components: {
        PrcButton,
        LoadingSpinner,
        Typeahead,
    },
    props: {
        inNav: { type: Boolean, default: false },
        doNavigate: { type: Boolean, default: true },
        showRecentSearchesAndOtherInfo: { type: Boolean, default: true },
        showPromotionalPages: { type: Boolean, default: true },
    },
    emits: ['drug-name'],
    data() {
        return {
            showSpinner: false,
            // missingDrugName: false,
            selectedDrug: null,
            drugs: [],
            searchDrugFailed: false,
        };
    },
    computed: {
        ...mapWritableState(useAutocompleteStore, ['results', 'oldResults', 'selectedDrugName']),
        showRecentSearches() {
            return this.recentSearches != null && this.recentSearches.length > 0;
        },
        popularSearches() {
            // Asana Task: https://app.asana.com/0/1200287405669226/1201489508896596
            let s = [
                "Atorvastatin",
                "Albuterol Sulfate Hfa",
                "Lisinopril",
                "Sildenafil",
                "Levothyroxine",
                "Gabapentin",
                "Metformin HCL",
            ];
            if (this.isMobile()) {
                return s.slice(0, 3);
            } else {
                return s;
            }
        },
        recentSearches() {
            var searchHistory = this.readSearchHistory();
            let newRecentSearches: string[] = null;
            if (searchHistory == null) {
                return null;
            }
            newRecentSearches = searchHistory.split(",");
            if (newRecentSearches == null) {
                return null;
            }
            if (newRecentSearches.length > 5) {
                newRecentSearches = newRecentSearches.slice(
                    newRecentSearches.length - 5,
                    newRecentSearches.length
                );
            }
            for (let i = 0; i < newRecentSearches.length; i++) {
                newRecentSearches[i] = newRecentSearches[i].toLowerCase();
            }
            // eliminate duplicates
            newRecentSearches = Array.from(new Set(newRecentSearches));

            newRecentSearches = newRecentSearches.reverse();
            if (this.isMobile()) {
                return newRecentSearches.slice(0, 5);
            } else {
                return newRecentSearches;
            }
        },
    },
    methods: {
        goToHref(encodedDrugName) {
            this.$router.push({ path: '/' + encodeURIComponent(encodedDrugName), query: { z: this.$route.query['z'], p: this.$route.query['p'] } });
        },
        searchDrug() {
            if (this.selectedDrugName == null || this.selectedDrugName.length < 1) {
                return;
            }
            if (!this.doNavigate) {
                // instead of navigating, these pages (like the Price Explainer price-explainer.vue page) want us to emit an update to their value of drug-name
                this.$emit("drug-name", this.selectedDrugName);
                return;
            }
            // show the spinner before the http request
            this.addToSearchHistory(this.selectedDrugName);
            let encodedDrugName = encodeURIComponent(this.selectedDrugName);
            this.$router.push({ path: '/' + encodedDrugName, query: { z: this.$route.query['z'], p: this.$route.query['p'] } });
        },
        toggleSpinner() {
            this.showSpinner = !this.showSpinner;
        },
        searchThis(drugName: string) {
            this.selectedDrugName = drugName;
            this.searchDrug();
        },
        addToSearchHistory(searchValue: string) {
            var allRecentSearches = this.readSearchHistory();
            if (allRecentSearches != null && allRecentSearches.length > 0) {
                var allRecentSearchesSplit = allRecentSearches.split(",");
                if (allRecentSearchesSplit != null) {
                    // rebuild the list so we can move the current drug name to the last place in the list
                    var rebuiltList = "";
                    for (var i = 0; i < allRecentSearchesSplit.length; i++) {
                        if (allRecentSearchesSplit[i] != searchValue) {
                            rebuiltList += allRecentSearchesSplit[i] + ",";
                        }
                    }
                    allRecentSearches = rebuiltList + searchValue;
                } else {
                    allRecentSearches = searchValue;
                }
            } else {
                allRecentSearches = searchValue;
            }
            return this.setCookie("RecentSearches", allRecentSearches);
        },
        readSearchHistory() {
            return this.readCookie("RecentSearches");
        },
    },
    mounted() {
        // Used by the home and how it works pages to focus on the input
        if (!this.inNav && !this.isMobile()) {
            let s: any = document.querySelector("[placeholder^=🔎︎]");
            if (s == null) return;
            s.focus();
        }
    },
};
</script>

<style scoped>
.search-section {
    padding-bottom: 40px;
}

.input-wrapper {
    padding: 5px 5px 5px 18px;
    box-shadow: 2px 1px 3px #00000029;
    opacity: 1;
    background-color: var(--background-color-light);
}

.input-wrapper-dark {
    padding: 5px 5px 5px 18px;
    box-shadow: 2px 1px 3px #00000029;
    opacity: 1;
    background-color: var(--background-color-off-dark);
}

@media (max-width: 992px) {
    .input-wrapper {
        padding: 0;
    }

    .input-wrapper-dark {
        padding: 0;
    }
}

.search-outer-class {
    background-color: var(--background-color-light);
}

.search-outer-class-dark {
    background-color: var(--background-color-off-dark);
}

.search-input-class {
    background-color: var(--background-color-light);
}

.search-input-class-dark {
    background-color: var(--background-color-off-dark);
}

.input-wrapper input,
.input-wrapper input:active:focus {
    border: 0;
    box-shadow: none;
}

.font-error {
    text-align: center;
    font: Bold 16px/24px var(--body-font-family);
    letter-spacing: 1.5px;
    color: #ffff99;
    opacity: 1;
}

.mobile-search {
    height: 24px;
}

.welcome-subheader {
    text-align: center;
    font: var(--body-font-weight) 24px/29px var(--body-font-family);
    letter-spacing: 0px;
    color: black;
    opacity: 1;
}

.in-nav {
    width: 500px;
}

@media (max-width: 700px) {
    .in-nav {
        width: 300px;
    }
}

@media (max-width: 992px) {
    .welcome-subheader {
        font: var(--body-font-weight) 16px/16px var(--body-font-family);
    }

    .in-nav {
        width: 400px;
    }
}
</style>../../stores