<template>
  <div class="do-not-assume px-2">
    <h1 class="text-center" style="font-weight: var(--heading-font-weight)">
      Don't Assume You Have To Pay The Pharmacy's Price
    </h1>
    <h2 class="h5 text-center py-2" style="">
      Now everyone can afford their prescription medication, even if you don’t
      have prescription insurance. Just print out your free coupons and use the
      pharmacy at the top of our list.
    </h2>
    <div class="container-fluid">
      <div class="row py-3">
        <div class="col col-12 col-md-6">
          <div class="row py-3">
            <div class="col col-sm-1 d-none d-sm-block px-0">
              <img
                src="../../assets/checkbox.webp"
                class="do-not-assume-checkbox float-right mt-1 ml-1"
                alt="checkbox icon"
              />
            </div>
            <div class="col col-12 col-sm-11">
              <h3 class="h4" style="text-transform: capitalize; font-weight: var(--heading-font-weight)">
                Don’t have insurance? We got you!
              </h3>
              <p style="text-transform: capitalize">
                Even if you have insurance, our prices may beat it. We will help
                you compare prices so that you can always pay the lowest prices.
                This is particularly relevant for those with high-deductible
                plans or Medicare.
              </p>
            </div>
          </div>
          <div class="row py-3">
            <div class="col col-sm-1 d-none d-sm-block px-0">
              <img
                src="../../assets/checkbox.webp"
                class="do-not-assume-checkbox float-right mt-1"
                alt="checkbox icon"
              />
            </div>
            <div class="col col-12 col-sm-11">
              <h3 class="h4" style="text-transform: capitalize; font-weight: var(--heading-font-weight)">
                Save on Pet Prescriptions
              </h3>
              <p style="text-transform: capitalize">
                In most cases, your veterinarian can write prescriptions for
                animals. Make sure to ask for the medication to be written for
                its human-equivalent. Then fill your prescription at a
                participating pharmacy using IntelligentRx and save!
              </p>
            </div>
          </div>
          <div class="row py-3">
            <div class="col col-sm-1 d-none d-sm-block px-0">
              <img
                src="../../assets/checkbox.webp"
                class="do-not-assume-checkbox float-right mt-1"
                alt="checkbox icon"
              />
            </div>
            <div class="col col-12 col-sm-11">
              <h3 class="h4" style="text-transform: capitalize; font-weight: var(--heading-font-weight)">
                Find the lowest prescription price.
              </h3>
              <p style="text-transform: capitalize">
                Our clinical pharmacists have developed a way to help all
                consumers save up to 85 percent on prescriptions by providing an
                easy-to-use search tool for locating the best local pharmacy
                price.
              </p>
            </div>
          </div>
        </div>
        <div class="col col-12 col-md-6 d-none d-md-block">
          <div class="d-flex justify-content-center">
          <div class="image-on-right-overlay">
            <img
              src="../../assets/home-background-700.webp"
              class="image-on-right img-fluid"
              alt="Discussing Prescription Discounts with a Pharmacist"
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "do-not-assume",
};
</script>
<style scoped>
.do-not-assume {
  color: var(--light);
  background-color: var(--primary-color);
  padding-top: 30px;
  padding-bottom: 30px;
}
.do-not-assume-checkbox {
  max-height: 25px;
  max-width: 25px;
}
.image-on-right {
  max-height: 400px;
    opacity: 0.70;
}

.image-on-right-overlay {
    background-color: var(--primary-color);
    width: fit-content;
    height: fit-content;
    border-style: hidden;
    border-radius: 10px;
}

@media (max-width: 992px) {
    .image-on-right {
        max-height: 300px;
    }
}
</style>