import { requestmixin } from './requestmixin';
export const errormixin = {
    methods: {
        // reportError both logs errors to the console and reports them to IntelligentRx.
        reportError: function (errorText) {
            try {
                console.error(errorText);
                if (errorText == null || errorText.length > 10_000) {
                    return;
                }
                requestmixin.methods.post('/api/ecsClientError', {
                    errorText: errorText,
                })
                    .catch(error => {
                        if (error.response) {
                            // Server responded with non-2xx response
                            console.error(error.response.data);
                            console.error(error.response.status);
                            console.error(error.response.headers);
                        } else if (error.request) {
                            // no response was received from request
                            console.error(error.request);
                        } else {
                            // error setting up the request
                            console.error(error.message);
                        }
                    })
            } catch (newError) {
                // do nothing since we don't know what failed
                console.error('caught error trying to reportError');
                try {
                    console.error(newError);
                } catch {
                    // ok...
                }
            }
        },
    }
}
