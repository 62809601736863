<template>
    <div :class="getOverallClass">
        <div v-if="isMobile()" class="container">
            <h2 class="display-4">{{ title }}</h2>
            <div v-for="(pitch, i) in pitches" :key="pitch.title" class="row">
                <div class="col col-12" style="cursor: pointer" @click="switchPitch(i)">
                    <hr v-if="selectedPitch == pitch" class="three-detailed-pitch-hr text-primary" />
                    <hr v-else class="three-detailed-pitch-hr" />
                    <h3 class="h1">{{ pitch.title }}</h3>
                </div>
                <div class="col col-12" v-if="selectedPitch == pitch">
                    <p class="pitch-font">{{ selectedPitch.details }}</p>
                    <router-link to="/request-a-demo" class="btn btn-lg my-4"
                        style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">Request
                        a Demo
                        <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-else class="container">
            <h2 class="display-4">{{ title }}</h2>
            <div class="row three-detailed-pitch-selector">
                <div class="col col-4" v-for="(pitch, i) in pitches" :key="pitch.title" style="cursor: pointer"
                    @click="switchPitch(i)">
                    <hr v-if="selectedPitch == pitch" class="three-detailed-pitch-hr text-primary" />
                    <hr v-else class="three-detailed-pitch-hr" />
                    <h3 class="h1">{{ pitch.title }}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col col-12 col-lg-6">
                    <h2 class="h3">{{ selectedPitch.summary }}</h2>
                    <p class="pitch-font">{{ selectedPitch.details }}</p>
                    <router-link to="/request-a-demo" class="btn btn-lg my-4"
                        style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">Request
                        a Demo
                        <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                    </router-link>
                </div>
                <div class="col col-12 col-lg-6 d-none d-lg-block">
                    <img :src="require(`@/assets/${selectedPitch.imageInAssets}`)" class="three-detailed-pitch-image mh-100"
                        height="400" :alt="selectedPitch.pitchImageAltText" />
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { PropType } from "vue";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";
export default {
    name: "three-detailed-pitch",
    props: {
        title: String,
        pitches: Array as PropType<any[]>,
        // pitches: [
        //   {
        //     title: String,
        //     summary: String,
        //     details: String,
        //     iconInAssets: String,
        //     pitchImageInAssets: String,
        //   },
        // ],
        overallClass: String,
        iconClass: String,
    },
    mixins: [mixinDetectingMobile],
    data() {
        return {
            selectedPitch: this.pitches[0],
        };
    },
    computed: {
        getOverallClass() {
            if (this.overallClass != null && this.overallClass.length > 0)
                return "row " + this.overallClass;
            return "row three-detailed-pitch-background";
        },
        getIconClass() {
            if (this.iconClass != null && this.iconClass != '') {
                return this.iconClass;
            }
            return '';
        }
    },
    methods: {
        switchPitch(pitchIndex: number) {
            this.selectedPitch = this.pitches[pitchIndex];
        },
    },
    created() { },
};
</script>
<style scoped>
.three-detailed-pitch-hr {
    border-top: 2px;
    color: grey;
    border: solid;
}

.three-detailed-pitch-background {
    background: linear-gradient(var(--background-color-dark), #f9fdff);
    padding-top: 120px;
    padding-bottom: 120px;
}

.three-detailed-pitch-image {
    max-height: 500px;
    border-radius: 20px;
}

.three-detailed-pitch-selector {
    padding-bottom: 60px;
}

@media (max-width: 992px) {
    .three-detailed-pitch-background {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .three-detailed-pitch-image {
        max-height: 350px;
        border-radius: 5px;
    }

    .three-detailed-pitch-selector {
        padding-bottom: 20px;
    }
}
</style>