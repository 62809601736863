<template>
  <div
    class="container-fluid py-4 text-light"
    style="background-color: var(--background-color-dark)"
  >
    <h1 class="text-center text-light">Don't Overcharge For Prescriptions</h1>
    <search-bar :inNav="false" :doNavigate="true" :showRecentSearchesAndOtherInfo="false" class="pt-4"></search-bar>

    <div class="container">
      <h2 class="text-center">IntelligentRx is for Savvy Techs</h2>
      <p class="savvyText">
        With
        <router-link
          class="text-light light-link"
          to="/goodrx-comparison#betterForPharmacies"
          >lower pharmacy fees than GoodRx</router-link
        >
        and
        <router-link class="text-light light-link" to="/goodrx-comparison#betterPrices"
          >better or comparable prices</router-link
        >, IntelligentRx is better for your pharmacy and better for consumers,
        and
        <router-link
          class="text-light light-link"
          to="/goodrx-comparison#goodrx-comparison"
          >we have the data</router-link
        >
        to prove it.
      </p>
      <p class="savvyText">
        Get started now with IntelligentRx by searching for a drug, viewing
        prices via our 
        <router-link
          class="text-light light-link"
          to="/goodrx-comparison#goodrx-comparison"
          >GoodRx Comparison page</router-link>, or downloading our 
            <a
            href="https://apps.apple.com/us/app/intelligentrx/id1611042374"
              class="text-light light-link"
            >iOS</a> or <a
            href="https://play.google.com/store/apps/details?id=com.intelligentrx"
              class="text-light light-link"
            >Android</a>
        apps.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import SearchBar from "../shared/search-bar.vue";
export default {
  components: { SearchBar },
  name: "tech-landing-page",
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.savvyText {
    font-size: 24px;
}

@media (max-width: 992px) {
    .savvyText {
        font-size: 18px;
    }
}
</style>