<template>
    <div class="" style="padding-top: 120px;">
        <h2 class="d-none d-md-block display-2 text-center">{{ title }}</h2>
        <h2 class="d-block d-md-none display-3 text-center">{{ title }}</h2>
        <div :class="getOverallClass">
            <div class="col col-12 col-md-6 col-lg-6" v-for="pitch in pitches" :key="pitch.title">
                <div class="pitch-content-container border rounded mb-1">
                    <div class="justify-content-around d-flex flex-column inner-container">
                        <h3 class="d-none d-md-block display-3">{{ pitch.title }}</h3>
                        <h3 class="d-block d-md-none display-4">{{ pitch.title }}</h3>
                        <p class="lead">{{ pitch.details }}</p>
                        <div>
                            <router-link to="/request-a-demo" class="btn btn-lg"
                                style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">
                                Learn More
                                <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from 'vue';

export default {
    name: "two-column-pitch",
    props: {
        title: String,
        pitches: Array as PropType<any[]>,
        // pitches: [
        //   {
        //     title: String,
        //     summary: String,
        //     details: String,
        //     iconInAssets: String,
        //     pitchImageInAssets: String,
        //   },
        // ],
        overallClass: String,
        iconClass: String,
    },
    computed: {
        getOverallClass() {
            if (this.overallClass != null && this.overallClass.length > 0)
                return "row " + this.overallClass;
            return "row pitch-row";
        },
        getIconClass() {
            if (this.iconClass != null && this.iconClass != '') {
                return this.iconClass;
            }
            return '';
        }
    },
};
</script>
<style scoped>
.pitch-image {
    width: 60px;
    height: 60px;
}

.pitch-row {
    padding-top: 120px;
    padding-bottom: 80px;
}

.pitch-overarching-header {
    padding-bottom: 60px;
}

.pitch-content-container {
    height: 450px;
    text-align: center;
    background-color: #f9f9f9;
    /* margin-left: 25px;
    margin-right: 25px; */
}

.inner-container {
    /* padding: 50px 50px; */
    height: 100%;
}

.bolder-hr {
    border-top: 2px solid grey;
    width: 50px;
}

@media (max-width: 768px) {
    .pitch-image {
        width: 24px;
        height: 24px;
    }

    .pitch-row {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .pitch-overarching-header {
        padding-bottom: 40px;
    }

    .pitch-content-container {
        height: 350px;
        /* text-align: left;
        margin-left: 3vw;
        margin-left: 3vmin;
        margin-right: 3vw;
        margin-right: 3vmin;
        margin-bottom: 30px;
        padding-left: 0px;
        padding-right: 0px;
        width: 94vw;
        width: 94vmin; */
    }

    /* .inner-container {
        width: 94vw;
        width: 94vmin;
    } */
}
</style>
