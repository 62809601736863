<template>
    <div class="container-fluid">
        <banner-pitch :image="pitches.banner.imageSrc" pageDescription="Health Plans">
            <template v-slot:bannerHTMLLarge>
                <h2 class="display-4">Help Your Members Reduce Pharmacy Spend</h2>
                <p class="pitch-font">IntelligentRx + Your Members = Up To 30% Savings.</p>
                <router-link to="/request-a-demo" class="btn btn-lg" style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">
                    Request a Demo
                    <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                </router-link>
            </template>
            <template v-slot:bannerHTMLMobile>
                <h2 class="">Help Your Members Reduce Pharmacy Spend</h2>
                <p class="pitch-font">IntelligentRx + Your Members = Up To 30% Savings</p>
                <router-link to="/request-a-demo" class="btn btn-lg my-4"
                    style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">Request a Demo
                    <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                </router-link>
            </template>
        </banner-pitch>

        <highlight-overlay :title="pitches.healthPlanhighlightOverlayThomas.title" :text="pitches.healthPlanhighlightOverlayThomas.text"
            :imageSrc="pitches.healthPlanhighlightOverlayThomas.imageSrc">
        </highlight-overlay>

        <three-column-pitch title="Help Your Members Save" :pitches="pitches.healthPlans"
            :overallClass="'pitch-background-default'">
        </three-column-pitch>

        <pros-cons-pitch title="Is IntelligentRx Right For You?" description="" :prosCons="pitches.healthPlancommonProsCons"
            :overallClass="'pitch-background-dark'" :iconClass="'pitch-background-dark-invert-icon-class'">
        </pros-cons-pitch>

        <four-stats-pitch title="How We Save Money" :fourStats="pitches.healthPlancommonProblemSolutionStats"
            :overallClass="'pitch-background-gradient'">
        </four-stats-pitch>

        <three-detailed-pitch title="Managing Complexity" :pitches="pitches.healthPlanmanagingComplexity"
            :overallClass="'pitch-background-default'"></three-detailed-pitch>

        <three-column-pitch title="Our Offerings" :pitches="pitches.healthPlanemployerOurValuesInOurProduct"
            :overallClass="'pitch-background-dark'" :iconClass="'pitch-background-dark-invert-icon-class'">
        </three-column-pitch>


        <three-column-pitch title="Health Plan's Return On Investment" :pitches="pitches.healthPlanemployerROI"
            :overallClass="'pitch-background-gradient'">
        </three-column-pitch>

        <three-detailed-pitch title="How IntelligentRx For Health Plans Works" :pitches="pitches.healthPlanhowItWorks"
            :overallClass="'pitch-background-default'">
        </three-detailed-pitch>

        <pitch-image :title="pitches.securityPitch.title" :text="pitches.securityPitch.text" :imageLeft="true"
            :imageSrc="pitches.securityPitch.imageSrc" :overallClass="'pitch-background-dark'">
        </pitch-image>

        <three-column-pitch title="Integrating With Your Existing Plan" :pitches="pitches.integratingWithExistingPlan"
            :overallClass="'pitch-background-gradient'">
        </three-column-pitch>
    </div>
</template>

<script lang="ts">
import FourStatsPitch from "../controls/four-stats-pitch.vue";
import HighlightOverlay from "../controls/highlight-overlay.vue";
import ThreeColumnPitch from "../controls/three-column-pitch.vue";
import ThreeDetailedPitch from "../controls/three-detailed-pitch.vue";
import PitchImage from "../controls/pitch-image.vue";
import ProsConsPitch from "../controls/pros-cons-pitch.vue";
import BannerPitch from "../banner-pitch.vue";
import employerProgramPitches from './employer-program-pitches';
export default {
    name: "health-plans",
    components: {
        HighlightOverlay,
        ThreeColumnPitch,
        ThreeDetailedPitch,
        FourStatsPitch,
        PitchImage,
        ProsConsPitch,
        BannerPitch,
    },
    data() {
        return {
            // --- New Sections ---
            // "Our Track Record"
            // "helped 350,000 patients", "save over 20 million dollars", "since 2019"
            pitches: employerProgramPitches,
        };
    },
};
</script>
<style scoped>
.pitch-background-dark {
    /* background-color: hsl(206, 24%, 66%); */
    padding-top: 120px;
    padding-bottom: 90px;
    background-color: var(--background-color-dark);
    color: white;
}

.pitch-background-dark-invert-icon-class {
    filter: invert(1);
}

.pitch-background-default {
    padding-top: 120px;
    padding-bottom: 90px;
}

.pitch-background-gradient {
    /* background-color: hsl(206, 24%, 66%); */
    background: linear-gradient(var(--background-color-light-emphasis), #ffffff);
    padding-top: 120px;
    padding-bottom: 90px;
}

@media (max-width: 992px) {
    .pitch-background-dark {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .pitch-background-default {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .pitch-background-gradient {
        padding-top: 40px;
        padding-bottom: 30px;
    }
}
</style>
