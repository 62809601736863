<template>
    <div class="container">
        <div class="col col-12 col-md-8 offset-md-2 py-4">
            <h1 class="display-3 d-none d-md-block text-center">Prescription Transfer</h1>
            <h1 class="display-4 d-block d-md-none text-center">Prescription Transfer</h1>
            <!-- <p class="h4 d-none d-md-block text-center">The simple and free way to help patients transfer their prescriptions</p>
            <p class="h5 d-block d-md-none text-center">The simple and free way to help patients transfer their prescriptions</p> -->

            <form @submit.prevent class="mt-4">
                <div class="form-group">
                    <label class="" for="pharmacyNameSearch">Where do you want to pick up your
                        prescription?</label>
                    <input type="text" class="form-control mb-2 mr-sm-2" id="pharmacyNameSearch"
                        v-on:input="pharmacyNameBeingSearched = $event.target.value" placeholder="🔎︎   Type pharmacy name here" />
                </div>
            </form>

            <div v-if="prescriptionTransferResponse?.pharmacyName != null">
                <h2 class="h2">
                    How to Transfer to {{ prescriptionTransferResponse.pharmacyName }}
                </h2>
                <p>Transfers to {{ prescriptionTransferResponse.pharmacyName }}<span v-if="prescriptionTransferResponse.websiteDomain != null && prescriptionTransferResponse.websiteDomain != ''"> (<u>{{ prescriptionTransferResponse.websiteDomain }}</u>)</span> are handled by their staff. <span
                        v-if="prescriptionTransferResponse.guestURL != null && prescriptionTransferResponse.guestURL.length > 1">Click
                        the button below to go to the {{ prescriptionTransferResponse.pharmacyName }} prescription
                        transfer portal.</span><span
                        v-else-if="prescriptionTransferResponse.withAccountURL != null && prescriptionTransferResponse.withAccountURL.length > 1">Click
                        the button below to go to the {{ prescriptionTransferResponse.pharmacyName }} prescription
                        transfer portal.</span><span v-else>Unfortunately, we are not aware of an online portal you can use to
                        transfer your prescription so you need to call the pharmacy to initiate the transfer. We believe
                        their phone number is: <a :href="'tel:'+prescriptionTransferResponse.phoneNumber">{{
                            prescriptionTransferResponse.phoneNumber }}</a></span></p>
                <a class="btn btn-lg btn-primary" :href="prescriptionTransferResponse.guestURL"
                    v-if="prescriptionTransferResponse.guestURL != null && prescriptionTransferResponse.guestURL.length > 1">Go
                    To Guest Transfer Portal</a>
                <a class="btn btn-lg btn-primary" :href="prescriptionTransferResponse.withAccountURL"
                    v-if="prescriptionTransferResponse.withAccountURL != null && prescriptionTransferResponse.withAccountURL.length > 1">Go
                    To Member Transfer Portal</a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { requestmixin } from "../mixins/requestmixin";
import { errormixin } from "../mixins/errormixin";
import axios from 'axios';
export function debounce(func: any, wait: number, immediate: boolean) {
    var timeout: number | null;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export interface PrescriptionTransferResponse {
    pharmacyName: string;
    guestURL: string;
    withAccountURL: string;
    phoneNumber: string;
    websiteDomain: string;
}

export default {
    name: 'prescription-transfer',
    mixins: [
        requestmixin,
        errormixin,
    ],
    data() {
        let d: {
            pharmacyNameBeingSearched: string
            prescriptionTransferResponse: PrescriptionTransferResponse | null
            lastRequestInitiated: any | null,
        } = {
            pharmacyNameBeingSearched: "",
            prescriptionTransferResponse: null,
            lastRequestInitiated: null,
        };
        return d;
    },
    methods: {
        getPrescriptionTransfer(pharmacyName: string) {
            if (pharmacyName == null || pharmacyName.length < 3) {
                this.results = [];
                return;
            }

            if (this.lastRequestInitiated != null) {
                this.lastRequestInitiated.cancel();
            }

            this.lastRequestInitiated = axios.CancelToken.source();

            this.get("/api/prescription-transfer", {
                params: {
                    pharmacyName: pharmacyName,
                },
                cancelToken: this.lastRequestInitiated.token,
            })
            .then((res) => {
                this.prescriptionTransferResponse = res.data;
            })
            .catch((err) => {
                this.reportError(err);
            });
        },
    },
    watch: {
        //@ts-expect-error
        pharmacyNameBeingSearched: debounce(function () {
            // this.searchForPharmacyName(this.pharmacyName);
            this.getPrescriptionTransfer(this.pharmacyNameBeingSearched);
        }, 200)
    },
    mounted() {
        this.pharmacyNameBeingSearched = this.$route.query.p;
    },
}
</script>