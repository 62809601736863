<template>
    <div class="container-fluid pb-5" style="background-color: var(--background-color-off-light);">
        <h2 class="section-title pt-5" style="font-weight:bold;">Featured Savings</h2>
        <div class="row justify-content-around pt-3" style="">
            <div class="col col-12 col-md-3">
                <router-link to="/Sildenafil">
                    <saving-card>
                        <template v-slot:name> Sildenafil </template>
                        <template v-slot:label> Viagra </template>
                        <template v-slot:dosage> 30 tablets 20 mg </template>
                        <template v-slot:discount> 99% </template>
                        <template v-slot:sale-price> $6.48 </template>
                        <template v-slot:original-price> $960 </template>
                    </saving-card>
                </router-link>
            </div>
            <div class="col col-12 col-md-3">
                <router-link to="/Atorvastatin">
                    <saving-card>
                        <template v-slot:name> Atorvastatin </template>
                        <template v-slot:label> Lipitor </template>
                        <template v-slot:dosage> 30 tablets 10 mg </template>
                        <template v-slot:discount> 97 % </template>
                        <template v-slot:sale-price> $5.70 </template>
                        <template v-slot:original-price> $184 </template>
                    </saving-card>
                </router-link>
            </div>
            <div class="col col-12 col-md-3">
                <router-link to="/Gabapentin">
                    <saving-card>
                        <template v-slot:name> Gabapentin </template>
                        <template v-slot:label> Neurontin </template>
                        <template v-slot:dosage> 90 capsules 300 mg </template>
                        <template v-slot:discount> 81 % </template>
                        <template v-slot:sale-price> $9.03 </template>
                        <template v-slot:original-price> $48 </template>
                    </saving-card>
                </router-link>
            </div>
        </div>
        <div class="row justify-content-around">
            <div class="col col-12 col-md-3">
                <router-link to="/Albuterol Sulfate HFA">
                    <saving-card>
                        <template v-slot:name> Albuterol </template>
                        <template v-slot:label> ProAir </template>
                        <template v-slot:dosage> 1 Inhaler 90 mcg </template>
                        <template v-slot:discount> 70 % </template>
                        <template v-slot:sale-price> $18.08 </template>
                        <template v-slot:original-price> $59 </template>
                    </saving-card>
                </router-link>
            </div>
            <div class="col col-12 col-md-3">
                <router-link to="/Lisinopril">
                    <saving-card>
                        <template v-slot:name> Lisinopril </template>
                        <template v-slot:label> Zestril </template>
                        <template v-slot:dosage> 30 tablets 20 mg </template>
                        <template v-slot:discount> 63 % </template>
                        <template v-slot:sale-price> $4.49 </template>
                        <template v-slot:original-price> $12 </template>
                    </saving-card>
                </router-link>
            </div>
            <div class="col col-12 col-md-3">
                <router-link to="/Amoxicillin">
                    <saving-card>
                        <template v-slot:name> Amoxicillin </template>
                        <template v-slot:label> Amoxil </template>
                        <template v-slot:dosage> 30 capsules 250 mg </template>
                        <template v-slot:discount> 78 % </template>
                        <template v-slot:sale-price> $5.70 </template>
                        <template v-slot:original-price> $26 </template>
                    </saving-card>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import SavingCard from "./saving-card.vue";
export default {
    name: "featured-savings",
    components: {
        SavingCard,
    },
}
</script>
<style scoped></style>