<template>
  <div
    class="container-fluid text-light py-4"
    style="background-color: var(--primary-color)"
  >
    <div class="row d-flex flex-row align-items-center">
      <div class="col col-12 col-md-6 pl-md-5">
        <h2 style="font-weight:bold;">Don't miss a thing</h2>
        <p>Subscribe To Our Newsletter</p>
      </div>
      <div class="col col-12 col-md-6">
        <form @submit.prevent
          class="d-flex flex-row py-2 justify-content-between"
          style="background-color: var(--primary-color-transparent)"
        >
          <div class="w-100">
            <input
              type="email"
              class="form-control"
              style="
                background-color: var(--primary-color-transparent);
                border: none;
                color: var(--light);
              "
              id="emailInput"
              placeholder="Your email address"
              v-model="email"
            />
            <div
              v-if="
                !validateEmail && email != null && email.length > 0
              "
              class=""
              style="color: var(--warning)"
            >
              Please enter a valid email address
            </div>
          </div>
          <div class="px-2">
            <button
              type="submit"
              class="btn btn-light"
              :disabled="!validateEmail || !termsAndConditionsAndPrivacyPolicy"
              @click="newsletterSignUp()"
            >
              Subscribe
            </button>
          </div>
          <!-- <div class="form-check mb-2">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="termsAndConditionsAndPrivacyPolicy"
              v-model="termsAndConditionsAndPrivacyPolicy"
            />
            <label
              class="form-check-label"
              for="termsAndConditionsAndPrivacyPolicy"
            >
              I agree to the
              <router-link
                to="/terms-of-use"
                style="color: white; text-decoration: underline"
                >Terms and Conditions</router-link
              >
              and
              <router-link
                to="/privacy-policy"
                style="color: white; text-decoration: underline"
                >Privacy Policy</router-link
              >
            </label>
          </div> -->
        </form>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import $ from "jquery";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";
import { requestmixin } from "../../mixins/requestmixin";
import { cookiemixin } from "../../mixins/cookiemixin";
import { errormixin } from "../../mixins/errormixin";
export default {
  name: "newsletter-control",
  mixins: [mixinDetectingMobile, requestmixin, cookiemixin, errormixin],
  data() {
    const d: {
        email: string | null
      mailSent: boolean
      showSpinner: boolean
      mailSendFailure: boolean
      logoURL: string
      termsAndConditionsAndPrivacyPolicy: boolean
    } = {
      email: null,
      mailSent: false,
      showSpinner: false,
      mailSendFailure: false,
      logoURL: "",
      termsAndConditionsAndPrivacyPolicy: true,
    };
    return d;
  },
  methods: {
    newsletterSignUp() {
      this.setEmail();
      this.mailSent = false;
      this.get("/api/newsletter", {
        params: {
          receiverEmail: this.email,
          signUpForNewsletter: true,
        },
      })
        .then(() => {
          this.showSpinner = false;
          //@ts-expect-error
          $("#exampleModalCenter").modal("hide");
          this.mailSent = true;
          this.mailSendFailure = false;
        })
        .catch((err) => {
          this.showSpinner = false;
          //@ts-expect-error
          $("#exampleModalCenter").modal("hide");
          this.mailSent = false;
          this.mailSendFailure = true;
          this.reportError(err);
        });
    },
    readEmail() {
      return this.readCookie("Email");
    },
    setEmail() {
      if (this.email == null || this.email.length < 1) {
        return;
      }
      return this.setCookie("Email", this.email);
    },
  },
  beforeMount() {
    let emailFromCookie = this.readEmail();
    if (emailFromCookie != null && emailFromCookie.length > 0) {
      this.email = emailFromCookie;
    }
  },
  computed: {
    validateEmail() {
      //basic email regex validation
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(this.email).toLowerCase());
    },
  },
};
</script>
<style scoped>
#emailInput::placeholder {
  color: var(--light);
}
</style>