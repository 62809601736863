<template>
    <div>
        <label class="label" :for="name">{{ label }}</label>
        <div class="input-wrapper" :class="getStateClass">
            <!-- https://vuejs.org/guide/components/v-model.html -->
            <!-- @vue-expect-error -->
            <input :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :type="type"
                class="form-control irx-input text-left" :placeholder="placeholder" :disabled="disabled"
                :pattern="pattern" :name="name" :id="name" @keyup.enter="enterMethod()" />
        </div>
        <div v-if="!isValidState" class="error-message">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts">
// Usage of this component to bind to data(){return {sms: ''}}:
// <prc-input v-model="sms" />
// https://vuejs.org/guide/components/v-model.html

export default {
    name: "prc-input",
    props: {
        type: { type: String, default: "text" },
        placeholder: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        pattern: { type: String, default: null },
        errorMessage: { type: String, default: null },
        state: { type: Boolean, default: null },
        modelValue: { type: [String, Number], default: null },
        name: { type: String, default: null },
        label: { type: String, default: null },
        enterMethod: { type: Function, default: null },
    },
    emits: ['update:modelValue'],
    computed: {
        isValidState() {
            if (this.disabled) {
                return true;
            }

            const isValid = this.state || this.modelValue === "" || this.modelValue === null;
            return isValid;
        },
        getStateClass() {
            return {
                "disabled-state": this.disabled,
                "error-state": !this.isValidState,
            };
        },
    },
};
</script>

<style scoped>
.input-wrapper {
    padding: 5px 5px 5px 18px;
    background-color: white;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 0.5px solid #1a191980;
    border-radius: 24px;
    opacity: 1;
}

.input-wrapper input,
.input-wrapper input:active:focus:disabled {
    border: 0;
    box-shadow: none;
}

.error-state {
    border: 0.5px solid #d01313;
}

.error-message {
    margin-left: 10px;
    text-align: left;
    font: Light 12px/15px var(--body-font-family);
    letter-spacing: 0.3px;
    color: #d01313;
    opacity: 1;
}

.input-wrapper input:disabled,
.disabled-state {
    background: #ecf0f1 0% 0% no-repeat padding-box;
}

input:disabled::placeholder {
    color: #b1b3b3;
}

.label {
    padding-left: 10px;
    font: var(--body-font-weight) 14px/17px var(--body-font-family);
    letter-spacing: 0px;
    color: #404040;
    opacity: 1;
}
</style>