<template>
    <div class="">
        <div class="alert alert-success alert-dismissible mail-alert" :class="{ 'show-alert': mailSent || smsSent }">
            <router-link to="#" class="close" @click="closeAlert">&times;</router-link>
            <span class="notification-text">Message sent.</span>
        </div>
        <div class="alert alert-danger alert-dismissible mail-alert"
            :class="{ 'show-alert': mailSendFailure || smsSendFailure }">
            <router-link to="#" class="close text-dark" @click="closeAlert">&times;</router-link>
            <span class="danger-text">Failed to send message.</span>
        </div>
        <div id="couponPrint" class="row coupon-wrapper no-gutters">
            <back-to-previous-page class="col-12 col-md-10 offset-md-1" :returnURL="returnURL"
                backText="Back to Search Results"></back-to-previous-page>
            <h1 class="h2 col-12 col-md-10 offset-md-1 text-center">
                <b class="lead">Acquire an alternative prescription to save</b>
            </h1>
            <div class="col col-12 col-md-10 offset-md-1">
                <div class="card my-2">
                    <div class="container" style="">
                        <div class="row no-gutters px-4 py-4 align-items-end">
                            <div class="col-12">
                                <h2 class="h1 card-title" style="color: var(--primary-color); font-weight: bold">
                                    {{ alternativeMedication.drugName }} Therapeutic Alternative
                                </h2>
                                <p>You may be able to take <router-link
                                        :to="'/' + alternativeMedication.alternativeDrugName"><b>{{ alternativeMedication.alternativeDrugName }}</b></router-link> instead of {{
                                    alternativeMedication.drugName }} to treat your diagnosis. <router-link
                                        :to="'/' + alternativeMedication.alternativeDrugName"><b>{{ alternativeMedication.alternativeDrugName }}</b></router-link> is less expensive than {{
        alternativeMedication.drugName }} - <router-link
                                        :to="'/' + alternativeMedication.alternativeDrugName">see prices here</router-link>.
                                    Unfortunately, taking {{ alternativeMedication.alternativeDrugName }} may not be as safe
                                    or effective as taking {{ alternativeMedication.drugName }}.
                                </p>
                                <p> To get started with {{ alternativeMedication.alternativeDrugName }} you will need a new prescription,
                                    which means contacting your doctor. Alternatively, you
                                    can contact IntelligentRx's pharmacist to discuss your options:</p>
                                    <div class="">
                                        <div class="p-3 mb-3 border rounded"  style="background-color: var(--background-color-light);">
                                            <h3 class="h5">Call IntelligentRx For Help</h3>
                                            <p>
                                                <a class="btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg" href="tel:1-800-484-3487">
                                                    1-800-484-3487
                                                </a>
                                            </p>
                                        </div>
                                        <div class="p-3 mb-3 border rounded"  style="background-color: var(--background-color-light);">
                                            <h3 class="h5">Email IntelligentRx With General Questions</h3>
                                            <contact></contact>
                                        </div>
                                    </div>
                                <hr />
                                <h2 class="h1 card-title" style="color: var(--primary-color); font-weight: bold">
                                    Therapeutic Alternative FAQ
                                </h2>
                                <p><b>What is a therapeutic alternative?</b> There are often multiple prescription
                                    medications which treat a diagnosis. These different medications might be grouped into the
                                    same "drug class", which is a group of drugs which work similarly or treat similar
                                    conditions. It is often (but not always) possible to substitute one drug in a drug class
                                    for another drug in the same drug class, so you can use drug classes as a starting point
                                    to check if there are cheaper Rx treatment options.</p>
                                <p><b>Where can I see other drugs in my medication's drug class?</b> IntelligentRx's drug class tool gives you a drug class for your medication: <router-link to="/irxsavvy/drug-class">IntelligentRx's Drug Class Tool</router-link>.</p>
                                <p><b>Where can I see the cost of different drugs in the same drug class?</b> IntelligentRx's price approximation tool gives you prices for every medication in a drug class: <router-link to="/irxsavvy/drug-class-list">IntelligentRx's Price Approximation Tool</router-link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mixinDetectingMobile } from "../mixins/mixinDetectingMobile";
import { requestmixin } from "../mixins/requestmixin";
import { cookiemixin } from "../mixins/cookiemixin";
import { errormixin } from "../mixins/errormixin";
import BackToPreviousPage from './controls/back-to-previous-page.vue';
import contact from "./controls/contact.vue";

export interface alternativeMedication {
    returnURL: string
    drugName: string
    alternativeDrugName: string
}

export default {
    name: "alternative-medication-page",
    mixins: [
        mixinDetectingMobile,
        requestmixin,
        cookiemixin,
        errormixin,
    ],
    components: {
        BackToPreviousPage,
        contact,
    },
    data() {
        const d: {
            showSpinner: boolean
            returnURL: string
            alternativeMedication: alternativeMedication
        } = {
            showSpinner: false,
            returnURL: "",
            alternativeMedication: {
                returnURL: "",
                drugName: "",
                alternativeDrugName: "",
            },
        };
        return d;
    },
    methods: {
        toggleSpinner() {
            this.showSpinner = !this.showSpinner;
        },
    },
    beforeMount() {
        this.alternativeMedication = this.$route.query;
        this.returnURL = this.alternativeMedication.returnURL;
    },
    created() {
        let noIndex = document.querySelector('head meta[name="robots"]');
        if (noIndex == null) {
            let headEl = document.querySelector("head");
            if (headEl == null) return;
            headEl.insertAdjacentHTML(
                "beforeend",
                '<meta name="robots" content="noindex">'
            );

        }
        else noIndex.setAttribute("content", "noindex");
    },
    mounted() {
    },
    computed: {
    },
};
</script>

<style scoped>
.irx-btn-primary-animated:hover,
.irx-btn-primary-animated:focus,
.irx-btn-primary-animated:active,
.irx-btn-primary-animated.active,
.open>.dropdown-toggle.irx-btn-primary-animated {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.alert-success {
    background: #51a74e 0% 0% no-repeat padding-box;
    border: 1px solid #0da23b;
    opacity: 1;
}

.notification-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    color: #ffffff;
    opacity: 1;
    margin-left: 8.33%;
}

.danger-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    opacity: 1;
    margin-left: 8.33%;
}

.alert-dismissible .close {
    color: #ffffff;
    opacity: 1;
}


.coupon-wrapper {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px 0 60px 0;
}

.mail-alert {
    display: none;
}

.show-alert {
    display: block !important;
}

@media (max-width: 992px) {
    .coupon-wrapper {
        padding: 16px 15px 80px 15px;
    }

    .mail-alert {
        padding: 5px 40px 5px 15px;
        margin: 0;
    }

    .notification-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin: 0;
    }

    .danger-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        opacity: 1;
        margin: 0;
    }

    .mail-alert .close {
        padding-top: 5px;
    }
}
</style>