<template>
    <div class="container-fluid py-5">
        <div class="row pb-5">
            <div class="col col-12">
                <h1 class="text-center display-4">IntelligentRx Coupon API</h1>
                <h2 class="text-center h3 pb-2">Up To 100% Pass Through</h2>
            </div>
            <div class="col col-12 col-md-6 offset-md-3">
                <p class="pitch-font">In support of our mission to increase access to affordable prescription medications, we're
                    pleased to
                    announce a new product: An API for partners to access our prices in real time for use in their own
                    member programs.</p>
            </div>
        </div>

        <div class="row pitch-background-light">
            <div class="col col-12 pb-5">
                <h2 class="text-center display-4 text-dark">Revenue and Cost Options</h2>
            </div>
            <div class="col col-12 col-md-3 offset-md-2 pt-3">
                <div class="card border-light">
                    <div class="card-body">
                        <h3 class="text-center h1">100% Pass Through</h3>
                        <h3 class="text-center h1"> + API Fee</h3>
                        <p class="pitch-font">Our 100% Pass Through model gives you 100% of the revenue we receive from our
                            PBM partners when a provided group number and member ID is used. To cover the cost of
                            providing this service, we charge a fee of $1 per 100 API requests. Typical average
                            revenue per dispensed prescription is between $2.75 and $3.00.
                        </p>
                        <router-link to="/api-contact" class="btn btn-lg btn-primary">Get
                            Started&nbsp;&nbsp;
                            <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                        </router-link>
                        <br />
                        <a href="/openapi/intelligentrxcom-public.html#tag/PassThrough" class="btn btn-lg btn-secondary mt-2">See
                            Documentation&nbsp;
                            <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col col-12 col-md-3 offset-md-1 pt-3">
                <div class="card border-light">
                    <div class="card-body">
                        <h3 class="text-center h1">95% Pass Through</h3>
                        <p class="pitch-font">Our 95% Pass Through model gives you 95% of the revenue we receive from our
                            PBM partners when a provided group number and member ID is used.
                            We retain the remaining 5% of revenue to cover our costs. Typical average
                            revenue per dispensed prescription is between $2.65 and $3.00.
                        </p>
                        <router-link to="/api-contact" class="btn btn-lg btn-primary">Get
                            Started&nbsp;&nbsp;
                            <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                        </router-link>
                        <br />
                        <a href="/openapi/intelligentrxcom-public.html#tag/PassThrough" class="btn btn-lg btn-secondary mt-2">See
                            Documentation&nbsp;
                            <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row py-5 pitch-background-default">
            <div class="col col-12 py-4">
                <h2 class="text-center display-4 pb-2">Why Partner with IntelligentRx?</h2>
            </div>
            <div class="col col-12 col-md-6 offset-md-3">
                <ul class="pitch-font">
                    <li>PBMs can't collude to lower prices at the pharmacy, which means PBMs can't bypass us to connect
                        directly to our partners</li>
                    <li>There aren't many potential partners who can connect a customer to a large number of partner PBMs. We get prices from 12 PBMs. As far as we know, our only competitor which connects to that many PBMs is GoodRx</li>
                    <li>IntelligentRx shares more revenue with you than GoodRx (it's hard to beat 100%)</li>
                    <li>We make it stupid-simple to use our service, which lowers your integration costs</li>
                    <li>Our service works, and most requests return in less than 200 milliseconds</li>
                </ul>
            </div>
            <div class="col col-12 py-4">
                <h2 class="text-center display-4 pb-2">Why Partner with a Competitor?</h2>
            </div>
            <div class="col col-12 col-md-6 offset-md-3">
                <ul class="pitch-font">
                    <li>Partnering with GoodRx is better for Public Relations because they have a larger brand presence</li>
                </ul>
            </div>
            <div class="col col-12 col-md-6 offset-md-3">
                <p class="pitch-font">Of course you could partner with GoodRx to get a PR boost, put out a nice press release explaining how the program lowers costs for members, and simultaneously connect to IntelligentRx's API to use our partner's prices whenever our prices are more advantageous for you.</p>
                <router-link to="/api-contact" class="btn btn-lg btn-primary">Get
                    Started&nbsp;&nbsp;
                    <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'coupon-api',
}
</script>


<style scoped>
.pitch-background-default {
    padding-top: 120px;
    padding-bottom: 90px;
}

.pitch-background-light {
    background: var(--background-color-grey);
    padding-top: 120px;
    padding-bottom: 90px;
}

@media (max-width: 992px) {
    .pitch-background-default {
        padding-top: 40px;
        padding-bottom: 30px;
    }

    .pitch-background-light {
        padding-top: 40px;
        padding-bottom: 30px;
    }
}
</style>

<style scoped></style>