<template>
    <div class="container-fluid py-5">

        <div class="alert alert-success alert-dismissible mail-alert" :class="{ 'show-alert': mailSent }">
            <router-link to="#" class="close" @click="closeAlert()">&times;</router-link>
            <span class="notification-text text-center">Sent successfully</span>
        </div>
        <div class="alert alert-danger alert-dismissible mail-alert" :class="{ 'show-alert': mailSendFailure }">
            <router-link to="#" class="close text-dark" @click="closeAlert()">&times;</router-link>
            <span class="danger-text text-center">Failed to send. Please reload the page and try again. If the
                problem
                persists, please email info@intelligentrx.com</span>
        </div>

        <div class="row pb-5">
            <div class="col col-12">
                <h1 class="text-center display-4">Get Started with the IntelligentRx Coupon API</h1>
            </div>
        </div>

        <div class="row pb-5">
            <div class="col col-12 col-md-8 offset-md-2">
                <div class="card border-light">
                    <form class="card-body" @submit.prevent>
                        <p>Our team will reach out to help you get started.</p>
                        <div class="form-group">
                            <label for="emailInput">Email</label>
                            <input type="email" class="form-control" id="emailInput" v-model="email"
                                style="background-color: var(--background-color-gray); color: black" />
                            <div v-if="!validateEmail" class="error-message">
                                Please enter a valid email address
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="messageInput">Message (optional)</label>
                            <textarea class="form-control" id="messageInput" rows="3" v-model="message"
                                style="background-color: var(--background-color-gray); color: black"></textarea>
                        </div>
                        <button type="submit" class="btn irx-btn-primary w-100"
                            style="background-color: var(--primary-color);border-radius: 20px !important;"
                            :disabled="!validateEmail" @click="sendEmail">
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { errormixin } from "../../mixins/errormixin";
import { requestmixin } from "../../mixins/requestmixin";
export default {
    name: "api-contact",
    mixins: [requestmixin, errormixin],
    data() {
        const d: {
            name: string | null
            email: string | null
            message: string | number | string[] | undefined
            mailSent: boolean
            mailSendFailure: boolean
            smsSent: boolean
            smsSendFailure: boolean
        } = {
            name: null,
            email: null,
            message: undefined,
            mailSent: false,
            mailSendFailure: false,
            smsSent: false,
            smsSendFailure: false
        };
        return d;
    },
    methods: {
        sendEmail() {
            this.post("/api/ecsprice0", {
                subject: "New Message from " + this.name,
                emailBody: this.message,
                receiverEmail: this.email,
            })
                .then(() => {
                    this.name = "";
                    this.email = "";
                    this.message = "";
                    this.mailSent = true;
                    this.mailSendFailure = false;
                })
                .catch((err) => {
                    this.reportError(err);
                    this.mailSent = false;
                    this.mailSendFailure = true;
                });
        },
        closeAlert() {
            this.mailSent = false;
            this.mailSendFailure = false;
            this.smsSent = false;
            this.smsSendFailure = false;
        },
    },
    computed: {
        validateEmail() {
            //basic email regex validation
            const regex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(String(this.email).toLowerCase());
        },
    },
};
</script>
<style scoped>
.heading-font {
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
}

.section-title {
    margin-bottom: 15px;
}

.error-message {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.error-message ::after,
::before {
    box-sizing: border-box;
}

.alert-success {
    background: #51a74e 0% 0% no-repeat padding-box;
    border: 1px solid #0da23b;
    opacity: 1;
}

.notification-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    color: #ffffff;
    opacity: 1;
}

.danger-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    opacity: 1;
}

.alert-dismissible .close {
    color: #ffffff;
    opacity: 1;
}

.mail-alert {
    display: none;
}

.show-alert {
    display: block !important;
}

@media (max-width: 992px) {
    .mail-alert {
        padding: 5px 40px 5px 15px;
        margin: 0;
    }

    .notification-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }

    .danger-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        opacity: 1;
    }

    .mail-alert .close {
        padding-top: 5px;
    }
}
</style>
