<template>
    <a v-if="to" :href="to" :type="type" v-bind="attrs" exact>
        <slot />
    </a>
    <!-- @vue-expect-error -->
    <button v-else :type="type" @click="clicked" v-bind="attrs" :aria-label="ariaLabel">
        <div class="align-items-center">
            <slot />
            <div v-if="showCaret" :name="caretName" class="caret" />
        </div>
    </button>
</template>

<script lang="ts">
import { htmlClassConstants } from "../../constants";

export default {
    name: "prc-button",
    props: {
        type: { type: String, default: "" },
        ariaLabel: { type: String, default: "" },

        // if this property is applied the css selection in the parent element should be prefixed with '.prc-btn'
        // for example: in parent element <prc-button additional-class="danger"/> .prc-btn.danger { color:red }
        showCaret: { type: Boolean, default: false },
        caretDirection: { type: String, default: "right" },
        to: { type: [String, Object], default: null },
        disabled: { type: Boolean, default: false },
    },
    emits: ['click'],
    computed: {
        attrs() {
            return {
                ...this.$attrs,
                ...{
                    tag: this.to ? "a" : null,
                    disabled: this.disabled,
                    class: this.classes,
                    role: "button",
                },
            };
        },
        classes: function () {
            var innerClass = "";
            switch (this.type) {
                case "info":
                    innerClass = "prc-btn-info";
                    break;
                case "empty":
                    innerClass = "prc-btn-empty";
                    break;
                case "emphasis":
                    innerClass = "prc-btn-emphasis";
                    break;
                default:
                    break;
            }
            return {
                ["prc-btn"]: true,
                [innerClass]: true,
                ["prc-btn-flex"]: this.showCaret,
            };
        },
        caretName: function () {
            return htmlClassConstants.caretPrefix + "-" + this.caretDirection;
        },
    },
    methods: {
        clicked: function () {
            this.$emit("click", this);
        },
    },
};
</script>

<style scoped>
.prc-btn {
    width: 100%;
    text-align: center;
    padding: 12px 40px;
}

.prc-btn:focus {
    outline: 0;
}

.prc-btn-info {
    background: var(--primary-color) 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 1px #00000029;
    border-radius: 24px;
    opacity: 1;
    font: Bold 19px/23px var(--body-font-family);
    letter-spacing: 0px;
    color: #f5f5f5;
    border: 0;
}

.prc-btn-info:hover {
    background: var(--background-color-light-emphasis) 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 1px #00000029;
    border-radius: 24px;
    opacity: 1;
}

.prc-btn-info:disabled {
    background: #a2b3ba 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
}

.prc-btn-emphasis {
    color: var(--background-color-attention-emphasis-text-color);
    background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 1px #00000029;
    border-radius: 24px;
    opacity: 1;
    font: Bold 19px/23px var(--body-font-family);
    letter-spacing: 0px;
    color: var(--background-color-attention-emphasis-text-color);
    border: 0;
    margin: 2px;
}

.prc-btn-emphasis:hover {
    color: var(--background-color-attention-emphasis-text-color);
    background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 1px #00000029;
    border-radius: 24px;
    opacity: 1;
}

.prc-btn-emphasis:disabled {
    background: #a2b3ba 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
}

.prc-btn-empty {
    text-align: center;
    font: Bold 19px/16px var(--body-font-family);
    letter-spacing: 0px;
    color: var(--primary-color);
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 3px #4f5e7429;
    border: 2px solid var(--primary-color);
    border-radius: 24px;
    opacity: 1;
}

.prc-btn-empty:hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 3px #4f5e7429;
    border: 2px solid #007BFF;
    border-radius: 24px;
    opacity: 1;
    color: #007BFF;
    text-decoration: none;
}

.prc-btn-empty:disabled {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #a2b3ba;
    border-radius: 24px;
    opacity: 1;
    color: #a2b3ba;
}

.prc-btn-empty:focus {
    outline: 0;
}

.caret {
    color: var(--primary-color);
    width: 8px;
    height: 12px;
}

.prc-btn-flex {
    display: flex;
    justify-content: space-between;
    padding: 2px 0;
}

@media (max-width: 992px) {
    .prc-btn {
        padding: 12px 14px;
    }
}</style>