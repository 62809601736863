import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c5b0d20"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "card-content-wrapper px-4 py-4",
  style: {"background-color":"#fff"}
}
const _hoisted_2 = {
  class: "text-center pb-2",
  style: {"color":"var(--primary-color)","font-size":"14px"}
}
const _hoisted_3 = {
  class: "subsection-title",
  style: {"font-weight":"var(--heading-font-weight)","font-size":"24px"}
}
const _hoisted_4 = {
  class: "font-label text-center mt-10",
  style: {"font-size":"20px"}
}
const _hoisted_5 = {
  class: "font-inform mt-10",
  style: {"font-size":"16px"}
}
const _hoisted_6 = {
  class: "font-sale-price mt-30",
  style: {"font-size":"32px","color":"var(--primary-color)"}
}
const _hoisted_7 = {
  class: "font-inform mt-3",
  style: {"font-size":"17px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "discount", {}, undefined, true),
      _createTextVNode(" Saved ")
    ]),
    _createElementVNode("h3", _hoisted_3, [
      _renderSlot(_ctx.$slots, "name", {}, undefined, true),
      _createTextVNode(" Discount Savings ")
    ]),
    _createElementVNode("h3", _hoisted_4, [
      _renderSlot(_ctx.$slots, "label", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "dosage", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "sale-price", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("del", null, [
        _renderSlot(_ctx.$slots, "original-price", {}, undefined, true)
      ])
    ])
  ]))
}