<template>
  <div class="card-content-wrapper px-4 py-4" style="background-color: #fff">
    <div
      class="text-center pb-2"
      style="color: var(--primary-color); font-size: 14px"
    >
      <slot name="discount"></slot> Saved
    </div>
    <h3 class="subsection-title" style="font-weight: var(--heading-font-weight); font-size: 24px">
      <slot name="name"></slot> Discount Savings
    </h3>
    <h3 class="font-label text-center mt-10" style="font-size: 20px">
      <slot name="label"></slot>
    </h3>
    <div class="font-inform mt-10" style="font-size: 16px">
      <slot name="dosage"></slot>
    </div>
    <div
      class="font-sale-price mt-30"
      style="font-size: 32px; color: var(--primary-color)"
    >
      <slot name="sale-price"></slot>
    </div>
    <div class="font-inform mt-3" style="font-size: 17px">
      <del>
        <slot name="original-price"></slot>
      </del>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "saving-card",
};
</script>

<style scoped>
.card-content-wrapper {
  margin-bottom: 60px;
}

@media (max-width: 992px) {
  .card-wrapper {
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 20px;
  }
}
</style>