<template>
    <div class="">
        <div class="alert alert-success alert-dismissible mail-alert" :class="{ 'show-alert': mailSent || smsSent }">
            <router-link to="#" class="close" @click="closeAlert">&times;</router-link>
            <span class="notification-text">Message sent.</span>
        </div>
        <div class="alert alert-danger alert-dismissible mail-alert"
            :class="{ 'show-alert': mailSendFailure || smsSendFailure }">
            <router-link to="#" class="close text-dark" @click="closeAlert">&times;</router-link>
            <span class="danger-text">Failed to send message.</span>
        </div>
        <div id="couponPrint" class="row coupon-wrapper no-gutters">
            <back-to-previous-page class="col-12 col-md-10 offset-md-1" :returnURL="returnURL" backText="Back to Search Results"></back-to-previous-page>
            <h1 class="h2 col-12 col-md-10 offset-md-1 text-center">
                <b class="lead">Acquire a manufacturer coupon and show it to your pharmacy to save</b>
            </h1>
            <div class="col col-12 col-md-10 offset-md-1">
                <div class="card my-2">
                    <div class="container" style="">
                        <div class="row no-gutters px-4 py-4 align-items-end">
                            <div class="col-12">
                                <h2 class="h1 card-title" style="color: var(--primary-color); font-weight: bold">
                                    {{ manufacturerCoupon.drugName }} Manufacturer Coupon
                                </h2>
                                <p><b>You can't apply for a {{ manufacturerCoupon.drugName }} Manufacturer Coupon on IntelligentRx.com. Please use an alternative option:</b></p>
                                <div class="">
                                    <div class="p-3 mb-3 border rounded"  style="background-color: var(--background-color-light);">
                                        <h3 class="h5">Self-Service: Visit Manufacturer's Website</h3>
                                        <p>Navigate {{ manufacturerCoupon.drugName }}'s website to apply for a manufacturer coupon / copay assistance / patient assistance program.</p>
                                        <p>
                                            <a class="btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg" :href="manufacturerCoupon.manufacturerURL">
                                                Go To {{ manufacturerCoupon.drugName }}'s Website
                                            </a>
                                        </p>
                                    </div>
                                    <div class="p-3 mb-3 border rounded"  style="background-color: var(--background-color-light);">
                                        <h3 class="h5">Enroll Via Prescription Hope</h3>
                                        <p>Enroll for $60 / Month in Prescription Hope to pay $0 / month on your copay. Restrictions on income and insurance type apply.</p>
                                        <p>
                                            <a class="btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg" href="https://prescriptionhope.com/">
                                                Prescription Hope
                                            </a>
                                        </p>
                                    </div>
                                    <div class="p-3 mb-3 border rounded"  style="background-color: var(--background-color-light);">
                                        <h3 class="h5">Call IntelligentRx For Help</h3>
                                        <p>
                                            <a class="btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg" href="tel:1-800-484-3487">
                                                1-800-484-3487
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                
                                <hr />
                                <h2 class="h1 card-title" style="color: var(--primary-color); font-weight: bold">
                                    Manufacturer Coupon FAQ
                                </h2>
                                <p><b>What is a manufacturer coupon?</b> It is a coupon-like discount on a prescription medication which can be redeemed at your pharmacy. The coupon lowers a patient's out of pocket cost.</p>
                                <p><b>Who can use a manufacturer coupon?</b> It depends. Usually only commercially-insured patients can use them. This means you can't use them if you receive health insurance via a federal or state program like Medicare, Medicaid, TRICARE, Department of Defense, or Veterans Administration. These programs are usually excluded because the program may violate federal anti-kickback laws. Additional restrictions may apply. The eligibility requirements vary on a product-by-product basis.</p>
                                <p><b>Why do manufacturers give away their products?</b> Manufacturers still make money when you use a manufacturer coupon because they continue to bill your insurance company for their portion of the bill - without a discount. Manufacturers reduce patient's bills because <a href="https://www.iqvia.com/insights/the-iqvia-institute/reports-and-publications/reports/medicine-spending-and-affordability-in-the-us#:~:text=A%20growing%20public%20health%20concern%20is%20patients%20abandoning%20their%20new%20prescriptions%20and%20not%20following%20a%20physician%E2%80%99s%20recommendation%20for%20care.%20Abandonment%20occurs%20much%20more%20often%20when%20patient%E2%80%99s%20have%20higher%20out%2Dof%2Dpocket%20cost.">higher out-of-pocket patient costs cause much higher abandonment rates</a>, and if you abandon a medication then they get paid nothing. Manufacturer's thinking seems to be: it's better to get paid something than to get nothing.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mixinDetectingMobile } from "../mixins/mixinDetectingMobile";
import { requestmixin } from "../mixins/requestmixin";
import { cookiemixin } from "../mixins/cookiemixin";
import { errormixin } from "../mixins/errormixin";
import BackToPreviousPage from './controls/back-to-previous-page.vue';

export interface manufacturerCoupon {
    returnURL: string
    drugName: string
    manufacturerURL: string
}

export default {
    name: "manufacturer-coupon-page",
    mixins: [
        mixinDetectingMobile,
        requestmixin,
        cookiemixin,
        errormixin,
    ],
    components: {
        BackToPreviousPage,
    },
    data() {
        const d: {
            showSpinner: boolean
            returnURL: string
            manufacturerCoupon: manufacturerCoupon
        } = {
            showSpinner: false,
            returnURL: "",
            manufacturerCoupon: {
                returnURL: "",
                drugName: "",
                manufacturerURL: "",
            },
        };
        return d;
    },
    methods: {
        toggleSpinner() {
            this.showSpinner = !this.showSpinner;
        },
    },
    beforeMount() {
        this.manufacturerCoupon = this.$route.query;
        this.returnURL = this.manufacturerCoupon.returnURL;
    },
    created() {
        let noIndex = document.querySelector('head meta[name="robots"]');
        if (noIndex == null) {
            let headEl = document.querySelector("head");
            if (headEl == null) return;
            headEl.insertAdjacentHTML(
                "beforeend",
                '<meta name="robots" content="noindex">'
            );

        }
        else noIndex.setAttribute("content", "noindex");
    },
    mounted() {
    },
    computed: {
    },
};
</script>

<style scoped>
.irx-btn-primary-animated:hover,
.irx-btn-primary-animated:focus,
.irx-btn-primary-animated:active,
.irx-btn-primary-animated.active,
.open>.dropdown-toggle.irx-btn-primary-animated {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.alert-success {
    background: #51a74e 0% 0% no-repeat padding-box;
    border: 1px solid #0da23b;
    opacity: 1;
}

.notification-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    color: #ffffff;
    opacity: 1;
    margin-left: 8.33%;
}

.danger-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    opacity: 1;
    margin-left: 8.33%;
}

.alert-dismissible .close {
    color: #ffffff;
    opacity: 1;
}


.coupon-wrapper {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px 0 60px 0;
}

.mail-alert {
    display: none;
}

.show-alert {
    display: block !important;
}

@media (max-width: 992px) {
    .coupon-wrapper {
        padding: 16px 15px 80px 15px;
    }

    .mail-alert {
        padding: 5px 40px 5px 15px;
        margin: 0;
    }

    .notification-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        margin: 0;
    }

    .danger-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        opacity: 1;
        margin: 0;
    }

    .mail-alert .close {
        padding-top: 5px;
    }
}
</style>