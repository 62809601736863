import { requestmixin } from './requestmixin';
import { errormixin } from './errormixin';
import { mixinDetectingMobile } from "./mixinDetectingMobile";
export const ipapimixin = {
    methods: {
        // zipFromIP is how you get a zip code if getZipCodeCache returns null.
        zipFromIP: function () {
            // Problem: Mobile network IP address geolocation is awful
            // Partial solution: Default to 92103 if you are not on wifi or ethernet.
            if ('connection' in navigator && navigator.connection.type != null)
            {
                // This only works on Android Chrome
                // https://caniuse.com/?search=navigator.connection
                if (navigator.connection.type !== 'wifi' && navigator.connection.type !== 'ethernet')
                {
                    // if we made a call to /api/ecsipapi0 we could not trust the results
                    // therefore, do not make a call.
                    return;
                }
            }
            else if (mixinDetectingMobile.methods.isMobile())
            {
                // I wish there were a better way to detect mobile network usage but there doesn't seem to be.
                return;
            }
            requestmixin.methods.get('/api/ecsipapi0', {
                params: {
                    '1': '1',
                },
            })
                .then(res => {
                    if (res == null || res.data == null) {
                        return;
                    }
                    if (res.data.zip != null && res.data.zip !== '' && res.data.zip.length > 2 && res.data.zip.length < 7) {
                        this.setZipCodeCache(res.data.zip);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        // Server responded with non-2xx response
                        errormixin.methods.reportError(error.response.data);
                        errormixin.methods.reportError(error.response.status);
                        errormixin.methods.reportError(error.response.headers);
                    } else if (error.request) {
                        // no response was received from request
                        errormixin.methods.reportError(error.request);
                    } else {
                        // error setting up the request
                        errormixin.methods.reportError(error.message);
                    }
                })
        },
        getZipCodeCache() {
            var cookies = document.cookie;
            if (cookies == '' || cookies == null) {
                return null;
            }
            let splitCookies = cookies.split('; ');
            if (splitCookies != null) {
                let foundCookies = splitCookies.find(row => row.startsWith('ZipCodeSearched='));
                if (foundCookies != null) {
                    return foundCookies.split('=')[1];
                }
            }
        },
        setZipCodeCache(zipCode) {
            if (zipCode == null) {
                return;
            }
            var d = new Date();
            d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
            var expires = "expires=" + d.toUTCString();
            document.cookie = "ZipCodeSearched=" + zipCode + ';' + expires + ';path=/;';
        }
    }
}
