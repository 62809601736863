<template>
    <!-- I'm making a clean isMobile !isMobile distinction because on Desktop this is all in a single row and on mobile it's much more complicated -->
    <div :class="searchDrugResultItemHighlighted">
        <div class="col col-12 col-md-6 my-md-4 py-3" v-if="buttonText != null && buttonText.length > 0">
            <h2 class="pharmacy-name mb-0">
                {{ leftText }}
            </h2>
        </div>

        <div class="
                col col-12 col-md-6
            " v-if="buttonText != null && buttonText.length > 0">
            <a class="btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg" :href="buttonHref">
                {{ buttonText }}
            </a>
        </div>

        <div v-else class="col col-12 py-3">
            <h2 class="pharmacy-name mb-0">
                {{ leftText }}
            </h2>
        </div>
    </div>
</template>
  
<script lang="ts">
import { cookiemixin } from "../../mixins/cookiemixin";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";

export default {
    name: "search-drug-result-item-highlighted",

    components: {},
    mixins: [cookiemixin, mixinDetectingMobile],

    props: {
        leftText: String,
        buttonText: String,
        buttonHref: String,
        warning: Boolean,
    },
    data() {
        return {
        };
    },

    methods: {
    },
    mounted() {
    },
    computed: {
        searchDrugResultItemHighlighted() {
            let isWarning = this.warning != null && this.warning;
            let b = 'row align-items-center h-100 result-row';
            if (isWarning)
            {
                b += ' bg-warning';
            }
            return b;
        }
    }
};
</script>
  
<style scoped>
.result-row {
    padding: 15px 30px;
    border-bottom: #f5f5f5 1.5px solid;
}

.pharmacy-name {
    text-align: left;
    font-weight: var(--header-font-weight);
    font-family: var(--header-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
    font-weight: 600;
    font-size: 21px;
    line-height: 22px;
    letter-spacing: 0px;
}

.irx-btn-primary-animated:hover,
.irx-btn-primary-animated:focus,
.irx-btn-primary-animated:active,
.irx-btn-primary-animated.active,
.open>.dropdown-toggle.irx-btn-primary-animated {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

@media (max-width: 998px) {
}

@media (max-width: 992px) {
    .result-row {
        padding: 10px 30px;
        border-bottom: #f5f5f5 1.5px solid;
    }

    .pharmacy-name {
        font-size: 18px;
        line-height: 30px;
    }

    .row {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>