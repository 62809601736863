<template>
  <div class="row">
      <div class="col col-12 col-md-6 d-flex" style="overflow: hidden; justify-content:center;">
          <div class="how-it-works-image-overlay">
              <img src="../../assets/how-it-works-with-pharmacist-and-patient.webp" class="how-it-works-image" alt="Discussing Prescription Discounts with a Pharmacist" />
          </div>
      </div>
      <div class="col col-12 col-md-6">
          <div class="container-fluid">
            <div class="row">
                <div class="col col-12 pt-4">
                    <h2 style="font-weight: var(--heading-font-weight); text-align: left;">How <span style="color: var(--primary-color);">IntelligentRx</span> Works</h2>
                </div>
                <ol class="col col-12">
                    <li class="row pt-3">
                        <div class="left-num col col-3 text-left"><h3 style="font-weight: var(--heading-font-weight); line-height: 44px;">01</h3></div>
                        <div class="col col-9 text-left">
                            <h4 class="h5" style="font-weight: var(--heading-font-weight);">Search</h4>
                            <p>Search for prescription prices using our online pricing tool.</p>
                        </div>
                    </li>
                    <li class="row pt-3">
                        <div class="left-num col col-3 text-left"><h3 style="font-weight: var(--heading-font-weight); line-height: 44px;">02</h3></div>
                        <div class="col col-9 text-left">
                            <h4 class="h5" style="font-weight: var(--heading-font-weight);">Compare</h4>
                            <p>Compare prices from local pharmacies and get smart prescription coupons.</p>
                        </div>
                    </li>
                    <li class="row pt-3">
                        <div class="left-num col col-3 text-left"><h3 style="font-weight: var(--heading-font-weight); line-height: 44px;">03</h3></div>
                        <div class="col col-9 text-left">
                            <h4 class="h5" style="font-weight: var(--heading-font-weight);">Save</h4>
                            <p>Show the smart coupon to the pharmacy staff and save up to 85%</p>
                        </div>
                    </li>
                </ol>
            </div>
          </div>
      </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "how-it-works-steps",
};
</script>

<style scoped>
.subsection-title {
  margin-top: 29px;
  margin-bottom: 12px;
}

.how-it-works-image-overlay {
    background-color: var(--primary-color);
    width: fit-content;
    height: fit-content;
    border-style: hidden;
    border-radius: 10px;
}

.how-it-works-image {
    opacity: 0.70;
}

.left-num {
    color: var(--primary-color);
    font-size: 36px;
}

@media (max-width: 992px) {
  .step {
    margin-top: 35px;
  }
}
</style>