import axios from 'axios';
export const searchDrugNames = {
    methods: {
        // queryDrugName for a drug prefix and limit the number of results with the integer limit.
        // return an array of results.
        queryDrugName: function (query, limit) {
            // first, let's try to get a result from the remote server
            // console.log(query);
            let u = '/api/drugname?drugName='+encodeURIComponent(query);
            if (location.hostname.includes('localhost')) {
                u = 'http://localhost:5000' + u;
            }
            return axios.get(u, {
                timeout: 1000, // short timeout; we expect results *NOW*
            })
            .then((res) => {
                // console.log(res);
                if (res.data == null || res.data.drugNames == null) {
                    // can happen if HTML is returned
                    throw('html was returned not json response')
                }
                if (res.status != 200) {
                    throw(res.statusText);
                }
                let r = res.data;
                if (r.error != null) {
                    throw(r.error);
                }
                if (r.drugNames.length > limit) {
                    r.drugNames = r.drugNames.slice(0, limit);
                }
                return this.drugNamesToTitleCase(r.drugNames);
            })
            .catch((error) => {
                console.error('error searching drug names: ' + error);
                var arr = [];
                return this.drugNamesToTitleCase([...arr]); // clone / copy array to prevent bugs where the caller modifies the array
            });
        },
        // drugNamesToTitleCase converts the array of items to title case
        drugNamesToTitleCase(arr) {
            // make the start of each 'word' upper case and all other characters lower case
            for (let i = 0; i < arr.length; i++) {
                let nextIsUpperCase = true;
                let newString = [];
                arr[i] = arr[i].toLowerCase();
                for (let c = 0; c < arr[i].length; c++) {
                // adjust this logic so that if something is:
                // DRUG (IR)
                // It is translated to Drug (IR)
                // AVOID translating to: Drug (Ir)
                let newChar = arr[i][c];
                if (nextIsUpperCase && arr[i][c].match(/[a-z]+$/i)) {
                    newChar = arr[i][c].toUpperCase();
                    nextIsUpperCase = false;
                }
                if (!arr[i][c].match(/[a-z0-9]+$/i)) nextIsUpperCase = true;
                newString.push(newChar);
                }
                arr[i] = newString.join("");
                if (arr[i].toUpperCase().endsWith(" IR")) {
                arr[i] = arr[i].substring(0, arr[i].length - 2) + "IR";
                }
                if (arr[i].toUpperCase().endsWith(" ER")) {
                arr[i] = arr[i].substring(0, arr[i].length - 2) + "ER";
                }
                if (arr[i].toUpperCase().endsWith("(IR)")) {
                arr[i] = arr[i].substring(0, arr[i].length - 4) + "IR";
                }
                if (arr[i].toUpperCase().endsWith("(ER)")) {
                arr[i] = arr[i].substring(0, arr[i].length - 4) + "ER";
                }
            }
            return arr;
        }
    }
}
export default searchDrugNames;