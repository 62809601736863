export const routerMixin = {
    methods: {
        // ONLY use this for a single update - otherwise use updateRouterToMany
        updateRouterTo: function (k, v) {
            if (this.$route.query[k] != v) {
                var queryReplace = {};
                queryReplace[k] = v;
                this.$router
                    .replace({
                        query: Object.assign({}, this.$route.query, queryReplace),
                        params: { savePosition: true },
                        hash: this.$route.hash,
                    })
                    .catch((err) => {
                        if (err.name != "NavigationDuplicated") {
                            throw err;
                        }
                    });
            }
        },
        // keyValuePairs is an object like {key: 'value'}
        updateRouterToMany: function(keyValuePairs) {
            this.$router.replace({
                query: Object.assign({}, this.$route.query, keyValuePairs),
                params: { savePosition: true },
                hash: this.$route.hash,
            })
            .catch((err) => {
                if (err.name != "NavigationDuplicated") {
                    throw err;
                }
            });
        }
    }
};