<template>
    <div class="row no-gutters irx-grey-background">
        <div class="col col-12 col-md-10 offset-md-1">
            <h1 class="text-center h2">Reimburse Me</h1>
            <div class="card my-2">
                <div class="container">
                    <div class="row no-gutters px-4 py-4 align-items-end">
                        <div class="col-12">
                            <h2 class="h1" style="color: var(--primary-color); font-weight: bold;">Medical Insurance
                                Reimbursement
                                Help</h2>
                            <p>
                                When paying for a prescription out of pocket with IntelligentRx's discount cards, you aren't
                                using your insurance. Sometimes your insurance company will reimburse you for these payments
                                or view the service as out-of-network and apply the payment against your deductible. Either
                                way, applying for reimbursement from your insurance can be well worth the 5 minutes it
                                takes.
                            </p>
                            <p>To apply for reimbursement, follow these steps:</p>
                            <ol>
                                <li>Get receipts for the service</li>
                                <li>Make copies of your receipts for your own records (pictures with your phone camera may
                                    work)
                                </li>
                                <li>Fill out a reimbursement form from your health insurer</li>
                                <li>Mail or fax the form to your health insurer</li>
                            </ol>
                            <p>Contact information for the most common insurers are below</p>
                            <div class="row">
                                <div class="col col-6 col-md-4 pb-3" v-for="reimbursement in reimbursementInfos"
                                    :key="reimbursement.InsurerName">
                                    <div class="card">
                                        <div class="card-body" style="">
                                            <p>{{ reimbursement.InsurerName }}</p>
                                            <p>{{ reimbursement.AddressRow0 }}</p>
                                            <p>{{ reimbursement.AddressRow1 }}</p>
                                            <p>{{ reimbursement.AddressRow2 }}</p>
                                            <p v-if="reimbursement.FaxNumber != null && reimbursement.FaxNumber !== ''">{{
                                                reimbursement.FaxNumber }}</p>
                                            <a :href="reimbursement.FormLink" class="btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg">{{ reimbursement.InsurerName }} Form</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { errormixin } from "../mixins/errormixin";
import { requestmixin } from "../mixins/requestmixin";
export interface ReimbursementInfo {
    InsurerName: string
    AddressRow0: string
    AddressRow1: string
    AddressRow2: string
    FaxNumber: string
    FormLink: string
}
export default {
    name: "reimburse-me",
    mixins: [requestmixin, errormixin],
    data() {
        const d: {
            reimbursementInfos: ReimbursementInfo[]
        } = {
            reimbursementInfos: [
                {
                    InsurerName: "Aetna",
                    AddressRow0: "Aetna Pharmacy Management",
                    AddressRow1: "P.O. Box 52444",
                    AddressRow2: "Phoenix, AZ 85072-2444",
                    FaxNumber: "1-888-472-1128",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/2bRjiWYzR4i4NpP3XjGePw/ce99e46333628e9bf1276427987df6bf/Aetna-paper_claim_form.pdf",
                },
                {
                    InsurerName: "Anthem",
                    AddressRow0: "Claims Department",
                    AddressRow1: "P.O. Box 52065",
                    AddressRow2: "Phoenix, AZ 85072-2065",
                    FaxNumber: "401-404-6344",
                    FormLink: "https://www.anthem.com/forms/",
                },
                {
                    InsurerName: "BlueCross California",
                    AddressRow0: "Argus Health Systems, Inc.",
                    AddressRow1: "P.O. Box 419019, Dept 191",
                    AddressRow2: "Kansas City, MO 64141",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/1WkVYgDSuE55C3Iy7NYZ2M/a8975b6cfd763060866ae28d2f8358e7/Blue_Shield_CA_Prescription_Reimbursement_Form_nLF3y2P.pdf",
                },
                {
                    InsurerName: "BlueCross Blue Shield",
                    AddressRow0: "Prime Therapeutics (Commercial)",
                    AddressRow1: "P.O. Box 25136",
                    AddressRow2: "Lehigh Valley, PA 18002-5136",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/3NwbY8Ab7auflT4T3CuSAf/f70ef00aa33c4524fccc1a42eca2ce14/BCBS-Texas-rx_prime_claim_tx.pdf",
                },
                {
                    InsurerName: "Cigna",
                    AddressRow0: "Cigna Pharmacy Service Center",
                    AddressRow1: "P.O. Box 188053",
                    AddressRow2: "Chattanooga, TN 37422-8053",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/6euxwJlsEKNuKjc5afCG49/e1702ef60393f007efc192cd11a70246/Cigna-pharmacy-claim-form.pdf",
                },
                {
                    InsurerName: "CVS / Caremark",
                    AddressRow0: "CVS / Caremark",
                    AddressRow1: "P.O. Box 52136",
                    AddressRow2: "Phoenix, Arizona 85072-2136",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/1I0NVT5VaUtn5rfiy4s9lh/55cb1eb97e7466f8ac1a77e0ad921aed/CVSCaremark-paperclaim_std_eng.pdf",
                },
                {
                    InsurerName: "Express Scripts",
                    AddressRow0: "Express Scripts",
                    AddressRow1: "P.O. Box 14711",
                    AddressRow2: "Lexington, KY 40512",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/5xdoWZG1E7jeCjuA1sDHvT/bbe5681f0dc6f06bfb4673a8a09fb7ec/ESI_ReimbursementForm_ENG.pdf",
                },
                {
                    InsurerName: "Health Net",
                    AddressRow0: "Health Net of California C/O Caremark",
                    AddressRow1: "P.O. Box 52136",
                    AddressRow2: "Phoenix, AZ 85072-2136",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/406rTE0l4jfxce49d0ZBVr/56a2fe13f165e2dfc4945237343f99fc/HealthNet-ca_rx_claim_form_eng.pdf",
                },
                {
                    InsurerName: "Humana",
                    AddressRow0: "Humana Pharmacy Solutions",
                    AddressRow1: "P.O. Box 14140",
                    AddressRow2: "Lexington, KY 40512-4140",
                    FaxNumber: "866-754-5362",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/3F3EwV4DvddRETwxuWAPV/a04b749923250ce9546502b1c32242f1/Humana-claim-form.pdf",
                },
                {
                    InsurerName: "Kaiser Permanente",
                    AddressRow0: "OptumRx Claims Department",
                    AddressRow1: "P.O. Box 29044",
                    AddressRow2: "Hot Springs, AR 71903",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/7bCl3DsUESwLpD8Bn8nZ82/97e55cba7f4e97b148bb71aed3de1321/Kaiser-mid_selffund_claimform.pdf",
                },
                {
                    InsurerName: "Optum",
                    AddressRow0: "OptumRx ATTN: Claims Department",
                    AddressRow1: "P.O. Box 29077",
                    AddressRow2: "Hot Springs, AR 71903",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/43b6bqEbnBMLKc6mPwJSmB/2037055b892acb14ebc8d3bb0b036a04/Optum-prescriptionclaimform.pdf",
                },
                {
                    InsurerName: "UnitedHealthcare",
                    AddressRow0: "OptumRx Claims Department",
                    AddressRow1: "P.O. Box 29077",
                    AddressRow2: "Hot Springs, AR 71903",
                    FaxNumber: "",
                    FormLink: "https://assets.ctfassets.net/4f3rgqwzdznj/43b6bqEbnBMLKc6mPwJSmB/2037055b892acb14ebc8d3bb0b036a04/Optum-prescriptionclaimform.pdf",
                },
            ]
        };
        return d;
    },
    methods: {
    },
    computed: {
        validateEmail() {
            //basic email regex validation
            const regex =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return regex.test(String(this.email).toLowerCase());
        },
    },
};
</script>

<style scoped>
.heading-font {
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
}

.section-title {
    margin-bottom: 15px;
}

.error-message {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.error-message ::after,
::before {
    box-sizing: border-box;
}

.alert-success {
    background: #51a74e 0% 0% no-repeat padding-box;
    border: 1px solid #0da23b;
    opacity: 1;
}

.notification-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    color: #ffffff;
    opacity: 1;
}

.danger-text {
    text-align: left;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    opacity: 1;
}

.alert-dismissible .close {
    color: #ffffff;
    opacity: 1;
}

.mail-alert {
    display: none;
}

.show-alert {
    display: block !important;
}

.demo-background {
    background: linear-gradient(var(--background-color-light), #f9fdff);
}

.irx-grey-background {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 16px 0 60px 0;
}

@media (max-width: 992px) {
    .mail-alert {
        padding: 5px 40px 5px 15px;
        margin: 0;
    }

    .notification-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }

    .danger-text {
        text-align: left;
        font: var(--body-font-weight) 12px/15px var(--body-font-family);
        letter-spacing: 0px;
        opacity: 1;
    }

    .mail-alert .close {
        padding-top: 5px;
    }
}</style>
