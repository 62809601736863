<template>
  <div id="cover-spin">
    <div class="outer">
      <div class="middle">
        <div class="inner">
          <h3 class="text-center">
            <b>Hang on. We're getting you the lowest prices.</b>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "loading-spinner",
};
</script>

<style scoped>
.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 590px;
}

@media (max-width: 650px) {
  .inner {
    padding-top: 30px;
    width: 400px;
  }
}
@media (max-width: 450px) {
  .inner {
    padding-top: 30px;
    width: 300px;
  }
}
@media (max-width: 350px) {
  .inner {
    padding-top: 30px;
    width: 250px;
  }
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 9999;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}
</style>