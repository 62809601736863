<template>
  <div
    style="background-color: var(--background-color-dark)"
  >
    <div class="search-section-background pb-5" style="">
      <header-section v-bind:pinHeader="false" />
      <!-- hack: include the email logo image so webpack will compile it. The dist image will only be used in the template.
            This is the easiest way without touching webpack configurations  -->
      <img
        style="display: none"
        src="../assets/email-logo.webp"
        alt="email logo"
      />
      <div class="welcome-wrapper container-fluid">
        <div class="row">
          <h1 class="col-sm-12 col-md-8 offset-md-2 welcome-header text-light">
            Don't Overpay On Your Prescriptions
          </h1>
        </div>
      </div>
      <div class="search-wrapper">
        <SearchBar />
      </div>
    </div>
    <div class="" style="background-color: var(--background-color-light)">
      <logo-carousel class=""></logo-carousel>

      <how-it-works-steps
        class="py-5"
        style="
          background-color: var(--background-color-off-light);
        "
      />

      <do-not-assume></do-not-assume>

      <featured-savings></featured-savings>
    </div>
  </div>
</template>

<script lang="ts">
import SearchBar from "./shared/search-bar.vue";
import HowItWorksSteps from "./shared/how-it-works-steps.vue";
import HeaderSection from "./layout/header-section.vue";
import { ipapimixin } from "../mixins/ipapimixin";
import LogoCarousel from "./controls/logo-carousel.vue";
import { requestmixin } from "../mixins/requestmixin";
import DoNotAssume from "./controls/do-not-assume.vue";
import FeaturedSavings from "./controls/featured-savings.vue";

export default {
  name: "home-page",
  props: {
    msg: String,
  },
  mixins: [ipapimixin, requestmixin],
  components: {
    SearchBar,
    HowItWorksSteps,
    HeaderSection,
    LogoCarousel,
    DoNotAssume,
    FeaturedSavings,
  },
  methods: {
  },
  mounted() {
    if (this.getZipCodeCache() == null) {
      this.zipFromIP();
    }

    // const urlParams = new URLSearchParams(document.location.search);
    // eslint-disable-next-line no-undef
    // amplitude.getInstance().logEvent("HOME_PAGE_LOAD", {
    //   URL: document.location.toString(),
    //   gclid: urlParams.get("gclid"), // google click id
    // });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heading-font {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
}

.content-bottom {
  padding-top: 60px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
}

.section-wrapper {
  margin-top: 60px;
}

.search-section-background {
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-origin: padding-box;
}

/* absolute path should not be translated by webpack. This resource is preloaded from index.html. */

.header-image {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  overflow: hidden;
}

.welcome-img {
  display: inline-block;
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 50%;
}

.welcome-img img {
  width: auto;
  height: 100%;
  margin-left: -50px;
}

p {
  margin-bottom: 0px;
}

.welcome-wrapper {
  text-align: center;
  padding-top: 45px;
}

.featured-item {
  border-radius: 5px;
  border: 1px solid rgb(14, 144, 230);
  background-color: rgb(240, 249, 255);
  color: rgb(14, 144, 230);
  padding: 0px;
  margin: 10px 5px;
}

.featured-item hr {
  border-top: 1px solid rgb(14, 144, 230);
}

.strikethrough-font {
  text-decoration: line-through;
  color: rgb(147, 206, 240);
  font-size: 11px;
}

ul {
  padding-left: 0;
}

li {
  list-style-position: inside;
}

.search-wrapper {
  margin-top: 40px;
}

.second-card-row {
  margin-top: 30px;
}

.icons-row {
  margin-top: 25px;
}

.bottom-section-title {
  margin-top: 100px;
}

.welcome-header {
  text-align: center;
  font-family: var(--heading-font-family);
  font-weight: 1000;
  font-size: 42px;
  line-height: 68px;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}

.welcome-subheader {
  text-align: center;
  font: var(--body-font-weight) 24px/29px var(--body-font-family);
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}

@media (max-width: 992px) {
  /* absolute path should not be translated by webpack. This resource is preloaded from index.html. */
  .search-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }

  .section-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }

  .second-card-row {
    margin-top: 0px;
  }

  .bottom-section {
    margin-left: 10px;
    margin-right: 10px;
  }

  .font-description-title {
    margin-top: 30px;
  }

  .icons-row {
    padding-bottom: 10px;
  }

  .icons-row img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .welcome-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }

  .welcome-header {
    font-family: var(--heading-font-family);
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  .welcome-subheader {
    font: var(--body-font-weight) 16px/16px var(--body-font-family);
  }
}

@media (max-width: 1500px) and (min-width: 992px) {
  /* absolute path should not be translated by webpack. This resource is preloaded from index.html. */
}
</style>

