<template>
    <div class="row" style="margin-top: -50px; position: relative">
        <!--should be a bit wider than a normal container-->
        <div class="highlight-overlay-rounded-container pt-4">
            <div class="row">
                <div class="col col-4 offset-1 d-none d-lg-block">
                    <div class="d-flex justify-content-center">
                        <img :src="require(`@/assets/${imageSrc}`)" class="" height="200" width="" :alt="imgAltText" />
                    </div>
                </div>
                <div class="col col-12 col-lg-6 pl-5 pr-5">
                    <h2 class="display-4">{{ title }}</h2>
                    <p class="pitch-font">{{ text }}</p>
                    <router-link to="/request-a-demo" class="btn btn-lg my-4"
                        style="background: var(--background-color-attention-emphasis) 0% 0% no-repeat padding-box; color: black">Request
                        a Demo
                        <font-awesome-icon icon="fa fa-arrow-right" aria-hidden="true"></font-awesome-icon>
                    </router-link>
                </div>
                <div class="col col-1"></div>
                <div class="col col-12 d-lg-none d-block">
                    <div class="d-flex justify-content-center">
                        <img :src="require(`@/assets/${imageSrc}`)" class="" height="200" width="" :alt="imgAltText" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { accessibilityMixin } from '../../mixins/accessibilityMixin';
export default {
    // this element overlays itself upon the previous element via negative padding
    // It should be placed inside a container
    name: "highlight-overlay",
    props: {
        title: String,
        text: String,
        imageSrc: String,
    },
    mixins: [accessibilityMixin],
    data() {
        return {
            imgAltText: '',
        };
    },
    mounted() {
        this.imgAltText = this.getImageAltFromImageURL(this.imageSrc);
    }
};
</script>
<style scoped>
.highlight-overlay-rounded-container {
    background-color: var(--background-color-dark-emphasis);
    border-radius: 20px;
    color: white;
    margin-left: 100px;
    margin-right: 100px;
}

@media (max-width: 992px) {
    .highlight-overlay-rounded-container {
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 5px;
    }
}
</style>