import { createPinia, defineStore } from 'pinia'
 
interface State {
    results: any[]
    oldResults: any[]
    selectedDrugName: string | null
}

export const useAutocompleteStore = defineStore('autocompleteStore', {
    state: (): State => {
        return {
            results: [],
            oldResults: [],
            selectedDrugName: null,
        };
    },
    actions: {
    }
});

const store = createPinia()

export default store
