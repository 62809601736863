<template>
  <!-- I'm making a clean isMobile !isMobile distinction because on Desktop this is all in a single row and on mobile it's much more complicated -->
  <div v-if="isMobile()">
    <div class="row align-items-center h-100 result-row">
      <div class="col col-lg-5 col-md-5 col-sm-8 col-7">
        <h2 class="pharmacy-name mb-0">
          {{ drugItem.pharmacyName }}
        </h2>
      </div>
      <!-- The goal is to have the Price Savings inline on lg and vertical on smaller screens -->
      <!-- On smaller screens prices appear in line with Pharmacy Name. -->
      <div class="col col-lg-4 col-md-4 col-sm-4 col-5">
        <div
          v-if="drugItem.ucPrice && drugItem.ucPrice > 0 && drugItem.ucPrice > drugItem.price"
        >
          <p class="text-right drug-price-mobile mb-0">
            ${{ drugItem.price.toFixed(2) }}
          </p>
          <!-- <p class="p-0 retail-price-comparison-text text-right">
              ${{ ucPrice }} Retail
            </p> -->
          <p class="p-0 retail-price-comparison-text text-right mb-0">
            Save
            <span class="retail-price-comparison-percentage"
              >{{ ucPriceSavings }}%</span
            >
          </p>
          <!-- <p class="p-0 retail-price-comparison-text text-right" style="text-decoration: line-through;">
            ${{ drugItem.ucPrice }}
          </p> -->
        </div>
        <div v-else class="d-flex flex-column">
          <p class="align-self-end drug-price-mobile mb-0">
            ${{ drugItem.price.toFixed(2) }}
          </p>
        </div>
      </div>

      <template
        v-if="
          drugItem.priceBasis == null ||
          (drugItem.priceBasis !== 'Retail' &&
            drugItem.priceBasis !== 'ucPrice' &&
            drugItem.priceBasis !== 'UC' &&
            drugItem.ucPrice != drugItem.price)
        "
      >
        <div
          class="
            col col-md-3 col-12
            text-center
            mt-2 mt-md-0
            p-3
            px-md-2 px-lg-3
          "
        >
          <button
            @click="openCoupon()"
            class="btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg"
            aria-label="click here to get a prescription coupon valued at {drugItem.pharmacyName} for {drugItem.pharmacyName} at {drugItem.pharmacyName}"
          >
            Get Coupon
          </button>
        </div>
      </template>
      <template v-else>
        <div class="col col-md-3 col-12 text-center">
          <p
            class="retail-price-text mb-0"
            title="Price charged by this retailer. No coupon is necessary to receive this price."
          >
            Retail Price
          </p>
        </div>
      </template>
    </div>
  </div>

  <div v-else-if="!isMobile()">
    <div class="row align-items-center h-100 result-row">
      <div class="col col-lg-4 col-md-3 col-12">
        <!-- previously included class: col col-lg-4 col-md-3 col-7 to blanace out this.useLogoAndName-->
        <h2 v-if="!this.useLogoAndName" class="pharmacy-name mb-0">
          {{ drugItem.pharmacyName }}
        </h2>
      </div>

      <div class="col col-lg-2 col-md-3 col-12">
        <p
          class="distance mb-0"
          v-if="
            drugItem.distance &&
            parseFloat(drugItem.distance) >= 0
          "
        >
          {{ drugItem.distance.toFixed(2) }} mi away
        </p>
        <address class="address" v-if="drugItem.address">
          {{ drugItem.address }}
        </address>
      </div>

      <!-- The goal is to have the Price Savings inline on lg and vertical on smaller screens -->
      <div class="col col-lg-1 hide-at-lg">
        <div
          v-if="drugItem.ucPrice && drugItem.ucPrice > 0 && drugItem.ucPrice > drugItem.price"
          class=""
        >
          <p class="p-0 retail-price-comparison-text text-center mb-0">
            <span style="text-decoration: line-through">${{ ucPrice }}</span>
            Retail
          </p>
          <br />
          <p class="p-0 retail-price-comparison-text text-center mb-0">
            Save
            <span class="retail-price-comparison-percentage"
              >{{ ucPriceSavings }}%</span
            >
          </p>
        </div>
      </div>
      <!-- only the col-md and larger styles should ever be seen because of the isMobile check -->
      <p class="col col-lg-2 col-md-3 col-6 text-right drug-price mb-0">
        ${{ drugItem.price.toFixed(2) }}
      </p>
      <!-- only the col-md and larger styles should ever be seen because of the isMobile check -->
      <!-- shown on screen sizes smaller than 992px -->
      <p class="col col-lg-2 col-md-3 col-6 drug-price-mobile mb-0">
        ${{ drugItem.price.toFixed(2) }}
      </p>

      <template
        v-if="
          drugItem.priceBasis == null ||
          (drugItem.priceBasis !== 'Retail' &&
            drugItem.priceBasis !== 'ucPrice' &&
            drugItem.priceBasis !== 'UC' &&
            drugItem.ucPrice != drugItem.price)
        "
      >
        <div
          class="
            col col-md-3 col-12
            text-center
            mt-2 mt-md-0
            p-3
            px-md-2 px-lg-3
          "
        >
          <button
            @click="openCoupon()"
            class="btn irx-btn-primary irx-btn-primary-animated w-100 btn-lg"
            :aria-label="
              'click here to get a prescription coupon valued at ' +
              drugItem.price +
              ' dollars for ' +
              drugItem.ln +
              ' at ' +
              drugItem.pharmacyName
            "
          >
            Get Coupon
          </button>
        </div>
      </template>
      <template v-else>
        <div class="col col-md-3 col-12 text-center">
          <p
            class="retail-price-text mb-0"
            title="Price charged by this retailer. No coupon is necessary to receive this price."
          >
            Retail Price
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { cookiemixin } from "../../mixins/cookiemixin";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";

export default {
  name: "search-drug-result-item",

  components: {},
  mixins: [cookiemixin, mixinDetectingMobile],

  props: {
    drug: Object,
  },
  emits: ['pharmacy-selected'],
  computed: {
    ucPriceSavings() {
      if (
        this.drugItem.ucPrice != null &&
        this.drugItem.price != null &&
        parseFloat(this.drugItem.ucPrice) > 0 &&
        parseFloat(this.drugItem.price) > 0
      ) {
        return (
          (1 - parseFloat(this.drugItem.price) / parseFloat(this.drugItem.ucPrice)) *
          100
        ).toFixed(0);
      }
      return 0;
    },
    ucPrice() {
      if (this.drugItem.ucPrice != null && parseFloat(this.drugItem.ucPrice) > 0) {
        return parseFloat(this.drugItem.ucPrice).toFixed(0);
      }
      return 0;
    },
  },
  data() {
    return {
      useLogoAndName: false,
      drugItem: this.drug,
    };
  },

  methods: {
    openCoupon() {
      this.setCookie("MostRecentlyPickedPharmacy", this.drugItem.pharmacyName);
      this.$emit("pharmacy-selected", this.drugItem.pharmacyName);
    },
  },
  mounted() {
    this.drugItem.pharmacyName = this.drugItem.pharmacyName.toUpperCase();
    // console.log(this.drugItem.pharmacyName);
  },
};
</script>

<style scoped>
.result-row {
  padding: 15px 30px;
  border-bottom: #f5f5f5 1.5px solid;
}

.pharmacy-name {
  text-align: left;
  font-weight: var(--header-font-weight);
  font-family: var(--header-font-family);
  letter-spacing: 0px;
  color: #292525;
  opacity: 1;
  font-weight: 600;
  font-size: 21px;
  line-height: 22px;
  letter-spacing: 0px;
}

.distance {
  letter-spacing: 0px;
  color: #292525;
  opacity: 1;
  font-family: var(--body-font-family);
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.address {
  letter-spacing: 0px;
  color: #292525cc;
  opacity: 1;
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: var(--body-font-family);
  font-size: 14px;
  line-height: 22px;
}

.drug-price {
  text-align: left;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  font-family: var(--body-font-family);
  font-weight: bold;
  font-size: 32px;
  line-height: 30px;
}

.drug-price-mobile {
  display: none;
}

.retail-price-text {
  letter-spacing: 0px;
  color: #292525;
  opacity: 1;
  font-family: var(--body-font-family);
  font-weight: bold;
  font-size: 21px;
  line-height: 22px;
}

.retail-price-comparison-text {
  text-align: left;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  font-family: var(--body-font-family);
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.retail-price-comparison-percentage {
  text-align: left;
  font: var(--header-font-weight) 18px/22px var(--body-font-family);
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}

.btn-primary-animated:hover,
.btn-primary-animated:focus,
.btn-primary-animated:active,
.btn-primary-animated.active,
.open > .dropdown-toggle.btn-primary-animated {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

@media (max-width: 998px) {
  .hide-at-lg {
    display: none;
  }
}

@media (max-width: 992px) {
  .hide-at-md {
    display: none;
  }

  .result-row {
    padding: 10px 30px;
    border-bottom: #f5f5f5 1.5px solid;
  }

  .drug-price {
    display: none;
  }

  .drug-price-mobile {
    display: block;
    text-align: left;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0px;
    color: var(--primary-color);
    opacity: 1;
    font-weight: bold;
  }

  .pharmacy-name {
    font-size: 18px;
    line-height: 30px;
  }

  .retail-price-text {
    font-size: 18px;
    line-height: 30px;
  }

  .distance {
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
  }

  .address {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0;
    color: #29252599;
  }

  .row {
    padding-left: 0;
    padding-right: 0;
  }

  .retail-price-comparison-text {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    opacity: 1;
  }
  .retail-price-comparison-percentage {
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0px;
    opacity: 1;
  }
}
</style>