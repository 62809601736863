<template>
    <div class="search-result-section-wrapper">
        <div v-if="showSpinner" id="cover-spin-local">
            <h3 class="text-center">
                <b>Hang on. We're getting you the lowest prices.</b>
            </h3>
        </div>
        <div v-else>
            <div v-if="patientNoticeWarnings != null && patientNoticeWarnings.length > 0">
                <div v-for="notice in patientNoticeWarnings" :key="notice.leftText"
                    class="container search-result-section search-result-section-highlighted">
                    <search-drug-result-item-highlighted :leftText="notice.leftText"
                        :buttonHref="notice.buttonHref"
                        :buttonText="notice.buttonText"
                        warning="true"></search-drug-result-item-highlighted>
                </div>
            </div>

            <div v-if="!hasDrugs" class="container">
                <div class="row">
                    <p class="col initial-search-result">
                        Enter a Zip Code and click "Search" to get the lowest and most up to
                        date prices in your area<span v-if="drugName">
                            for {{ drugName }}</span>
                    </p>
                </div>
            </div>

            <div v-if="hasDrugs && alternatives != null && alternatives.length > 0">
                <div v-for="alternative in alternatives" :key="alternative.leftText"
                    class="container search-result-section search-result-section-highlighted">
                    <search-drug-result-item-highlighted :leftText="alternative.leftText"
                        :buttonHref="alternative.buttonHref"
                        :buttonText="alternative.buttonText"></search-drug-result-item-highlighted>
                </div>
            </div>

            <div v-if="hasDrugs && popularDrugs != null" class="container search-result-section">
                <div v-for="drug in popularDrugs" :key="drug.pharmacyName">
                    <search-drug-result-item :drug="drug" v-bind="$attrs" />
                </div>
            </div>

            <div v-if="!hasDrugs && (patientNoticeWarnings == null || patientNoticeWarnings.length < 1)" class="container">
                <div class="row">
                    <div class="col text-center">
                        <img class="img-fluid" src="../../assets/no-results-icon.svg" alt="no results icon" />
                    </div>
                </div>
                <div class="row no-results-label">
                    <h3 class="col text-center">{{ drugName }} Not Covered</h3>
                </div>
                <div class="row no-results-description">
                    <p class="col text-center">
                        We could not find any prices for {{ drugName }}. Sorry. If you believe this drug should be
                        covered and this message is being shown in error, please <router-link class="text-reset"
                            to="/contact">Contact Us</router-link>.
                    </p>
                </div>
            </div>
            <div v-else-if="!hasDrugs" class="container">
                <div class="row">
                    <div class="col text-center">
                        <img class="img-fluid" src="../../assets/no-results-icon.svg" alt="no results icon" />
                    </div>
                </div>
                <div class="row no-results-label">
                    <h3 class="col text-center">No results found.</h3>
                </div>
                <div class="row no-results-description">
                    <p class="col text-center">
                        There are no participating pharmacies within a 50 mile radius of the
                        location. Please try a different location.
                    </p>
                </div>
            </div>

            <div v-if="!showUnpopular" class="col-md-4 offset-md-4 col-sm-8 offset-sm-2 search-button">
                <prc-button type="info" @click="setShowUnpopular()" class="input-height">Show More
                    Pharmacies</prc-button>
            </div>

            <div v-if="hasDrugs && unpopularDrugs != null && showUnpopular" class="container search-result-section">
                <div v-for="drug in unpopularDrugs" :key="drug.pharmacyName">
                    <search-drug-result-item :drug="drug" v-bind="$attrs" />
                </div>
            </div>

            <div v-if="mailOrderDrugs != null && mailOrderDrugs.length > 0">
                <div class="container" style="padding-top: 100px;">
                    <h3 class="text-left">
                        <b>Mail Order Prices</b>
                    </h3>
                </div>
                <div class="container search-result-section search-result-section-highlighted-2">
                    <div v-for="drug in mailOrderDrugs" :key="drug.pharmacyName">
                        <search-drug-result-item :drug="drug" v-bind="$attrs" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import SearchDrugResultItem from "./search-drug-result-item.vue";
import PrcButton from "../controls/prc-button.vue";
import { cookiemixin } from "../../mixins/cookiemixin";
import SearchDrugResultItemHighlighted from './search-drug-result-item-highlighted.vue';
import { PropType } from "vue";

// PatientNotices to taking this prescription medication
//
// For Alternatives:
// 1. If a drug has no drug class and is expensive, it would ask the patient to call IntelligentRx for additional savings options
// 2. If there is an alternative, it would link to the page which discusses alternatives
// 3. If there is no alternative but there is a manufacturer coupon, it would show the coupon (there is another, separate task for this)
//
// For other notices:
// Various reasons; show these in yellow as a warning
export interface PatientNotice {
    // Text you see on the left hand side. Displayed in black
    leftText: string
    // Text you see on the right hand side. Displayed in white inside a blue button.
    buttonText: string
    buttonHref: string
}

export default {
    name: "search-drug-result-section",
    components: {
        SearchDrugResultItem,
        PrcButton,
        SearchDrugResultItemHighlighted,
    },
    mixins: [cookiemixin],
    data() {
        return {
            showUnpopular: false,
        };
    },
    props: {
        drugName: { type: String, default: null },
        popularDrugs: { type: Array as PropType<any[]>, default: null },
        unpopularDrugs: { type: Array as PropType<any[]>, default: null },
        alternatives: { type: Array as PropType<PatientNotice[]>, default: null },
        patientNoticeWarnings: { type: Array as PropType<PatientNotice[]>, default: null },
        mailOrderDrugs: { type: Array as PropType<any[]>, default: null },
        hasLoaded: { type: Boolean, default: false },
        showSpinner: { type: Boolean, default: false },
    },
    emits: ['show-unpopular-pharmacies'],
    computed: {
        hasDrugs() {
            return this.hasLoaded && (
                (this.popularDrugs && this.popularDrugs.length > 0) ||
                (this.unpopularDrugs && this.unpopularDrugs.length > 0)
            );
        },
        patientWarningsPresent() {
            return (this.patientNoticeWarnings == null || this.patientNoticeWarnings.length < 1);
        },
    },
    methods: {
        setShowUnpopular() {
            this.showUnpopular = true;
            this.$emit("show-unpopular-pharmacies", this.showUnpopular);
        },
    },
    mounted() { },
};
</script>

<style scoped>
.search-result-section-wrapper {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-top: 30px;
    padding-bottom: 50px;
}

.search-result-section {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.search-result-section-highlighted {
    margin-bottom: 30px;
}

.search-result-section-highlighted-2 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.initial-search-result {
    text-align: center;
    font: var(--body-font-weight) 18px/22px var(--body-font-family);
    letter-spacing: 0.45px;
    color: #292525;
    opacity: 1;
}

.no-results-label {
    font: var(--header-font-weight) 32px/39px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525;
    opacity: 1;
    margin-top: 15px;
}

.no-results-description {
    font: var(--body-font-weight) 16px/19px var(--body-font-family);
    letter-spacing: 0px;
    color: #292525cc;
    opacity: 1;
    margin-top: 15px;
}

.location-logo {
    margin-right: 5px;
}

#cover-spin-local {
    position: relative;
    background-color: #f5f5f5;
    padding-bottom: 100px;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#cover-spin-local::after {
    content: "";
    position: absolute;
    left: 48%;
    width: 40px;
    height: 40px;
    background-color: #f5f5f5;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin 0.8s linear infinite;
    animation: spin 0.8s linear infinite;
}

@media (max-width: 992px) {

    .search-result-section-wrapper {
        background: #f5f5f5 0% 0% no-repeat padding-box;
        opacity: 1;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .search-result-section-highlighted {
        margin-bottom: 10px;
    }

    .search-result-section-highlighted-2 {
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
</style>