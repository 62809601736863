export const accessibilityMixin = {
    methods: {
        // readCookie returns the string value at this cookie name. If there is no value this function returns null.
        getImageAltFromImageURL: function (imageURL) {
            if (imageURL == null || imageURL === '' || imageURL.length <= 1) {
                return '';
            }
            let splitImageURL = imageURL.split('/');
            if (splitImageURL.length <= 1) {
                return;
            }
            let almostAltText = splitImageURL[splitImageURL.length-1].replace(/_/g, ' ').trim();
            let almostAltTextPeriodIndex = almostAltText.indexOf('.');
            if (almostAltTextPeriodIndex > 1) {
                almostAltText = almostAltText.substring(0, almostAltTextPeriodIndex).trim();
            }
            return almostAltText;
        },
    }
}
